
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { useToggleClassActiveMutation } from "../../../lib/apollo/graphql/generated";
import { Button, Td } from "../../common/styles/commonStyles";
import {
    Gender
  } from "../../../lib/apollo/graphql/generated";
import useReviewContainer from "../container/RReviewContainer.hook";

interface ReviewRowProps {
  review: {
    __typename?: "Review";
    id: number, 
    userId: string, 
    classId: string, 
    content: string, 
    score: number, 
    createdAt: any, 
    user: 
    { __typename?: 'User', 
    id: string, 
    name?: string | null, 
    username: string, 
    email?: string | null, 
    dateOfBirth?: any | null, 
    gender?: Gender | null, 
    phone?: string | null, 
    introduction?: string | null, 
    createdAt: any, 
    updatedAt: any, 
    deletedAt?: any | null, 
    resignReason?: string | null },
    class: {
      __typename?: "Class" | undefined;
      id: string;
      title?: string | null | undefined;
      description?: string | null | undefined;
      duration?: number | null | undefined;
      active?: boolean | undefined;
      createdAt?: any;
  } 
  },
    setReviewId: React.Dispatch<React.SetStateAction<number>> ,
    changedReview: (index: number, score: number, content: string) => void
    index: number,
    deleteReview: (index: number) => void

}

function PostRow({ review, setReviewId, changedReview, deleteReview, index }: ReviewRowProps) {
  const [viewMore, setViewMore] = useState(false);
  const {
    id,
    userId,
    classId,
    content,
    score,
    createdAt,
    user
  } = review!;

  const classes = review.class

  const [toggleClassActive] = useToggleClassActiveMutation();
  const [edit, setEdit] = useState(false)
  const [changedContent, setChangedContent] = useState(content)
  const [changedScore, setChangedScore] = useState(score)

  const {
    models: { },
    operations: { onUpdateReview, onDeleteReview },
  } = useReviewContainer();


  const onUpdate = () => {
    let changedData: any = {id}

    if (changedContent != content) changedData = {...changedData, content: changedContent}
    if (changedScore != score) changedData = {...changedData, score: changedScore}

    const keys = Object.keys(changedData).length

    if (keys == 1) {
      alert('수정사항이 없습니다')
      return
    }

    changedReview(index, changedScore, changedContent)
    onUpdateReview(changedData)
    setEdit(false)
  }

  const onDelete = () => {
    setViewMore(false)
    deleteReview(index)
    onDeleteReview(id)
  }

  const init = () => {
    setChangedContent(content)
    setChangedScore(score)
  }

  useEffect(()=>{
    if (!edit) init()
  },[edit])

  return (
    <>
      <tr>
        <Td>{id}</Td>
        <Td>{classes.title}</Td>
        <Td>{score}</Td>
        <Td>{user.username}</Td>
        <Td>{format(new Date(createdAt), "yyyy-MM-dd")}</Td>
        <Td>
          <Button onClick={() => {
            setReviewId(id)
            setViewMore(!viewMore)
            }}>
            {!viewMore ? "더보기" : "닫기"}
          </Button>
        </Td>
      </tr>
      {viewMore && (
        <tr>
          <Td colSpan={8}>
            <ViewMoreWrapper>
              <OptionsWrapper>
                <Option>후기</Option>
                <ReviewWrapper>
                  <SocreInput 
                    type='text' 
                    disabled={!edit}
                    value={changedScore}
                    onChange={(e: any)=>setChangedScore(Number(e.target.value))}
                  ></SocreInput>
                  <ModifyInput 
                    type='text' 
                    disabled={!edit}
                    value={changedContent}
                    onChange={(e: any)=>setChangedContent(e.target.value)}
                  ></ModifyInput>
                </ReviewWrapper>
              </OptionsWrapper>
              {
                edit 
                ? <ButtonWrapper>
                  <CustomButton onClick={onUpdate}>완료</CustomButton> 
                  <CustomButton onClick={()=>setEdit(false)}>취소</CustomButton> 
                </ButtonWrapper>
                : 
                <ButtonWrapper>
                  <CustomButton onClick={()=>setEdit(true)}>수정</CustomButton>
                  <CustomButton onClick={onDelete}>삭제</CustomButton>
                </ButtonWrapper>
              }
            </ViewMoreWrapper>
          </Td>
        </tr>
      )}
    </>
  );
}

const ReviewWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  border: ".5px solid gainsboro"
}));

const SocreInput= styled.input<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  textAlign: "center"
}));

const ModifyInput= styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  resize: "none",
  border: "none"
}));


const ButtonWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: "10px",
  justifyContent: "center"
}));

const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px"
}));

const Avatar = styled.img<any>(() => ({
  width: 50,
  height: 50,
  borderRadius: 25,
  objectFit: "cover",
}));

const ViewMoreWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.normal,
//   display: "flex",
//   alignItems: "flex-start",
textAlign: "center",
  gap: theme.spacing.xxSmall2,
}));

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  border: `1px solid ${theme.color.black7}`,
  ...theme.font.body3,
  borderBottom: "none",
}));

const Option = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  width: "100%",
}));

const CoverPhoto = styled.img<any>(() => ({
  width: 250,
  height: 100,
  objectFit: "cover",
}));

export default PostRow;
