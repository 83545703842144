import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useCommentContainer from "../container/CommentContainer.hook";

interface CommnetRowProps {
  comment: {
    __typename?: 'Comment', 
    content: string, 
    createdAt: any, 
    id: number, 
    postId: number, 
    userId?: string | null, 
    user?: { 
      __typename?: 'User', 
    id: string, 
    name?: string | null, 
    username: string, 
    email?: string | null, 
    dateOfBirth?: any | null, 
    gender?: string | null, 
    phone?: string | null, 
    introduction?: string | null, 
    createdAt: any, 
    updatedAt: any, 
    deletedAt?: any | null, 
    resignReason?: string | null } | null
  } | null
  boardId: number | undefined
}

function CommentRow({ comment, boardId }: CommnetRowProps) {
  const {
    content,
    createdAt,
    postId,
    id,
    userId,
    user
  } = comment!;

  const {
    refs: {  },
    models: { loading, data },
    operations: { onDeleteComment, onUpdateComment }
  } = useCommentContainer(postId, boardId, setEditFalse);

  const [edit, setEdit] = useState(false)
  const [changedContent, setChangedContent] = useState('')

  function init() {
    if (content){
      setChangedContent(content)
    }
  }

  function setEditFalse () {
    setEdit(false)
  }

  useEffect(()=>{
    if(!edit) init()
  },[])

  return (
    <div>
      <CommentInfoWrapper>
        <span>{user? user.username : "관리자"}</span>
        <span>{ format(new Date(createdAt), "yyyy-MM-dd")}</span>
      </CommentInfoWrapper>
      {
        edit
        ?<CommentWrapper>
          <ModifyInput type='text' value={changedContent} onChange={(e: any)=>setChangedContent(e.target.value.trim())}></ModifyInput>
          <CustomButton onClick={()=>onUpdateComment(id, content, changedContent)}>완료</CustomButton>
        </CommentWrapper>
        :<CommentWrapper>
          <div>{content}</div>
          <ButtonWrapper>
            <CustomButton onClick={()=>{setEdit(true)}}>수정</CustomButton>
            <CustomButton onClick={()=>{onDeleteComment(id)}}>삭제</CustomButton>
          </ButtonWrapper>
        </CommentWrapper>
      }
    </div>

  );
}

export default CommentRow;


const CommentInfoWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: "10px",
  marginBottom: "10px 0 5px 0"
}));

const CommentWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px"
}));

const ButtonWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: "10px"
}));

const ModifyInput= styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  resize: "none",
}));
