import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { GetAllPostsDocument, GetPostDocument, useDeletePostMutation, useGetPostQuery, useUpdatePostMutation } from "../../../lib/apollo/graphql/generated";

function usePostContainer(boardId?: number | null, postId?: number | null, deleteAPost?: () => void) {
  const [fetchingMore, setFetchingMore] = useState(false);

  const [state, setState] = useState({
    title: "",
    content: ""
  })

  const { data, loading, fetchMore, refetch } = useGetPostQuery({
    onCompleted: (data) => {
      if(data?.getPost) {
        setState({
          title: data?.getPost?.title || "",
          content: data?.getPost?.content || "",
        })
      }
    },
    variables: {
      boardId,
      id: postId
    },
  });

  function onChangeTitle(value: string) {
    setState((prev) => ({
      ...prev,
      title: value,
    }));
  }

  function onChange(value: string) {
    setState((prev) => ({
      ...prev,
      content: value,
    }));
  }

  
  const [updatePost] = useUpdatePostMutation({
    refetchQueries: () => [
      {
        query: GetPostDocument,
        variables: {
          boardId,
          id: postId
        },
      },
    ],
  });

  function onUpdatePost(changedDatas: any) {
    const { title, content, category } = changedDatas

    let updateDatas: any = {id: postId}

    if (title) updateDatas = {...updateDatas, title}
    if (content) updateDatas={...updateDatas, content}
    if (category) updateDatas={...updateDatas, category}

    updatePost({
      variables: updateDatas,
    });
  }

  const [deletePost] =  useDeletePostMutation({
    onCompleted: () => {
      alert('삭제되었습니다')
      deleteAPost && deleteAPost()
    },
    // refetchQueries: (data) => [
    //   {
    //     query: GetAllPostsDocument,
    //     variables: {
    //       boardId: boardId,
    //       maxPage: 20,
    //       page: 1, 
    //     },
    //   },
    // ],
  });

  function onDeletePost() {
    const confirm = window.confirm('게시글을 삭제하시겠습니까?')
    
    if(!confirm) {
      return 
    }

    deletePost({
      variables: {
        id: postId,
      },
    });
  }

  return {
    refs: {},
    models: {
      state,
      loading,
      data: data?.getPost,
      fetchingMore,
    },
    operations: {
      onUpdatePost,
      onDeletePost,
      onChange,
      onChangeTitle
    },
  };
}

export default usePostContainer;
