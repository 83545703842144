import { createGlobalStyle, DefaultTheme, Font } from "styled-components";

export const theme: DefaultTheme = {
  topbarHeight: 64,
  color: {
    green1: "#c5f363",
    green2: "#6AC088",
    green3: "#1F7373",
    yellow1: "#EBF244",
    error: "#F6421C",
    black1: "#111827",
    black2: "#1F2937",
    black3: "#374151",
    black4: "#4B5563",
    black5: "#6B7280",
    black6: "#9ca3af",
    black7: "#D1D5DB",
    black8: "#e5e7eb",
    black9: "#F3F4F6",
    border: "#F9FAFB",
    white: "#FFFFFF",
    black: "#19191C",
    background: "#f9f9fa",
  },
  font: generateFont(),
  spacing: {
    xxSmall: 2,
    xxSmall2: 4,
    small: 8,
    normal: 12,
    default: 16,
    regular: 20,
    middle: 24,
    large: 32,
    section: 40,
  },
};

/**
 * Generate font theme object to get the lineHeight px value in it
 */
function generateFont(): Font {
  const fontSizes = [60, 48, 40, 32, 28, 24, 20, 17, 15, 13, 11];
  const lineHeights = [1.2, 1.2, 1.2, 1.5, 1.5, 1.3, 1.5, 1.5, 1.5, 1.5, 1.5];

  const titleLength = 2;
  const headLength = 5;
  const bodyLength = 3;

  let font = {};

  fontSizes.forEach((size, index) => {
    const lineHeight = size * lineHeights[index];
    if (index < titleLength) {
      font = {
        ...font,
        [`title${index + 1}`]: {
          fontSize: size,
          lineHeight: `${lineHeight}px`,
        },
      };
    } else if (index < titleLength + headLength) {
      font = {
        ...font,
        [`head${index - titleLength + 1}`]: {
          fontSize: size,
          lineHeight: `${lineHeight}px`,
        },
      };
    } else if (index < titleLength + headLength + bodyLength) {
      font = {
        ...font,
        [`body${index - (titleLength + headLength) + 1}`]: {
          fontSize: size,
          lineHeight: `${lineHeight}px`,
        },
      };
    } else {
      font = {
        ...font,
        [`caption${index - (titleLength + headLength + bodyLength) + 1}`]: {
          fontSize: size,
          lineHeight: `${lineHeight}px`,
        },
      };
    }
  });

  return font as Font;
}

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body * {
    white-space: pre-line;
  }

  /* Scroll bar stylings */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff; 
  }
    
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(222, 220, 220); 
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;
