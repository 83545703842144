import React, { useState } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { useToggleClassActiveMutation } from "../../../lib/apollo/graphql/generated";
import { Button, Td } from "../../common/styles/commonStyles";

interface ClassRowProps {
  tutorClass: {
    __typename?: "Class";
    id: string;
    title: string;
    description: string;
    duration: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
    tutor: {
      __typename?: "Tutor";
      id: string;
      introduction?: string | null;
      experience?: string | null;
      verified: boolean;
      active: boolean;
      mentorActive: boolean;
      createdAt: any;
      updatedAt: any;
      areas?: Array<{
        __typename?: "Area";
        id: string;
        name: string;
      } | null> | null;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        username: string;
        email?: string | null;
        deletedAt?: any | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    };
    coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
    fields?: Array<{
      __typename?: "Field";
      id: string;
      name: string;
    } | null> | null;
  } | null;
}

function ClassRow({ tutorClass }: ClassRowProps) {
  const [viewMore, setViewMore] = useState(false);

  const [toggleClassActive] = useToggleClassActiveMutation();

  const {
    id,
    tutor: {
      user: { avatar, username, name, deletedAt },
    },
    coverPhoto,
    title,
    fields,
    active,
    description,
  } = tutorClass!;
  return (
    <>
      <tr>
        <Td>{id}</Td>
        <Td>{avatar ? <Avatar src={avatar?.uri} /> : null}</Td>
        <Td>{name}</Td>
        <Td>{username}</Td>
        <Td>{title}</Td>
        <Td>{fields?.map((field) => "#" + field?.name + " ")}</Td>
        <Td>{active ? "O" : "X"}</Td>
        <Td>
          <Button onClick={() => setViewMore(!viewMore)}>
            {!viewMore ? "더보기" : "닫기"}
          </Button>
        </Td>
      </tr>
      {viewMore && (
        <tr>
          <Td colSpan={8}>
            <ViewMoreWrapper>
              <OptionsWrapper>
                <Option>커버 사진</Option>
                <Option>
                  {coverPhoto ? <CoverPhoto src={coverPhoto?.uri} /> : "없음"}
                </Option>
              </OptionsWrapper>
              <OptionsWrapper>
                <Option>강의 설명</Option>
                <Option>{description}</Option>
              </OptionsWrapper>
              <OptionsWrapper>
                <Option>강의 {active ? "비활성화" : "활성화"}</Option>
                <Option>
                  <Button
                    disabled={!!deletedAt}
                    onClick={() =>
                      toggleClassActive({
                        variables: {
                          classId: id,
                          active: !active,
                        },
                      })
                    }
                  >
                    {deletedAt ? "탈퇴한회원" : active ? "비활성화" : "활성화"}
                  </Button>
                </Option>
              </OptionsWrapper>
            </ViewMoreWrapper>
          </Td>
        </tr>
      )}
    </>
  );
}

const Avatar = styled.img<any>(() => ({
  width: 50,
  height: 50,
  borderRadius: 25,
  objectFit: "cover",
}));

const ViewMoreWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.normal,
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing.xxSmall2,
}));

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  border: `1px solid ${theme.color.black7}`,
  ...theme.font.body3,
  borderBottom: "none",
}));

const Option = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  width: "100%",
}));

const CoverPhoto = styled.img<any>(() => ({
  width: 250,
  height: 100,
  objectFit: "cover",
}));

export default ClassRow;
