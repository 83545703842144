import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { GetCommentsDocument, useCreateCommentMutation, useDeleteCommentMutation, useGetCommentsQuery, useUpdateCommentMutation } from "../../../lib/apollo/graphql/generated";

function useCommentContainer(postId?: number | null, boardId?: number, setEditFalse?: () => void) {
  const { data, loading, fetchMore, refetch } = useGetCommentsQuery({
    variables: {
      boardId,
      postId: postId,
      maxPage:20,
      page:1
    },
  });

  function reFetch (skip: boolean, page: number) {
    if (skip) {
      return
    } else {
      refetch({
        boardId,
        postId: postId,
        maxPage:20,
        page
      })
    }
  }

  const [deleteComment] = useDeleteCommentMutation({
    refetchQueries: () => [
      {
        query: GetCommentsDocument,
        variables: {
          boardId,
          postId: postId,
          maxPage: 20,
          page: 1, 
        },
      },
    ],
  })

  function onDeleteComment (id: number) {
    const confirm = window.confirm('삭제하시겠습니까?')
    
    if(!confirm) {
      return 
    }

    deleteComment({
      variables: {
        id,
      },
    });
  }

  const [updateComment] = useUpdateCommentMutation({
    onCompleted: () => {
      setEditFalse && setEditFalse()
    },
    refetchQueries: () => [
      {
        query: GetCommentsDocument,
        variables: {
          boardId,
          postId: postId,
          maxPage:20,
          page:1
        },
      },
    ],
  });

  function onUpdateComment(id: number, originalContent: string, content: string) {
    if (!content) {
      alert('내용을 작성해주세요')
      return
    }

    if (originalContent == content) {
      alert('수정사항이 없습니다')
      setEditFalse && setEditFalse()
      return
    }

    updateComment({
      variables: {
        id,
        content
      }
    });
  }

  const [createComment] = useCreateCommentMutation({
    refetchQueries: () => [
      {
        query: GetCommentsDocument,
        variables: {
          boardId,
          postId: postId,
          maxPage:20,
          page:1
        },
      },
    ],
  })

  function onCreateComment(content: string) {
    if (!content) {
      alert('내용을 작성해주세요')
      return
    }

    createComment({
      variables:{
        boardId,
        postId,
        content
      }
    })
  }

  return {
    refs: {},
    models: {
      loading,
      data: data?.getComments,
    },
    operations: {
      onDeleteComment,
      onUpdateComment,
      onCreateComment,
      reFetch
    },
  };
}

export default useCommentContainer;
