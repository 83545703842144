import { format } from "date-fns";
import React from "react";
import useUserListTable from "./UserListTable.hook";
import {
  Button,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../../../common/styles/commonStyles";

function UserListTable() {
  const {
    refs: { fetchMoreRef },
    models: { data, loading, fetchingMore },
    operations: { onDeleteClick },
  } = useUserListTable();

  if (loading) return <div />;

  return (
    <Table>
      <THead>
        <tr>
          <Th>ID</Th>
          <Th>이름</Th>
          <Th>닉네임</Th>
          <Th>이메일</Th>
          <Th>전화번호</Th>
          <Th>관심분야</Th>
          <Th>멘토여부</Th>
          <Th>강사여부</Th>
          <Th>가입일</Th>
          <Th>탈퇴여부</Th>
          <Th>탈퇴사유</Th>
        </tr>
      </THead>
      <TBody>
        {data?.map((user) => (
          <tr key={user?.id}>
            <Td>{user?.id}</Td>
            <Td>{user?.name || "-"}</Td>
            <Td>{user?.username}</Td>
            <Td>{user?.email}</Td>
            <Td>{user?.phone || "-"}</Td>
            <Td>{user?.fields?.map((field) => "#" + field?.name + " ")}</Td>
            <Td>
              {user?.mentor?.id
                ? user?.mentor?.verified
                  ? "O"
                  : "멘토승인대기중"
                : "X"}
            </Td>
            <Td>
              {user?.tutor?.id
                ? user?.tutor?.verified
                  ? "O"
                  : "강사승인대기중"
                : "X"}
            </Td>
            <Td>{format(new Date(user?.createdAt), "yyyy-MM-dd")}</Td>
            <Td>
              {user?.deletedAt ? (
                format(new Date(user?.deletedAt), "yyyy-MM-dd")
              ) : (
                <Button onClick={() => onDeleteClick(user?.id!)}>
                  탈퇴하기
                </Button>
              )}
            </Td>
            <Td>{user?.resignReason}</Td>
          </tr>
        ))}

        <tr style={{ visibility: "hidden" }}>
          <td ref={fetchMoreRef} colSpan={8}>
            Fetch More
          </td>
        </tr>
      </TBody>
    </Table>
  );
}

export default UserListTable;
