import React from "react";
import { Link } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import logo from "../../../assets/icons/logo_color.svg";
import { PATH_NAMES } from "../utils/router";

function Sidebar() {
  return (
    <Wrapper>
      {Object.keys(PATH_NAMES).map((key) => {
        const route = PATH_NAMES[key];

        if (route.path === PATH_NAMES.HOME.path) {
          return (
            <LogoContainer key={route.name}>
              <Link to="/">
                <Logo />
              </Link>
            </LogoContainer>
          );
        }

        return (
          <Link key={route.name} to={route.path}>
            <Nav key={route.name}>{route.name}</Nav>
          </Link>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.aside<any>(({ theme }: ThemedComponentProps) => ({
  minWidth: 200,
  width: "10%",
  height: "100%",
  backgroundColor: theme.color.black2,
  color: theme.color.white,
  overflow: "auto",
}));

const LogoContainer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Logo = styled.img.attrs(() => ({
  src: logo,
}))<any>(() => ({
  width: "100%",
  objectFit: "contain",
}));

const Nav = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body1,
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  width: "100%",
  textAlign: "left",

  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.3)",
  },
}));

export default Sidebar;
