import { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  GetAllTutorsDocument,
  useGetAllTutorsQuery,
  useToggleActiveTutorMutation,
  useVerifyTutorAsAdminMutation,
} from "../../../../lib/apollo/graphql/generated";

function useTutorListTable(requestedOnly: boolean) {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useGetAllTutorsQuery({
    variables: {
      first: 20,
      requestedOnly,
    },
  });

  const [verifyTutorAsAdmin] = useVerifyTutorAsAdminMutation({
    refetchQueries: requestedOnly
      ? () => [
          {
            query: GetAllTutorsDocument,
            variables: {
              first: 20,
              requestedOnly,
            },
          },
        ]
      : undefined,
  });

  const [toggleActiveTutor] = useToggleActiveTutorMutation();

  async function onFetchMore(inView: boolean) {
    if (inView && !fetchingMore) {
      setFetchingMore(true);

      const { hasNextPage, endCursor } = data?.getAllTutors?.pageInfo!;
      if (hasNextPage) {
        fetchMore({
          variables: {
            first: 20,
            requestedOnly,
            after: endCursor,
          },
        }).finally(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  function onVerifyClick(tutorId: string) {
    verifyTutorAsAdmin({
      variables: {
        tutorId,
        verified: true,
      },
    });
  }

  function onActivateClick(tutorId: string, active: boolean) {
    toggleActiveTutor({
      variables: {
        tutorId,
        active: !active,
        mentorActive: !active,
      },
    });
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAllTutors?.edges,
      fetchingMore,
    },
    operations: {
      onVerifyClick,
      onActivateClick,
    },
  };
}

export default useTutorListTable;
