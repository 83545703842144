import React, {useState, useEffect} from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import usePostContainer from "./CommunityContainer.hook";
import {
  Table,
  TBody,
  Td,
  Th,
  THead
  } from "../../common/styles/commonStyles";
import PostRow from "../components/PostRow";
import BoardWrapper from "../components/BoardWrapper";
import Post from "../../p/container/PostContainer";
import PostListTable from "./PostListTable";

function CommunityContainer() {
  const theme = useTheme();
  const [tab, setTab] = useState(0)
  const [postId, setPostId] = useState<null | number>(null)
  const [showPost, setShowPost] = useState(false)
  const [total, setTotal] = useState(0)
  const [searchWord, setSearchWord] = useState('')
  const [searchCategory, setSearchCategory] = useState('')
  const [searchInfo, setSearchInfo] = useState({
    searchWord, searchCategory
  })
  const [changePostList, setChagePostList] = useState(false)

  function onTabSelect(selectedTab: number | undefined) {
    selectedTab && setTab(selectedTab);
  }

  function onShowPost(show: boolean) {
    setShowPost && setShowPost(show)
  }

  function onPostSelect(selectedPost: number | null) {
    setPostId && setPostId(selectedPost)
  }

  function onSetPostTotal(total: number | undefined) {
    (total || total == 0) && setTotal(total)
  }

  function deletePost() {
    showPost && setShowPost(false)
    setPostId(null)
    setChagePostList(true)
    // deletePostOnList()
  }

  function deletePostOnList() {
    setTotal((prev) => prev-1)
  }

  function search(tempSearchCategory: string, tempSearchWord: string) {
    setSearchInfo({
      searchCategory: tempSearchCategory,
      searchWord: tempSearchWord.trim()
    })
    // setSearchCategory(tempSearchCategory)
    // setSearchWord(tempSearchWord.trim())
  }

  useEffect(()=>{
    if (postId)
      onShowPost(true)
    else
      onShowPost(false)
  },[postId])

  return (
    <Wrapper>
      <BoardWrapper setChagePostList={setChagePostList} tab={tab} postId={postId} search={search} onTabSelect={onTabSelect} onSetPostTotal={onSetPostTotal}/>
      {
        !showPost
        ?
        <>
          <div>
            <span>게시글수 {total}개</span>
            {/* <SearchWrapper onSetSearchWord={onSetSearchWord}/> */}
          </div>
          <PostListTable changePostList={changePostList} setChagePostList={setChagePostList} deletePostOnList={deletePostOnList} tab={tab} searchInfo={searchInfo} onSetPostTotal={onSetPostTotal} onPostSelect={onPostSelect}/>
        </>
        :
        <>
          <CustomButton onClick={()=>onPostSelect(null)}>목록으로</CustomButton>
          <Post deletePost={deletePost} boardId={tab} postId={postId}/>
        </>

      }

    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  padding: theme.spacing.middle,
}));

const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px"
}));

export default CommunityContainer;