import { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  GetAllBannersDocument,
  useDeleteBannerMutation,
   useDeleteBannersMutation,
   useGetAllBannersQuery,
   useUpdateBannerAsAdminMutation,
   useUpdateOrderMutation, 
   } from "../../../lib/apollo/graphql/generated";
import useUpdateBanner from "./UpdateBanner.hook";

function useBannerListTable(changeBannerTypes: (types: string[]) => void) {
  const { ref: fetchMoreRef, inView } = useInView({});

  const [fetchingMore, setFetchingMore] = useState(false);

  const [bannerType, setBannerType] = useState<any[]>([])
  const [state, setState] = useState<any[]>([])

  const { data, loading, fetchMore, refetch } = useGetAllBannersQuery({
    onCompleted: (data) => {
      const types = data?.getAllBanners?.edges?.map((d: any) => d.type)
      const typeArr = new Set(types)
      const uniqueArr = [...typeArr];
      let temp: any = []
      uniqueArr.map((d: any) => {
        const filter = data?.getAllBanners?.edges?.filter((x: any) => x.type == d)
        temp.push(filter)
      }) 
      changeBannerTypes(uniqueArr)
      setBannerType(temp)
      data?.getAllBanners?.edges && setState(data?.getAllBanners?.edges)
    },
    variables: {
      first: 20,
    }
  });

  const [deleteBanner] =  useDeleteBannerMutation({
    refetchQueries: () => [
      {
        query: GetAllBannersDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  const [deleteBanners] = useDeleteBannersMutation({
    refetchQueries: () => [
      {
        query: GetAllBannersDocument,
        variables: {
          first: 20,
        },
      },
    ],
  })

  function onDeleteBanners(type: string) {
    const confirm = window.confirm('삭제하시겠습니까?')
    
    if(!confirm) {
      return 
    }

    deleteBanners({
      variables: {
        type
      }
    })
  }

  function onDeleteBanner(id: number) {
    const confirm = window.confirm('삭제하시겠습니까?')
    
    if(!confirm) {
      return 
    }

    deleteBanner({
      variables: {
        id,
      },
    });
  }

  function changeData(tempArr: any) {
    setState(tempArr)
  }

  const [updateBanner] = useUpdateBannerAsAdminMutation({
    onCompleted: () => {
      alert("수정되었습니다");
    },
    refetchQueries: () => [
      {
        query: GetAllBannersDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  const [updateOrder] = useUpdateOrderMutation({
    onCompleted: () => {
      alert("수정되었습니다");
    },
    refetchQueries: () => [
      {
        query: GetAllBannersDocument,
        variables: {
          first: 20,
        },
      },
    ],
  })

  async function orderChange(index: number) {
    const banners = bannerType[index];
    
    let id: number[] = []
    let order: number[] = []
    banners.map((banner: any, index: number)=>{
      id.push(banner.id)
      order.push(index+1)
    })

    updateOrder({
      variables: {
        id,
        order
      },
    });
    }
  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      bannerType,
      state,
      loading,
      data: data?.getAllBanners?.edges,
      fetchingMore,
    },
    operations: {
      onDeleteBanners,
      onDeleteBanner,
      changeData,
      orderChange
    },
  };
}

export default useBannerListTable;
