import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";

import usePostContainer from "./PostContainer.hook";
import Comment from "../../comment/container/CommentContainer";
import { Button } from "../../common/styles/commonStyles";
import Editor from "../../common/components/Editor";
import usePostFormModal from "../../post/components/PostFormModal.hook";

type PostContainerProps = {
  postId: number | null;
  boardId: number;
  deletePost: () => void;
};

function PostContainer({ boardId, postId, deletePost }: PostContainerProps) {
  const [edit, setEdit] = useState(false);
  // const [title, setTitle] = useState('')
  // const [content, setContent] = useState('')

  const {
    refs: {},
    models: { state, loading, data, fetchingMore },
    operations: { onUpdatePost, onDeletePost, onChange, onChangeTitle },
  } = usePostContainer(boardId, postId, deletePost);

  function init() {
    if (data) {
      // setContent(data?.content)
      // setTitle(data?.title)
    }
  }

  function validation() {
    let changedDatas: any = {};

    //
    if (title != data?.title) {
      changedDatas = { ...changedDatas, title };
    }

    //
    if (content != data?.content) {
      changedDatas = { ...changedDatas, content };
    }

    if (Object.keys(changedDatas).length < 1) return false;
    return changedDatas;
  }

  function onUpdate() {
    const changedDatas = validation();

    if (!changedDatas) {
      alert("수정사항이 없습니다");
      return;
    }

    onUpdatePost(changedDatas);
  }

  function cancel() {
    setEdit(false);
    data && onChangeTitle(data?.title);
    data && onChange(data?.content);
  }

  useEffect(() => {
    if (!edit) init();
  }, [edit]);

  if (loading) return <div />;

  const { title, content } = state;

  return (
    <div style={{ margin: "20px 0" }}>
      {edit ? (
        <input
          type="text"
          style={{
            border: "1px solid gainsboro",
            width: "100%",
            height: "40px",
            lineHeight: "40px",
          }}
          value={title}
          onChange={(e) => onChangeTitle(e.target.value)}
        ></input>
      ) : (
        <TitleWrapper>
          {data?.category ? `${data?.category} | ` : ""}
          {data?.title}
        </TitleWrapper>
      )}
      {!edit && (
        <>
          <div>{data?.user?.username}</div>
          <div>
            작성일 : {format(new Date(data?.createdAt), "yyyy-MM-dd")} | 수정일
            : {format(new Date(data?.updatedAt), "yyyy-MM-dd")}
          </div>
        </>
      )}
      {/* {
        edit
        ? <input type='text' value={content} onChange={(e)=>setContent(e.target.value)}></input>
        :<ContentWrapper dangerouslySetInnerHTML={{__html: decodeURIComponent(data ? data?.content : '')}}></ContentWrapper>
      } */}
      {edit ? (
        <Editor value={content} onChange={onChange} />
      ) : (
        <ContentWrapper
          className="htmlwrapper"
          dangerouslySetInnerHTML={{
            __html: decodeURIComponent(data ? data?.content : ""),
          }}
        ></ContentWrapper>
      )}
      {edit ? (
        <ButtonWrapper>
          <Button
            onClick={() => {
              setEdit(false);
              onUpdate();
            }}
          >
            완료
          </Button>
          <Button onClick={cancel}>취소</Button>
        </ButtonWrapper>
      ) : (
        <>
          <ButtonWrapper>
            <Button onClick={() => setEdit(true)}>수정</Button>
            <Button onClick={onDeletePost}>삭제</Button>
          </ButtonWrapper>
          <Comment boardId={boardId} postId={postId} />
        </>
      )}
    </div>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const TitleWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({}));

const ButtonWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  justifyContent: "center",
  gap: "20px",
  margin: "20px 0",
}));

const ContentWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  minHeight: "400px",
}));

export default PostContainer;
