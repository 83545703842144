import { ModalProps } from "global";
import React from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useUpdateUserModal from "./UpdateUserModal.hook";
import { Button } from "../../../common/styles/commonStyles";

function UpdateUserModal({
  userId,
  isOpen,
  onRequestClose,
}: ModalProps & { userId?: string }) {
  const theme = useTheme();

  const {
    models: { state, loading, fields, isNew },
    operations: {
      onInputChange,
      onGenderChange,
      onFieldSelect,
      onAvatarInputChange,
      onSubmit,
    },
  } = useUpdateUserModal(userId, onRequestClose);

  if (loading) return <div />;

  const {
    name,
    username,
    email,
    selectedFieldIds,
    gender,
    dateOfBirth,
    phone,
    introduction,
    avatar,
    avatarState,
  } = state;

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Title>유저 생성</Title>
      <Button
        disabled={
          !username && !email && Array.from(selectedFieldIds).length < 1
        }
        onClick={onSubmit}
      >
        제출하기
      </Button>
      <InputRow>
        <Label>이메일*</Label>
        <Input
          placeholder="이메일"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "email")
          }
        />
      </InputRow>
      <InputRow>
        <Label>닉네임*</Label>
        <Input
          placeholder="닉네임"
          value={username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "username")
          }
        />
      </InputRow>
      <InputRow>
        <Label>이름</Label>
        <Input
          placeholder="이름"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "name")
          }
        />
      </InputRow>
      <InputRow>
        <Label>전화번호</Label>
        <Input
          placeholder="전화번호"
          value={phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "phone")
          }
        />
      </InputRow>
      <InputRow>
        <Label>생년월일</Label>
        <Input
          type="date"
          placeholder="생년월일"
          value={dateOfBirth}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "dateOfBirth")
          }
        />
      </InputRow>
      <InputRow>
        <Label>성별</Label>
        <select
          style={{ flex: 9 }}
          value={gender}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onGenderChange(e.target.value)
          }
        >
          <option value="FEMALE">여성</option>
          <option value="MALE">남성</option>
        </select>
      </InputRow>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>소개</Label>
        <Textarea
          placeholder="소개"
          value={introduction}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "introduction")
          }
        />
      </InputRow>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>관심분야</Label>
        <FieldsWrapper>
          {fields?.map((field) => {
            const isSelected = selectedFieldIds.has(field?.id);

            return (
              <Button
                key={field?.id}
                style={{
                  backgroundColor: isSelected
                    ? theme.color.black
                    : theme.color.black6,
                }}
                onClick={() => onFieldSelect(field?.id!)}
              >
                {field?.name}
              </Button>
            );
          })}
        </FieldsWrapper>
      </InputRow>
      <InputRow>
        <Label>프로필 사진</Label>
        <Input
          type="file"
          accept="image/*"
          placeholder="프로필 사진"
          onChange={onAvatarInputChange}
        />
      </InputRow>
      {avatarState && <Avatar src={URL.createObjectURL(avatarState)} />}
      {!avatarState && avatar && <Avatar src={avatar?.uri} />}
    </ReactModal>
  );
}

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head3,
  fontWeight: "bold",
  marginBottom: theme.spacing.middle,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing.normal,
  ...theme.font.body1,
  margin: `${theme.spacing.small}px 0px`,
}));

const Label = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  minHeight: 150,
}));

const FieldsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing.small,
}));

const Avatar = styled.img<any>(() => ({
  width: 150,
  height: 150,
  borderRadius: 150 / 2,
  objectFit: "cover",
}));

export default UpdateUserModal;
