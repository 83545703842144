import React from "react";

function MainContainer() {
  return (
    <div>
      <div></div>
    </div>
  );
}

export default MainContainer;
