import React, {useEffect} from 'react'
import { ChangeEvent, useState } from "react";
import {
  useGetBoardQuery,
  useUpdateBoardMutation,
  GetAllBoardsDocument,
  useDeleteBoardMutation,
} from "../../../lib/apollo/graphql/generated";

function useUpdateBoardModal(
  boardId?: number | null, 
  onRequestClose?: () => void,
  categorySplit?: (categoryStr: string) => void,
  setRoleArr?: (roleId: number) => void
  ) {

  const [state, setState] = useState({
    name: "",
    roleId: 0,
    type: 0,
    category: "",
    push: 0,
  })
  
  const { loading, data } = useGetBoardQuery({
    onCompleted: (data) => {
      if (data?.getBoard) {
        const { id, type, name, total, roleId, createdAt, updatedAt, category, push} = data?.getBoard

        categorySplit && categorySplit(category || '')
        setRoleArr && setRoleArr(roleId)
        
        setState({
          name,
          roleId,
          type,
          category: category || '',
          push,
        });

        
      }
    },
    variables: {
      id: boardId
    }
  });


  const [deleteBoard] =  useDeleteBoardMutation({
    onCompleted: () => {
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllBoardsDocument,
        variables: {
          maxPage: 20,
          page: 1, 
        },
      },
    ],
  });

  function onDeleteSelectedBoard() {
    const confirm = window.confirm('게시글이 전부 삭제됩니다. 삭제하시겠습니까?')
    
    if(!confirm) {
      return 
    }

    deleteBoard({
      variables: {
        id: boardId,
      },
    });
  }

  const [updateBoard] = useUpdateBoardMutation({
    onCompleted: () => {
      alert('수정되었습니다')
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllBoardsDocument,
        variables: {
          maxPage: 20,
          page: 1
        },
      },
    ],
  });

  function onUpdateBoard(changedDatas: any) {
    const { name, type, roleId, category, push } = changedDatas
    console.log(changedDatas)

    let updateDatas: any = {id: boardId}

    if (name) updateDatas = {...updateDatas, name}
    if (type != undefined) updateDatas={...updateDatas, type}
    if (roleId) updateDatas={...updateDatas, roleId}
    if (category) updateDatas={...updateDatas, category}
    if (push != undefined) updateDatas={...updateDatas, push}

    updateBoard({
      variables: updateDatas,
    });
  }

  function onInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onCheckboxChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) {
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: checked ? 1 : 0,
    }));
  }

  return {
    models: {
      state,
      loading,
      data: data?.getBoard,
    },
    operations: {
      onUpdateBoard,
      onDeleteSelectedBoard,
      onInputChange,
      onCheckboxChange
    },
  };
}

export default useUpdateBoardModal;
