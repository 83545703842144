import { InMemoryCache } from "@apollo/client";
import customRelayStylePagination from "./customRelayStylePagination";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getAllUsersAsAdmin: customRelayStylePagination(),
        getAllMentors: customRelayStylePagination(["requestedOnly"]),
        getAllTutors: customRelayStylePagination(["requestedOnly"]),
        getAllClassesAsAdmin: customRelayStylePagination(),
      },
    },
  },
});

export const setDefaultCache = (cache: any) => {
  console.info("set default cache:", cache);
};

setDefaultCache(cache);

export default cache;
