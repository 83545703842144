import { useState } from "react";
import {
  useGetCompanyInformationQuery,
  useUpdateCompanyInformationAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useCompanyInformationContainer() {
  const [state, setState] = useState({
    name: "",
    address: "",
    ceo: "",
    registrationNumber: "",
  });

  const { data, loading } = useGetCompanyInformationQuery({
    onCompleted: (data) => {
      if (data?.getCompanyInformation) {
        setState({
          name: data?.getCompanyInformation?.name || "",
          address: data?.getCompanyInformation?.address || "",
          ceo: data?.getCompanyInformation?.ceo || "",
          registrationNumber:
            data?.getCompanyInformation?.registrationNumber || "",
        });
      }
    },
  });

  const [updateCompanyInformationAsAdmin] =
    useUpdateCompanyInformationAsAdminMutation({
      onCompleted: () => {
        alert("저장 완료");
      },
    });

  function onChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    updateCompanyInformationAsAdmin({
      variables: {
        companyInformationInput: {
          ...state,
        },
      },
    });
  }

  return {
    models: {
      loading,
      state,
    },
    operations: {
      onChange,
      onSubmit,
    },
  };
}

export default useCompanyInformationContainer;
