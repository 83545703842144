import React from 'react'
import styled from 'styled-components'

type PaginationItemProps = {
	item : any 
	page: any 
	setPage: any 
}

const PaginationItem = ({ item, page, setPage }: PaginationItemProps) => {
	return (
		<>
			{item === page ? (
				<ClickedPageNumberBox>{item}</ClickedPageNumberBox>
			) : (
				<PageNumberBox onClick={() => setPage(item)}>{item}</PageNumberBox>
			)}
		</>
	)
}

export default PaginationItem

const ClickedPageNumberBox = styled.div`
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	background-color: #333333;
	color: #ffffff;
`
const PageNumberBox = styled.div`
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;

	/* content/line */

	/* border: 0.5px solid #425788; */
	border-radius: 1px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	&:hover {
		cursor: pointer;
	}
`

