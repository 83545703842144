import { format } from "date-fns";
import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useQuizContainer from "./QuizContainer.hook";
import { Button } from "../../common/styles/commonStyles";

function QuizContainer() {
  const {
    models: { loading, state, data, updateLoading },
    operations: { onInputChange, isButtonDisabled, onSubmit },
  } = useQuizContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <UpdatedAt>
        마지막 수정일 : {format(new Date(data?.updatedAt), "yyyy-MM-dd")}
      </UpdatedAt>
      <InputRow>
        <InputLabel>퀴즈</InputLabel>
        <Input
          value={state.quiz}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "quiz")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>보기1</InputLabel>
        <Input
          value={state.option1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "option1")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>보기2</InputLabel>
        <Input
          value={state.option2}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "option2")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>보기3</InputLabel>
        <Input
          value={state.option3}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "option3")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>보기4</InputLabel>
        <Input
          value={state.option4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "option4")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>답</InputLabel>
        <Input
          type="number"
          min="1"
          max="4"
          value={state.answer}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "answer")
          }
        />
      </InputRow>
      <Button disabled={isButtonDisabled()} onClick={onSubmit}>
        {updateLoading ? "수정중" : "수정하기"}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.normal,
  width: "100%",
  padding: theme.spacing.default,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  width: "50%",
}));

const InputLabel = styled.div<any>(() => ({
  width: 100,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.small,
  border: `1px solid ${theme.color.black6}`,
  flex: 1,
}));

const UpdatedAt = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  fontWeight: "bold",
}));

export default QuizContainer;
