import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetAllClassesAsAdminQuery } from "../../../lib/apollo/graphql/generated";

function useClassContainer() {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useGetAllClassesAsAdminQuery({
    variables: {
      first: 20,
    },
  });

  async function onFetchMore(inView: boolean) {
    if (inView && !fetchingMore) {
      setFetchingMore(true);

      const { hasNextPage, endCursor } = data?.getAllClassesAsAdmin?.pageInfo!;
      if (hasNextPage) {
        fetchMore({
          variables: {
            first: 20,
            after: endCursor,
          },
        }).finally(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAllClassesAsAdmin?.edges,
      fetchingMore,
    },
  };
}

export default useClassContainer;
