import { useEffect, useState } from "react";
import {
  GetFieldsDocument,
  useUpdateFieldAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useFieldCard(
  field: {
    __typename?: "Field" | undefined;
    id: string;
    name: string;
    active: boolean;
    sort: number;
    createdAt: any;
    updatedAt: any;
  } | null
) {
  const [state, setState] = useState({
    name: "",
    sort: 0,
    active: true,
  });

  const [updateFieldAsAdmin, { loading }] = useUpdateFieldAsAdminMutation({
    onCompleted: (data) => {
      if (field) {
        const { name, sort, active } = data?.updateFieldAsAdmin;
        setState({
          name,
          active,
          sort,
        });
      } else {
        setState({
          name: "",
          sort: 0,
          active: true,
        });
      }
    },
    refetchQueries: !field
      ? () => [
          {
            query: GetFieldsDocument,
          },
        ]
      : undefined,
  });

  useEffect(() => {
    if (field) {
      setState({
        name: field.name,
        sort: field.sort,
        active: field.active,
      });
    }
  }, [field?.id]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;
    const { checked } = e.target;

    if (key === "active") {
      setState((prev) => ({
        ...prev,
        [key]: checked,
      }));
    } else {
      if (key === "sort" && Number(value) < 1) {
        return;
      }

      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  }

  function isButtonDisabled() {
    const isFilled = state.name && state.sort > 0;

    return !isFilled || loading;
  }

  function onSubmit() {
    updateFieldAsAdmin({
      variables: {
        fieldInput: {
          id: field ? field?.id : null,
          name: state?.name,
          sort: Number(state?.sort),
          active: state?.active,
        },
      },
    });
  }

  return {
    models: {
      state,
      loading,
    },
    operations: {
      onInputChange,
      isButtonDisabled,
      onSubmit,
    },
  };
}

export default useFieldCard;
