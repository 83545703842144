import { useEffect, useState } from "react";
import {
  GetAreasDocument,
  useUpdateAreaAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useAreaCard(
  area: {
    __typename?: "Area" | undefined;
    id: string;
    name: string;
    sort: number;
    createdAt: any;
    updatedAt: any;
  } | null
) {
  const [state, setState] = useState({
    name: "",
    sort: 0,
  });

  const [updateAreaAsAdmin, { loading }] = useUpdateAreaAsAdminMutation({
    onCompleted: (data) => {
      if (area) {
        const { name, sort } = data?.updateAreaAsAdmin;
        setState({
          name,
          sort,
        });
      } else {
        setState({
          name: "",
          sort: 0,
        });
      }
    },
    refetchQueries: !area
      ? () => [
          {
            query: GetAreasDocument,
          },
        ]
      : undefined,
  });

  useEffect(() => {
    if (area) {
      setState({
        name: area.name,
        sort: area.sort,
      });
    }
  }, [area?.id]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    if (key === "sort" && Number(value) < 1) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function isButtonDisabled() {
    const isFilled = state.name && state.sort > 0;

    return !isFilled || loading;
  }

  function onSubmit() {
    updateAreaAsAdmin({
      variables: {
        areaInput: {
          id: area ? area?.id : null,
          name: state?.name,
          sort: Number(state?.sort),
        },
      },
    });
  }

  return {
    models: {
      state,
      loading,
    },
    operations: {
      onInputChange,
      isButtonDisabled,
      onSubmit,
    },
  };
}

export default useAreaCard;
