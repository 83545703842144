import { useGetAreasQuery } from "../../../lib/apollo/graphql/generated";

function useAreaContainer() {
  const { loading, data } = useGetAreasQuery();

  return {
    models: {
      loading,
      data: data?.getAreas,
    },
  };
}

export default useAreaContainer;
