import React, {useState, useEffect} from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import ReviewListTable from "../components/ReviewListTable";
import UnreviewedListTable from "../components/UnreviewedListTable";
import { Button } from "../../common/styles/commonStyles";

function ReviewContainer() {
  const theme = useTheme();
  const [review, setReview] = useState(true)
  const [tab, setTab] = useState(0)

  const onTabSelect = (id: number) => {
    setTab(id)

    // if(id==0) setReview(true) 
    // else setReview(false)
  }

  useEffect(()=>{
    if(tab==0) {
      setReview(true) 
    } else {
      setReview(false)
    }
  },[tab])

  return (
    <Wrapper>
        <ButtonWrapper>
          <Button
            style={{
              backgroundColor:
                tab == 0 ? theme.color.black1 : theme.color.black6,
            }}
            onClick={() => {onTabSelect(0)}}
          >
            작성된후기
          </Button>
          <Button
            style={{
              backgroundColor:
                tab == 1 ? theme.color.black1 : theme.color.black6,
            }}
            onClick={() => {onTabSelect(1)}}
          >
            작성전후기
          </Button>
        </ButtonWrapper>
      {
        review
        ?<ReviewListTable />
        :<UnreviewedListTable />
      }
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const ButtonWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: "10px",
  marginBottom: "20px"
}));
 

export default ReviewContainer;
