import { useState } from "react";
import { USER_TYPES } from "../../../../constants";

function useUserListContainer() {
  const [tab, setTab] = useState(USER_TYPES.ALL);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);

  function onTabSelect(selectedTab: string) {
    setTab(selectedTab);
  }

  function toggleShowUpdateUserModal(show: boolean) {
    setShowUpdateUserModal(show);
  }

  async function onExcelDownloadPress() {
    const download = await fetch(
      process.env.REACT_APP_API_URL + "/export_users"
    );

    download.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "users.xlsx";
      document.body.appendChild(a);
      a.click();

      a.remove();
    });

    alert("다운로드 완료");
  }

  return {
    models: {
      tab,
      showUpdateUserModal,
    },
    operations: {
      onTabSelect,
      toggleShowUpdateUserModal,
      onExcelDownloadPress,
    },
  };
}

export default useUserListContainer;
