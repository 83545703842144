import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  PhoneNumber: any;
  Upload: any;
};

export type Area = {
  __typename?: 'Area';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type AreaInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  sort: Scalars['Int'];
};

export type Banner = {
  __typename?: 'Banner';
  desc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  media: Mm;
  mediaId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type BannerConnection = {
  __typename?: 'BannerConnection';
  edges: Array<Maybe<Banner>>;
  pageInfo: PageInfo;
};

export type Board = {
  __typename?: 'Board';
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  push: Scalars['Int'];
  roleId: Scalars['Int'];
  total: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type Chat = {
  __typename?: 'Chat';
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  /** Returns if the requested user has unread message in the chat. */
  hasUnreadMessage: Scalars['Boolean'];
  id: Scalars['ID'];
  lastMessage?: Maybe<Message>;
  lastMessageAt: Scalars['DateTime'];
  tutor: User;
  tutorLastSeenAt: Scalars['DateTime'];
  user: User;
  userLastSeenAt: Scalars['DateTime'];
};

export type ChatConnection = {
  __typename?: 'ChatConnection';
  edges?: Maybe<Array<Maybe<Chat>>>;
  pageInfo: PageInfo;
};

export type Class = {
  __typename?: 'Class';
  active: Scalars['Boolean'];
  coverPhoto?: Maybe<Media>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  fields?: Maybe<Array<Maybe<Field>>>;
  id: Scalars['ID'];
  title: Scalars['String'];
  tutor: Tutor;
  updatedAt: Scalars['DateTime'];
};

export type ClassConnection = {
  __typename?: 'ClassConnection';
  edges: Array<Maybe<Class>>;
  pageInfo: PageInfo;
};

export type ClassInput = {
  coverPhotoInput?: InputMaybe<MediaInput>;
  description: Scalars['String'];
  duration: Scalars['Float'];
  id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  tutorId?: InputMaybe<Scalars['ID']>;
};

export type ClassSearchConnection = {
  __typename?: 'ClassSearchConnection';
  area?: Maybe<Area>;
  edges: Array<Maybe<Class>>;
  fields?: Maybe<Array<Maybe<Field>>>;
  pageInfo: PageInfo;
};

export type Comment = {
  __typename?: 'Comment';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  postId: Scalars['Int'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type CommentResult = {
  __typename?: 'CommentResult';
  result?: Maybe<Array<Maybe<Comment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CompanyInformation = {
  __typename?: 'CompanyInformation';
  address?: Maybe<Scalars['String']>;
  ceo?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
};

export type CompanyInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  ceo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
};

export type DeleteResult = {
  __typename?: 'DeleteResult';
  boardId?: Maybe<Scalars['Int']>;
};

export type Field = {
  __typename?: 'Field';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type FieldInput = {
  active: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  sort: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type FileList = {
  fileId?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  mediaId?: InputMaybe<Scalars['String']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type HomeClasses = {
  __typename?: 'HomeClasses';
  classes: Array<Maybe<Class>>;
  field: Field;
};

export type HomeMentor = {
  __typename?: 'HomeMentor';
  field: Field;
  mentors: Array<Maybe<Mentor>>;
};

export type HomeRecommends = {
  __typename?: 'HomeRecommends';
  classes: Array<Maybe<HomeClasses>>;
  mentors: Array<Maybe<HomeMentor>>;
  tutors: Array<Maybe<HomeTutors>>;
};

export type HomeTutors = {
  __typename?: 'HomeTutors';
  field: Field;
  tutors: Array<Maybe<Tutor>>;
};

export type Mm = {
  __typename?: 'MM';
  id?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type MediaInput = {
  file: Scalars['Upload'];
  thumbnailFile?: InputMaybe<Scalars['Upload']>;
};

export type Mentor = {
  __typename?: 'Mentor';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  experience: Scalars['String'];
  fields?: Maybe<Array<Maybe<Field>>>;
  id: Scalars['ID'];
  introduction: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  verified: Scalars['Boolean'];
};

export type MentorConnection = {
  __typename?: 'MentorConnection';
  edges: Array<Maybe<Mentor>>;
  pageInfo: PageInfo;
};

export type MentorSearchConnection = {
  __typename?: 'MentorSearchConnection';
  edges: Array<Maybe<Mentor>>;
  fields?: Maybe<Array<Maybe<Field>>>;
  pageInfo: PageInfo;
};

export type Message = {
  __typename?: 'Message';
  author: User;
  chat: Chat;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges?: Maybe<Array<Maybe<Message>>>;
  pageInfo: PageInfo;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['Boolean']>;
  addBannerImage?: Maybe<Scalars['Boolean']>;
  createBoard?: Maybe<Scalars['Boolean']>;
  createComment?: Maybe<Scalars['Boolean']>;
  createPost?: Maybe<Scalars['Boolean']>;
  createReview?: Maybe<Scalars['Boolean']>;
  deleteBanner?: Maybe<Scalars['Boolean']>;
  deleteBanners?: Maybe<Scalars['Boolean']>;
  deleteBoard?: Maybe<Scalars['Boolean']>;
  deleteComment?: Maybe<Scalars['Boolean']>;
  deletePost?: Maybe<DeleteResult>;
  deleteReview?: Maybe<Scalars['Boolean']>;
  deleteUserProfile: Scalars['Boolean'];
  getAllUsersByClass?: Maybe<Scalars['Boolean']>;
  pushMessage?: Maybe<Scalars['Boolean']>;
  /**
   * Sends a new message. Publishes updateChat and newMessage.
   * When sending text, media/attachment is not required.
   * When sending media, text is not required.
   */
  sendMessage: Message;
  startChat: Chat;
  toggleActiveMentor: Mentor;
  toggleActiveTutor: Tutor;
  toggleClassActive: Class;
  updateAreaAsAdmin: Area;
  updateBanner?: Maybe<Scalars['Boolean']>;
  updateBannerAsAdmin?: Maybe<Scalars['Boolean']>;
  updateBoard?: Maybe<Scalars['Boolean']>;
  updateClassBasic: Class;
  updateClassCoverPhoto: Class;
  updateClassFields: Class;
  updateComment?: Maybe<Scalars['Boolean']>;
  updateCompanyInformationAsAdmin: CompanyInformation;
  updateFieldAsAdmin: Field;
  updateLastSeenAt: Chat;
  /**
   *  Update user's device token to send push notification
   *
   * if new token is not provided, it only deletes user's message token
   *
   * if old token is not provided, it only adds the message token for the device
   *
   * else, it updates message token for the device
   */
  updateMessagingToken: Scalars['Boolean'];
  updateMyAvatar: User;
  updateMyMentorBasicProfile: Mentor;
  updateMyMentorFields: Mentor;
  updateMyTutorBasicProfile: Tutor;
  updateMyTutorFields: Tutor;
  updateMyUserProfile: User;
  updateOrder?: Maybe<Scalars['Boolean']>;
  /** Update terms of service or privacy policy as admin */
  updatePolicyAsAdmin: Policy;
  updatePost?: Maybe<Scalars['Boolean']>;
  updateReligionQuizAsAdmin: ReligionQuiz;
  updateReview?: Maybe<Scalars['Boolean']>;
  /** Update mentorRule of service or tutorRule policy as admin */
  updateRulesAsAdmin: Rules;
  updateUserFields: User;
  updateUserProfileAsAdmin: User;
  uploadFile?: Maybe<UploadFileResult>;
  uploadImage?: Maybe<UploadImageResult>;
  verifyMentorAsAdmin: Mentor;
  verifyTutorAsAdmin: Tutor;
};


export type MutationAddBannerImageArgs = {
  avatarInput?: InputMaybe<MediaInput>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBoardArgs = {
  category?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  push?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateCommentArgs = {
  boardId?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreatePostArgs = {
  boardId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  fileList?: InputMaybe<Array<InputMaybe<FileList>>>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateReviewArgs = {
  classId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteBannerArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteBannersArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteBoardArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteCommentArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeletePostArgs = {
  boardId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteReviewArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteUserProfileArgs = {
  reason?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationGetAllUsersByClassArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type MutationPushMessageArgs = {
  classUserId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationSendMessageArgs = {
  chatId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};


export type MutationStartChatArgs = {
  tutorId: Scalars['ID'];
};


export type MutationToggleActiveMentorArgs = {
  active: Scalars['Boolean'];
  mentorId?: InputMaybe<Scalars['ID']>;
};


export type MutationToggleActiveTutorArgs = {
  active: Scalars['Boolean'];
  mentorActive: Scalars['Boolean'];
  tutorId?: InputMaybe<Scalars['ID']>;
};


export type MutationToggleClassActiveArgs = {
  active: Scalars['Boolean'];
  classId: Scalars['ID'];
};


export type MutationUpdateAreaAsAdminArgs = {
  areaInput: AreaInput;
};


export type MutationUpdateBannerArgs = {
  avatarInput?: InputMaybe<Array<InputMaybe<MediaInput>>>;
  desc?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBannerAsAdminArgs = {
  avatarInput?: InputMaybe<MediaInput>;
  desc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBoardArgs = {
  category?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  push?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateClassBasicArgs = {
  classInput: ClassInput;
};


export type MutationUpdateClassCoverPhotoArgs = {
  classId: Scalars['ID'];
  mediaInput: MediaInput;
};


export type MutationUpdateClassFieldsArgs = {
  classId: Scalars['ID'];
  fieldIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationUpdateCommentArgs = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateCompanyInformationAsAdminArgs = {
  companyInformationInput: CompanyInformationInput;
};


export type MutationUpdateFieldAsAdminArgs = {
  fieldInput: FieldInput;
};


export type MutationUpdateLastSeenAtArgs = {
  chatId: Scalars['ID'];
};


export type MutationUpdateMessagingTokenArgs = {
  newToken?: InputMaybe<Scalars['String']>;
  oldToken?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMyAvatarArgs = {
  avatarInput?: InputMaybe<MediaInput>;
};


export type MutationUpdateMyMentorBasicProfileArgs = {
  experience: Scalars['String'];
  introduction: Scalars['String'];
};


export type MutationUpdateMyMentorFieldsArgs = {
  fieldIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationUpdateMyTutorBasicProfileArgs = {
  areaIds: Array<InputMaybe<Scalars['ID']>>;
  experience: Scalars['String'];
  introduction: Scalars['String'];
};


export type MutationUpdateMyTutorFieldsArgs = {
  fieldIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationUpdateMyUserProfileArgs = {
  userInput: UserInput;
};


export type MutationUpdateOrderArgs = {
  id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  order?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationUpdatePolicyAsAdminArgs = {
  policyInput: PolicyInput;
};


export type MutationUpdatePostArgs = {
  category?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateReligionQuizAsAdminArgs = {
  religionQuizInput: ReligionQuizInput;
};


export type MutationUpdateReviewArgs = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateRulesAsAdminArgs = {
  rulesInput: RulesInput;
};


export type MutationUpdateUserFieldsArgs = {
  fieldIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationUpdateUserProfileAsAdminArgs = {
  avatarInput?: InputMaybe<MediaInput>;
  userId?: InputMaybe<Scalars['ID']>;
  userInput?: InputMaybe<UserInput>;
};


export type MutationUploadFileArgs = {
  fileInput?: InputMaybe<MediaInput>;
  fileName?: InputMaybe<Scalars['String']>;
};


export type MutationUploadImageArgs = {
  avatarInput?: InputMaybe<MediaInput>;
};


export type MutationVerifyMentorAsAdminArgs = {
  mentorId: Scalars['ID'];
  verified: Scalars['Boolean'];
};


export type MutationVerifyTutorAsAdminArgs = {
  tutorId: Scalars['ID'];
  verified: Scalars['Boolean'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type Policy = {
  __typename?: 'Policy';
  id: Scalars['Int'];
  privacy?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
};

export type PolicyInput = {
  privacy?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
};

export type Post = {
  __typename?: 'Post';
  boardId: Scalars['Int'];
  category: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  postFiles?: Maybe<Array<Maybe<PostFile>>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type PostConnection = {
  __typename?: 'PostConnection';
  edges: Array<Maybe<PostSummary>>;
  pageInfo: PageInfo;
};

export type PostFile = {
  __typename?: 'PostFile';
  fileName: Scalars['String'];
  id: Scalars['Int'];
  media?: Maybe<Media>;
  mediaId: Scalars['String'];
  postId: Scalars['Int'];
};

export type PostResult = {
  __typename?: 'PostResult';
  result?: Maybe<Array<Maybe<PostSummary>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PostSummary = {
  __typename?: 'PostSummary';
  boardId: Scalars['Int'];
  category: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  postFiles?: Maybe<Array<Maybe<PostFile>>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Posts = {
  __typename?: 'Posts';
  postConnection?: Maybe<PostConnection>;
  total?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['Boolean']>;
  getAllBanners?: Maybe<BannerConnection>;
  getAllBoards?: Maybe<Array<Maybe<Board>>>;
  getAllClassesAsAdmin: ClassConnection;
  getAllMentors: MentorConnection;
  getAllPosts?: Maybe<PostResult>;
  getAllPostsTemp?: Maybe<Posts>;
  getAllReviews?: Maybe<Array<Maybe<Review>>>;
  getAllReviewsTemp?: Maybe<ReviewConnection>;
  getAllTutors: TutorConnection;
  getAllUnreviewed?: Maybe<UnreviewedConnection>;
  getAllUsersAsAdmin: UserConnection;
  getAreas: Array<Maybe<Area>>;
  getBanner?: Maybe<Banner>;
  getBoard?: Maybe<Board>;
  getChat: Chat;
  /**
   * Get a list of messages in a given chat. Publishes updateChat to notify users
   * that lastSeenAt timestamp is updated.
   *
   * All notifications attached to this chat will be marked as checked.
   *
   * Supports pagination.
   */
  getChatMessages: MessageConnection;
  getChatWithTutorId?: Maybe<Chat>;
  getClass: Class;
  getClassesByField: ClassConnection;
  getComments?: Maybe<CommentResult>;
  getCompanyInformation: CompanyInformation;
  getFields: Array<Maybe<Field>>;
  getHomeRecommends: HomeRecommends;
  getMentor: Mentor;
  getMentorsByField: MentorConnection;
  getMyChats: ChatConnection;
  getMyClasses: ClassConnection;
  getMyReviews?: Maybe<Array<Maybe<Review>>>;
  getMyReviewsTemp?: Maybe<ReviewConnection>;
  getMyUserProfile?: Maybe<User>;
  /** Get terms of service and privacy policy */
  getPolicies: Policy;
  getPost?: Maybe<Post>;
  getReligionQuiz: ReligionQuiz;
  getReview?: Maybe<Review>;
  /** Get mentorRule of service and tutorRule policy */
  getRules: Rules;
  getTutor: Tutor;
  getTutorsByField: TutorConnection;
  getUserProfile?: Maybe<User>;
  isUniqueEmail: Scalars['Boolean'];
  isUniqueUsername: Scalars['Boolean'];
  searchClasses: ClassSearchConnection;
  searchMentors: MentorSearchConnection;
  searchTutors: TutorSearchConnection;
};


export type QueryGetAllBannersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllBoardsArgs = {
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllClassesAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllMentorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  requestedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetAllPostsArgs = {
  boardId?: InputMaybe<Scalars['Int']>;
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllPostsTempArgs = {
  after?: InputMaybe<Scalars['String']>;
  boardId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  searchCategory?: InputMaybe<Scalars['String']>;
  searchWord?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllReviewsArgs = {
  classId?: InputMaybe<Scalars['String']>;
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllReviewsTempArgs = {
  after?: InputMaybe<Scalars['String']>;
  boardId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllTutorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  requestedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetAllUnreviewedArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllUsersAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBannerArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBoardArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetChatArgs = {
  chatId: Scalars['ID'];
};


export type QueryGetChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  chatId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetChatWithTutorIdArgs = {
  tutorId: Scalars['ID'];
};


export type QueryGetClassArgs = {
  classId: Scalars['ID'];
};


export type QueryGetClassesByFieldArgs = {
  after?: InputMaybe<Scalars['String']>;
  fieldId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommentsArgs = {
  boardId?: InputMaybe<Scalars['Int']>;
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMentorArgs = {
  mentorId: Scalars['ID'];
};


export type QueryGetMentorsByFieldArgs = {
  after?: InputMaybe<Scalars['String']>;
  fieldId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMyChatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMyClassesArgs = {
  active: Scalars['Boolean'];
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMyReviewsArgs = {
  classId?: InputMaybe<Scalars['String']>;
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMyReviewsTempArgs = {
  after?: InputMaybe<Scalars['String']>;
  boardId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPostArgs = {
  boardId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetReviewArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTutorArgs = {
  tutorId: Scalars['ID'];
};


export type QueryGetTutorsByFieldArgs = {
  after?: InputMaybe<Scalars['String']>;
  fieldId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUserProfileArgs = {
  userId: Scalars['ID'];
};


export type QueryIsUniqueEmailArgs = {
  email: Scalars['String'];
};


export type QueryIsUniqueUsernameArgs = {
  username: Scalars['String'];
};


export type QuerySearchClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  areaId?: InputMaybe<Scalars['ID']>;
  fieldIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchMentorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  fieldIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchTutorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  areaId?: InputMaybe<Scalars['ID']>;
  fieldIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ReligionQuiz = {
  __typename?: 'ReligionQuiz';
  answer: Scalars['Int'];
  id: Scalars['ID'];
  option1: Scalars['String'];
  option2: Scalars['String'];
  option3: Scalars['String'];
  option4: Scalars['String'];
  quiz: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ReligionQuizInput = {
  answer: Scalars['Int'];
  option1: Scalars['String'];
  option2: Scalars['String'];
  option3: Scalars['String'];
  option4: Scalars['String'];
  quiz: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  class: Class;
  classId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  score: Scalars['Int'];
  user: User;
  userId: Scalars['String'];
};

export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  edges: Array<Maybe<Review>>;
  pageInfo: PageInfo;
};

export type Rules = {
  __typename?: 'Rules';
  id: Scalars['Int'];
  mentorRule?: Maybe<Scalars['String']>;
  tutorRule?: Maybe<Scalars['String']>;
};

export type RulesInput = {
  mentorRule?: InputMaybe<Scalars['String']>;
  tutorRule?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
  /** Published whenever a new message is sent in a chat. */
  newMessage?: Maybe<Message>;
  /** Published whenever a new message is sent or a user reads unread messages. */
  updateChat?: Maybe<Chat>;
};


export type SubscriptionNewMessageArgs = {
  chatId: Scalars['ID'];
};

export type Tutor = {
  __typename?: 'Tutor';
  active: Scalars['Boolean'];
  areas?: Maybe<Array<Maybe<Area>>>;
  classes: Array<Maybe<Class>>;
  createdAt: Scalars['DateTime'];
  experience?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Field>>>;
  id: Scalars['ID'];
  introduction?: Maybe<Scalars['String']>;
  mentorActive: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: User;
  verified: Scalars['Boolean'];
};

export type TutorConnection = {
  __typename?: 'TutorConnection';
  edges: Array<Maybe<Tutor>>;
  pageInfo: PageInfo;
};

export type TutorSearchConnection = {
  __typename?: 'TutorSearchConnection';
  area?: Maybe<Area>;
  edges: Array<Maybe<Tutor>>;
  fields?: Maybe<Array<Maybe<Field>>>;
  pageInfo: PageInfo;
};

export type Unreviewed = {
  __typename?: 'Unreviewed';
  class: Class;
  classId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  push: Scalars['Int'];
  pushedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['String'];
};

export type UnreviewedConnection = {
  __typename?: 'UnreviewedConnection';
  edges: Array<Maybe<Unreviewed>>;
  pageInfo: PageInfo;
};

export type UploadFileResult = {
  __typename?: 'UploadFileResult';
  fileId?: Maybe<Scalars['Int']>;
  mediaId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UploadImageResult = {
  __typename?: 'UploadImageResult';
  path?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Media>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fields: Array<Maybe<Field>>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  introduction?: Maybe<Scalars['String']>;
  mentor?: Maybe<Mentor>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  resignReason?: Maybe<Scalars['String']>;
  tutor?: Maybe<Tutor>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<User>>;
  pageInfo: PageInfo;
};

export type UserInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email: Scalars['String'];
  fieldIds: Array<InputMaybe<Scalars['ID']>>;
  gender?: InputMaybe<Gender>;
  introduction?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type GetPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPoliciesQuery = { __typename?: 'Query', getPolicies: { __typename?: 'Policy', id: number, terms?: string | null, privacy?: string | null } };

export type GetRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRulesQuery = { __typename?: 'Query', getRules: { __typename?: 'Rules', id: number, mentorRule?: string | null, tutorRule?: string | null } };

export type GetCompanyInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyInformationQuery = { __typename?: 'Query', getCompanyInformation: { __typename?: 'CompanyInformation', id: number, name?: string | null, address?: string | null, ceo?: string | null, registrationNumber?: string | null } };

export type UpdatePolicyAsAdminMutationVariables = Exact<{
  policyInput: PolicyInput;
}>;


export type UpdatePolicyAsAdminMutation = { __typename?: 'Mutation', updatePolicyAsAdmin: { __typename?: 'Policy', id: number, terms?: string | null, privacy?: string | null } };

export type UpdateRulesAsAdminMutationVariables = Exact<{
  rulesInput: RulesInput;
}>;


export type UpdateRulesAsAdminMutation = { __typename?: 'Mutation', updateRulesAsAdmin: { __typename?: 'Rules', id: number, mentorRule?: string | null, tutorRule?: string | null } };

export type UpdateCompanyInformationAsAdminMutationVariables = Exact<{
  companyInformationInput: CompanyInformationInput;
}>;


export type UpdateCompanyInformationAsAdminMutation = { __typename?: 'Mutation', updateCompanyInformationAsAdmin: { __typename?: 'CompanyInformation', id: number, name?: string | null, address?: string | null, ceo?: string | null, registrationNumber?: string | null } };

export type GetAreasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAreasQuery = { __typename?: 'Query', getAreas: Array<{ __typename?: 'Area', id: string, name: string, sort: number, createdAt: any, updatedAt: any } | null> };

export type UpdateAreaAsAdminMutationVariables = Exact<{
  areaInput: AreaInput;
}>;


export type UpdateAreaAsAdminMutation = { __typename?: 'Mutation', updateAreaAsAdmin: { __typename?: 'Area', id: string, name: string, sort: number, createdAt: any, updatedAt: any } };

export type GetAllBannersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllBannersQuery = { __typename?: 'Query', getAllBanners?: { __typename?: 'BannerConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Banner', id?: number | null, mediaId?: string | null, desc?: string | null, order?: number | null, link?: string | null, type?: string | null, media: { __typename?: 'MM', id?: string | null, path?: string | null } } | null> } | null };

export type GetBannerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetBannerQuery = { __typename?: 'Query', getBanner?: { __typename?: 'Banner', id?: number | null, mediaId?: string | null, desc?: string | null, order?: number | null, link?: string | null, type?: string | null, media: { __typename?: 'MM', id?: string | null, path?: string | null } } | null };

export type UpdateBannerMutationVariables = Exact<{
  desc?: InputMaybe<Scalars['String']>;
  avatarInput?: InputMaybe<Array<InputMaybe<MediaInput>> | InputMaybe<MediaInput>>;
  type?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UpdateBannerMutation = { __typename?: 'Mutation', updateBanner?: boolean | null };

export type UpdateBannerAsAdminMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  desc?: InputMaybe<Scalars['String']>;
  avatarInput?: InputMaybe<MediaInput>;
  order?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
}>;


export type UpdateBannerAsAdminMutation = { __typename?: 'Mutation', updateBannerAsAdmin?: boolean | null };

export type UpdateOrderMutationVariables = Exact<{
  id?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  order?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder?: boolean | null };

export type DeleteBannerMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type DeleteBannerMutation = { __typename?: 'Mutation', deleteBanner?: boolean | null };

export type DeleteBannersMutationVariables = Exact<{
  type?: InputMaybe<Scalars['String']>;
}>;


export type DeleteBannersMutation = { __typename?: 'Mutation', deleteBanners?: boolean | null };

export type AddBannerImageMutationVariables = Exact<{
  type?: InputMaybe<Scalars['String']>;
  avatarInput?: InputMaybe<MediaInput>;
}>;


export type AddBannerImageMutation = { __typename?: 'Mutation', addBannerImage?: boolean | null };

export type GetAllBoardsQueryVariables = Exact<{
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllBoardsQuery = { __typename?: 'Query', getAllBoards?: Array<{ __typename?: 'Board', id: number, type: number, name: string, total: number, roleId: number, createdAt: any, updatedAt: any, category?: string | null, push: number } | null> | null };

export type GetBoardQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetBoardQuery = { __typename?: 'Query', getBoard?: { __typename?: 'Board', id: number, type: number, name: string, total: number, roleId: number, createdAt: any, updatedAt: any, category?: string | null, push: number } | null };

export type CreateBoardMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  push?: InputMaybe<Scalars['Int']>;
}>;


export type CreateBoardMutation = { __typename?: 'Mutation', createBoard?: boolean | null };

export type UpdateBoardMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  push?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateBoardMutation = { __typename?: 'Mutation', updateBoard?: boolean | null };

export type DeleteBoardMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type DeleteBoardMutation = { __typename?: 'Mutation', deleteBoard?: boolean | null };

export type GetAllClassesAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllClassesAsAdminQuery = { __typename?: 'Query', getAllClassesAsAdmin: { __typename?: 'ClassConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Class', id: string, title: string, description: string, duration: number, active: boolean, createdAt: any, updatedAt: any, tutor: { __typename?: 'Tutor', id: string, introduction?: string | null, experience?: string | null, verified: boolean, active: boolean, mentorActive: boolean, createdAt: any, updatedAt: any, areas?: Array<{ __typename?: 'Area', id: string, name: string } | null> | null, user: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, deletedAt?: any | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null } }, coverPhoto?: { __typename?: 'Media', id: string, uri: string } | null, fields?: Array<{ __typename?: 'Field', id: string, name: string } | null> | null } | null> } };

export type ToggleClassActiveMutationVariables = Exact<{
  classId: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type ToggleClassActiveMutation = { __typename?: 'Mutation', toggleClassActive: { __typename?: 'Class', id: string, active: boolean } };

export type GetCommentsQueryVariables = Exact<{
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['Int']>;
  boardId?: InputMaybe<Scalars['Int']>;
}>;


export type GetCommentsQuery = { __typename?: 'Query', getComments?: { __typename?: 'CommentResult', total?: number | null, result?: Array<{ __typename?: 'Comment', content: string, createdAt: any, id: number, postId: number, userId?: string | null, user?: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, resignReason?: string | null } | null } | null> | null } | null };

export type CreateCommentMutationVariables = Exact<{
  postId?: InputMaybe<Scalars['Int']>;
  boardId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment?: boolean | null };

export type UpdateCommentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment?: boolean | null };

export type DeleteCommentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment?: boolean | null };

export type GetFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFieldsQuery = { __typename?: 'Query', getFields: Array<{ __typename?: 'Field', id: string, name: string, active: boolean, sort: number, createdAt: any, updatedAt: any } | null> };

export type UpdateFieldAsAdminMutationVariables = Exact<{
  fieldInput: FieldInput;
}>;


export type UpdateFieldAsAdminMutation = { __typename?: 'Mutation', updateFieldAsAdmin: { __typename?: 'Field', id: string, name: string, active: boolean, sort: number, createdAt: any, updatedAt: any } };

export type GetAllPostsQueryVariables = Exact<{
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  boardId?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllPostsQuery = { __typename?: 'Query', getAllPosts?: { __typename?: 'PostResult', total?: number | null, result?: Array<{ __typename?: 'PostSummary', boardId: number, category: string, content: string, createdAt: any, id: number, title: string, updatedAt: any, username?: string | null, userId?: string | null } | null> | null } | null };

export type GetAllPostsTempQueryVariables = Exact<{
  boardId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  searchCategory?: InputMaybe<Scalars['String']>;
  searchWord?: InputMaybe<Scalars['String']>;
}>;


export type GetAllPostsTempQuery = { __typename?: 'Query', getAllPostsTemp?: { __typename?: 'Posts', total?: number | null, postConnection?: { __typename?: 'PostConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'PostSummary', id: number, boardId: number, userId?: string | null, title: string, createdAt: any, updatedAt: any, content: string, category: string, username?: string | null } | null> } | null } | null };

export type GetPostQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  boardId?: InputMaybe<Scalars['Int']>;
}>;


export type GetPostQuery = { __typename?: 'Query', getPost?: { __typename?: 'Post', id: number, boardId: number, userId?: string | null, title: string, content: string, category: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, resignReason?: string | null } | null } | null };

export type CreatePostMutationVariables = Exact<{
  boardId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  fileList?: InputMaybe<Array<InputMaybe<FileList>> | InputMaybe<FileList>>;
}>;


export type CreatePostMutation = { __typename?: 'Mutation', createPost?: boolean | null };

export type UpdatePostMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePostMutation = { __typename?: 'Mutation', updatePost?: boolean | null };

export type DeletePostMutationVariables = Exact<{
  boardId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type DeletePostMutation = { __typename?: 'Mutation', deletePost?: { __typename?: 'DeleteResult', boardId?: number | null } | null };

export type UploadImageMutationVariables = Exact<{
  avatarInput?: InputMaybe<MediaInput>;
}>;


export type UploadImageMutation = { __typename?: 'Mutation', uploadImage?: { __typename?: 'UploadImageResult', success?: boolean | null, path?: string | null } | null };

export type UploadFileMutationVariables = Exact<{
  fileInput?: InputMaybe<MediaInput>;
  fileName?: InputMaybe<Scalars['String']>;
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile?: { __typename?: 'UploadFileResult', success?: boolean | null, mediaId?: string | null, fileId?: number | null } | null };

export type GetReligionQuizQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReligionQuizQuery = { __typename?: 'Query', getReligionQuiz: { __typename?: 'ReligionQuiz', id: string, quiz: string, option1: string, option2: string, option3: string, option4: string, answer: number, updatedAt: any } };

export type UpdateReligionQuizAsAdminMutationVariables = Exact<{
  religionQuizInput: ReligionQuizInput;
}>;


export type UpdateReligionQuizAsAdminMutation = { __typename?: 'Mutation', updateReligionQuizAsAdmin: { __typename?: 'ReligionQuiz', id: string, quiz: string, option1: string, option2: string, option3: string, option4: string, answer: number, updatedAt: any } };

export type GetAllReviewsQueryVariables = Exact<{
  maxPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllReviewsQuery = { __typename?: 'Query', getAllReviews?: Array<{ __typename?: 'Review', id: number, userId: string, classId: string, content: string, score: number, createdAt: any, user: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, resignReason?: string | null } } | null> | null };

export type GetAllReviewsTempQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllReviewsTempQuery = { __typename?: 'Query', getAllReviewsTemp?: { __typename?: 'ReviewConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Review', id: number, userId: string, classId: string, content: string, score: number, createdAt: any, user: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, resignReason?: string | null }, class: { __typename?: 'Class', id: string, title: string, description: string, duration: number, active: boolean, createdAt: any } } | null> } | null };

export type GetAllUnreviewedQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllUnreviewedQuery = { __typename?: 'Query', getAllUnreviewed?: { __typename?: 'UnreviewedConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Unreviewed', id: string, userId: string, classId: string, createdAt: any, push: number, pushedAt?: any | null, user: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, resignReason?: string | null }, class: { __typename?: 'Class', id: string, title: string, description: string, duration: number, active: boolean, createdAt: any } } | null> } | null };

export type GetReviewQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type GetReviewQuery = { __typename?: 'Query', getReview?: { __typename?: 'Review', id: number, userId: string, classId: string, content: string, score: number, createdAt: any, user: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, resignReason?: string | null } } | null };

export type CreateReviewMutationVariables = Exact<{
  classId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
}>;


export type CreateReviewMutation = { __typename?: 'Mutation', createReview?: boolean | null };

export type UpdateReviewMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateReviewMutation = { __typename?: 'Mutation', updateReview?: boolean | null };

export type DeleteReviewMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type DeleteReviewMutation = { __typename?: 'Mutation', deleteReview?: boolean | null };

export type PushMessageMutationVariables = Exact<{
  classUserId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
}>;


export type PushMessageMutation = { __typename?: 'Mutation', pushMessage?: boolean | null };

export type GetAllUsersAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllUsersAsAdminQuery = { __typename?: 'Query', getAllUsersAsAdmin: { __typename?: 'UserConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, resignReason?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, fields: Array<{ __typename?: 'Field', id: string, name: string, sort: number } | null>, avatar?: { __typename?: 'Media', id: string, uri: string } | null, mentor?: { __typename?: 'Mentor', id: string, verified: boolean } | null, tutor?: { __typename?: 'Tutor', id: string, verified: boolean } | null } | null> } };

export type GetAllMentorsQueryVariables = Exact<{
  requestedOnly?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllMentorsQuery = { __typename?: 'Query', getAllMentors: { __typename?: 'MentorConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Mentor', id: string, introduction: string, experience: string, verified: boolean, active: boolean, createdAt: any, updatedAt: any, fields?: Array<{ __typename?: 'Field', id: string, name: string, sort: number } | null> | null, user: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, deletedAt?: any | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null } } | null> } };

export type GetAllTutorsQueryVariables = Exact<{
  requestedOnly?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAllTutorsQuery = { __typename?: 'Query', getAllTutors: { __typename?: 'TutorConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Tutor', id: string, introduction?: string | null, experience?: string | null, verified: boolean, active: boolean, createdAt: any, updatedAt: any, fields?: Array<{ __typename?: 'Field', id: string, name: string, sort: number } | null> | null, areas?: Array<{ __typename?: 'Area', id: string, name: string, sort: number } | null> | null, user: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, deletedAt?: any | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null } } | null> } };

export type VerifyMentorAsAdminMutationVariables = Exact<{
  mentorId: Scalars['ID'];
  verified: Scalars['Boolean'];
}>;


export type VerifyMentorAsAdminMutation = { __typename?: 'Mutation', verifyMentorAsAdmin: { __typename?: 'Mentor', id: string, verified: boolean } };

export type VerifyTutorAsAdminMutationVariables = Exact<{
  tutorId: Scalars['ID'];
  verified: Scalars['Boolean'];
}>;


export type VerifyTutorAsAdminMutation = { __typename?: 'Mutation', verifyTutorAsAdmin: { __typename?: 'Tutor', id: string, verified: boolean } };

export type UpdateUserProfileAsAdminMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
  userInput?: InputMaybe<UserInput>;
  avatarInput?: InputMaybe<MediaInput>;
}>;


export type UpdateUserProfileAsAdminMutation = { __typename?: 'Mutation', updateUserProfileAsAdmin: { __typename?: 'User', id: string, name?: string | null, username: string, email?: string | null, dateOfBirth?: any | null, gender?: Gender | null, phone?: string | null, introduction?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, fields: Array<{ __typename?: 'Field', id: string, name: string, sort: number } | null>, avatar?: { __typename?: 'Media', id: string, uri: string } | null, mentor?: { __typename?: 'Mentor', id: string, verified: boolean } | null, tutor?: { __typename?: 'Tutor', id: string, verified: boolean } | null } };

export type ToggleActiveMentorMutationVariables = Exact<{
  active: Scalars['Boolean'];
  mentorId?: InputMaybe<Scalars['ID']>;
}>;


export type ToggleActiveMentorMutation = { __typename?: 'Mutation', toggleActiveMentor: { __typename?: 'Mentor', id: string, active: boolean } };

export type ToggleActiveTutorMutationVariables = Exact<{
  active: Scalars['Boolean'];
  mentorActive: Scalars['Boolean'];
  tutorId?: InputMaybe<Scalars['ID']>;
}>;


export type ToggleActiveTutorMutation = { __typename?: 'Mutation', toggleActiveTutor: { __typename?: 'Tutor', id: string, mentorActive: boolean, active: boolean } };

export type DeleteUserProfileMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
}>;


export type DeleteUserProfileMutation = { __typename?: 'Mutation', deleteUserProfile: boolean };


export const GetPoliciesDocument = gql`
    query GetPolicies {
  getPolicies {
    id
    terms
    privacy
  }
}
    `;

/**
 * __useGetPoliciesQuery__
 *
 * To run a query within a React component, call `useGetPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
      }
export function useGetPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
        }
export type GetPoliciesQueryHookResult = ReturnType<typeof useGetPoliciesQuery>;
export type GetPoliciesLazyQueryHookResult = ReturnType<typeof useGetPoliciesLazyQuery>;
export type GetPoliciesQueryResult = Apollo.QueryResult<GetPoliciesQuery, GetPoliciesQueryVariables>;
export const GetRulesDocument = gql`
    query GetRules {
  getRules {
    id
    mentorRule
    tutorRule
  }
}
    `;

/**
 * __useGetRulesQuery__
 *
 * To run a query within a React component, call `useGetRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRulesQuery(baseOptions?: Apollo.QueryHookOptions<GetRulesQuery, GetRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRulesQuery, GetRulesQueryVariables>(GetRulesDocument, options);
      }
export function useGetRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRulesQuery, GetRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRulesQuery, GetRulesQueryVariables>(GetRulesDocument, options);
        }
export type GetRulesQueryHookResult = ReturnType<typeof useGetRulesQuery>;
export type GetRulesLazyQueryHookResult = ReturnType<typeof useGetRulesLazyQuery>;
export type GetRulesQueryResult = Apollo.QueryResult<GetRulesQuery, GetRulesQueryVariables>;
export const GetCompanyInformationDocument = gql`
    query GetCompanyInformation {
  getCompanyInformation {
    id
    name
    address
    ceo
    registrationNumber
  }
}
    `;

/**
 * __useGetCompanyInformationQuery__
 *
 * To run a query within a React component, call `useGetCompanyInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyInformationQuery, GetCompanyInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyInformationQuery, GetCompanyInformationQueryVariables>(GetCompanyInformationDocument, options);
      }
export function useGetCompanyInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyInformationQuery, GetCompanyInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyInformationQuery, GetCompanyInformationQueryVariables>(GetCompanyInformationDocument, options);
        }
export type GetCompanyInformationQueryHookResult = ReturnType<typeof useGetCompanyInformationQuery>;
export type GetCompanyInformationLazyQueryHookResult = ReturnType<typeof useGetCompanyInformationLazyQuery>;
export type GetCompanyInformationQueryResult = Apollo.QueryResult<GetCompanyInformationQuery, GetCompanyInformationQueryVariables>;
export const UpdatePolicyAsAdminDocument = gql`
    mutation UpdatePolicyAsAdmin($policyInput: PolicyInput!) {
  updatePolicyAsAdmin(policyInput: $policyInput) {
    id
    terms
    privacy
  }
}
    `;
export type UpdatePolicyAsAdminMutationFn = Apollo.MutationFunction<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>;

/**
 * __useUpdatePolicyAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAsAdminMutation, { data, loading, error }] = useUpdatePolicyAsAdminMutation({
 *   variables: {
 *      policyInput: // value for 'policyInput'
 *   },
 * });
 */
export function useUpdatePolicyAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>(UpdatePolicyAsAdminDocument, options);
      }
export type UpdatePolicyAsAdminMutationHookResult = ReturnType<typeof useUpdatePolicyAsAdminMutation>;
export type UpdatePolicyAsAdminMutationResult = Apollo.MutationResult<UpdatePolicyAsAdminMutation>;
export type UpdatePolicyAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>;
export const UpdateRulesAsAdminDocument = gql`
    mutation UpdateRulesAsAdmin($rulesInput: RulesInput!) {
  updateRulesAsAdmin(rulesInput: $rulesInput) {
    id
    mentorRule
    tutorRule
  }
}
    `;
export type UpdateRulesAsAdminMutationFn = Apollo.MutationFunction<UpdateRulesAsAdminMutation, UpdateRulesAsAdminMutationVariables>;

/**
 * __useUpdateRulesAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateRulesAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRulesAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRulesAsAdminMutation, { data, loading, error }] = useUpdateRulesAsAdminMutation({
 *   variables: {
 *      rulesInput: // value for 'rulesInput'
 *   },
 * });
 */
export function useUpdateRulesAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRulesAsAdminMutation, UpdateRulesAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRulesAsAdminMutation, UpdateRulesAsAdminMutationVariables>(UpdateRulesAsAdminDocument, options);
      }
export type UpdateRulesAsAdminMutationHookResult = ReturnType<typeof useUpdateRulesAsAdminMutation>;
export type UpdateRulesAsAdminMutationResult = Apollo.MutationResult<UpdateRulesAsAdminMutation>;
export type UpdateRulesAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateRulesAsAdminMutation, UpdateRulesAsAdminMutationVariables>;
export const UpdateCompanyInformationAsAdminDocument = gql`
    mutation UpdateCompanyInformationAsAdmin($companyInformationInput: CompanyInformationInput!) {
  updateCompanyInformationAsAdmin(
    companyInformationInput: $companyInformationInput
  ) {
    id
    name
    address
    ceo
    registrationNumber
  }
}
    `;
export type UpdateCompanyInformationAsAdminMutationFn = Apollo.MutationFunction<UpdateCompanyInformationAsAdminMutation, UpdateCompanyInformationAsAdminMutationVariables>;

/**
 * __useUpdateCompanyInformationAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyInformationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyInformationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyInformationAsAdminMutation, { data, loading, error }] = useUpdateCompanyInformationAsAdminMutation({
 *   variables: {
 *      companyInformationInput: // value for 'companyInformationInput'
 *   },
 * });
 */
export function useUpdateCompanyInformationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyInformationAsAdminMutation, UpdateCompanyInformationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyInformationAsAdminMutation, UpdateCompanyInformationAsAdminMutationVariables>(UpdateCompanyInformationAsAdminDocument, options);
      }
export type UpdateCompanyInformationAsAdminMutationHookResult = ReturnType<typeof useUpdateCompanyInformationAsAdminMutation>;
export type UpdateCompanyInformationAsAdminMutationResult = Apollo.MutationResult<UpdateCompanyInformationAsAdminMutation>;
export type UpdateCompanyInformationAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyInformationAsAdminMutation, UpdateCompanyInformationAsAdminMutationVariables>;
export const GetAreasDocument = gql`
    query GetAreas {
  getAreas {
    id
    name
    sort
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAreasQuery__
 *
 * To run a query within a React component, call `useGetAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAreasQuery(baseOptions?: Apollo.QueryHookOptions<GetAreasQuery, GetAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAreasQuery, GetAreasQueryVariables>(GetAreasDocument, options);
      }
export function useGetAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAreasQuery, GetAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAreasQuery, GetAreasQueryVariables>(GetAreasDocument, options);
        }
export type GetAreasQueryHookResult = ReturnType<typeof useGetAreasQuery>;
export type GetAreasLazyQueryHookResult = ReturnType<typeof useGetAreasLazyQuery>;
export type GetAreasQueryResult = Apollo.QueryResult<GetAreasQuery, GetAreasQueryVariables>;
export const UpdateAreaAsAdminDocument = gql`
    mutation UpdateAreaAsAdmin($areaInput: AreaInput!) {
  updateAreaAsAdmin(areaInput: $areaInput) {
    id
    name
    sort
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAreaAsAdminMutationFn = Apollo.MutationFunction<UpdateAreaAsAdminMutation, UpdateAreaAsAdminMutationVariables>;

/**
 * __useUpdateAreaAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAreaAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaAsAdminMutation, { data, loading, error }] = useUpdateAreaAsAdminMutation({
 *   variables: {
 *      areaInput: // value for 'areaInput'
 *   },
 * });
 */
export function useUpdateAreaAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaAsAdminMutation, UpdateAreaAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaAsAdminMutation, UpdateAreaAsAdminMutationVariables>(UpdateAreaAsAdminDocument, options);
      }
export type UpdateAreaAsAdminMutationHookResult = ReturnType<typeof useUpdateAreaAsAdminMutation>;
export type UpdateAreaAsAdminMutationResult = Apollo.MutationResult<UpdateAreaAsAdminMutation>;
export type UpdateAreaAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAreaAsAdminMutation, UpdateAreaAsAdminMutationVariables>;
export const GetAllBannersDocument = gql`
    query GetAllBanners($first: Int, $after: String) {
  getAllBanners(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      mediaId
      desc
      order
      link
      type
      media {
        id
        path
      }
    }
  }
}
    `;

/**
 * __useGetAllBannersQuery__
 *
 * To run a query within a React component, call `useGetAllBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBannersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllBannersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBannersQuery, GetAllBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBannersQuery, GetAllBannersQueryVariables>(GetAllBannersDocument, options);
      }
export function useGetAllBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBannersQuery, GetAllBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBannersQuery, GetAllBannersQueryVariables>(GetAllBannersDocument, options);
        }
export type GetAllBannersQueryHookResult = ReturnType<typeof useGetAllBannersQuery>;
export type GetAllBannersLazyQueryHookResult = ReturnType<typeof useGetAllBannersLazyQuery>;
export type GetAllBannersQueryResult = Apollo.QueryResult<GetAllBannersQuery, GetAllBannersQueryVariables>;
export const GetBannerDocument = gql`
    query GetBanner($id: Int) {
  getBanner(id: $id) {
    id
    mediaId
    desc
    order
    link
    type
    media {
      id
      path
    }
  }
}
    `;

/**
 * __useGetBannerQuery__
 *
 * To run a query within a React component, call `useGetBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBannerQuery(baseOptions?: Apollo.QueryHookOptions<GetBannerQuery, GetBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBannerQuery, GetBannerQueryVariables>(GetBannerDocument, options);
      }
export function useGetBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannerQuery, GetBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBannerQuery, GetBannerQueryVariables>(GetBannerDocument, options);
        }
export type GetBannerQueryHookResult = ReturnType<typeof useGetBannerQuery>;
export type GetBannerLazyQueryHookResult = ReturnType<typeof useGetBannerLazyQuery>;
export type GetBannerQueryResult = Apollo.QueryResult<GetBannerQuery, GetBannerQueryVariables>;
export const UpdateBannerDocument = gql`
    mutation UpdateBanner($desc: String, $avatarInput: [MediaInput], $type: String, $links: [String]) {
  updateBanner(desc: $desc, avatarInput: $avatarInput, type: $type, links: $links)
}
    `;
export type UpdateBannerMutationFn = Apollo.MutationFunction<UpdateBannerMutation, UpdateBannerMutationVariables>;

/**
 * __useUpdateBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerMutation, { data, loading, error }] = useUpdateBannerMutation({
 *   variables: {
 *      desc: // value for 'desc'
 *      avatarInput: // value for 'avatarInput'
 *      type: // value for 'type'
 *      links: // value for 'links'
 *   },
 * });
 */
export function useUpdateBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannerMutation, UpdateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(UpdateBannerDocument, options);
      }
export type UpdateBannerMutationHookResult = ReturnType<typeof useUpdateBannerMutation>;
export type UpdateBannerMutationResult = Apollo.MutationResult<UpdateBannerMutation>;
export type UpdateBannerMutationOptions = Apollo.BaseMutationOptions<UpdateBannerMutation, UpdateBannerMutationVariables>;
export const UpdateBannerAsAdminDocument = gql`
    mutation UpdateBannerAsAdmin($id: Int, $desc: String, $avatarInput: MediaInput, $order: Int, $link: String) {
  updateBannerAsAdmin(
    id: $id
    desc: $desc
    avatarInput: $avatarInput
    order: $order
    link: $link
  )
}
    `;
export type UpdateBannerAsAdminMutationFn = Apollo.MutationFunction<UpdateBannerAsAdminMutation, UpdateBannerAsAdminMutationVariables>;

/**
 * __useUpdateBannerAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateBannerAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerAsAdminMutation, { data, loading, error }] = useUpdateBannerAsAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      desc: // value for 'desc'
 *      avatarInput: // value for 'avatarInput'
 *      order: // value for 'order'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateBannerAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannerAsAdminMutation, UpdateBannerAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannerAsAdminMutation, UpdateBannerAsAdminMutationVariables>(UpdateBannerAsAdminDocument, options);
      }
export type UpdateBannerAsAdminMutationHookResult = ReturnType<typeof useUpdateBannerAsAdminMutation>;
export type UpdateBannerAsAdminMutationResult = Apollo.MutationResult<UpdateBannerAsAdminMutation>;
export type UpdateBannerAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateBannerAsAdminMutation, UpdateBannerAsAdminMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($id: [Int], $order: [Int]) {
  updateOrder(id: $id, order: $order)
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const DeleteBannerDocument = gql`
    mutation DeleteBanner($id: Int) {
  deleteBanner(id: $id)
}
    `;
export type DeleteBannerMutationFn = Apollo.MutationFunction<DeleteBannerMutation, DeleteBannerMutationVariables>;

/**
 * __useDeleteBannerMutation__
 *
 * To run a mutation, you first call `useDeleteBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBannerMutation, { data, loading, error }] = useDeleteBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBannerMutation, DeleteBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBannerMutation, DeleteBannerMutationVariables>(DeleteBannerDocument, options);
      }
export type DeleteBannerMutationHookResult = ReturnType<typeof useDeleteBannerMutation>;
export type DeleteBannerMutationResult = Apollo.MutationResult<DeleteBannerMutation>;
export type DeleteBannerMutationOptions = Apollo.BaseMutationOptions<DeleteBannerMutation, DeleteBannerMutationVariables>;
export const DeleteBannersDocument = gql`
    mutation DeleteBanners($type: String) {
  deleteBanners(type: $type)
}
    `;
export type DeleteBannersMutationFn = Apollo.MutationFunction<DeleteBannersMutation, DeleteBannersMutationVariables>;

/**
 * __useDeleteBannersMutation__
 *
 * To run a mutation, you first call `useDeleteBannersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBannersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBannersMutation, { data, loading, error }] = useDeleteBannersMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDeleteBannersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBannersMutation, DeleteBannersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBannersMutation, DeleteBannersMutationVariables>(DeleteBannersDocument, options);
      }
export type DeleteBannersMutationHookResult = ReturnType<typeof useDeleteBannersMutation>;
export type DeleteBannersMutationResult = Apollo.MutationResult<DeleteBannersMutation>;
export type DeleteBannersMutationOptions = Apollo.BaseMutationOptions<DeleteBannersMutation, DeleteBannersMutationVariables>;
export const AddBannerImageDocument = gql`
    mutation AddBannerImage($type: String, $avatarInput: MediaInput) {
  addBannerImage(type: $type, avatarInput: $avatarInput)
}
    `;
export type AddBannerImageMutationFn = Apollo.MutationFunction<AddBannerImageMutation, AddBannerImageMutationVariables>;

/**
 * __useAddBannerImageMutation__
 *
 * To run a mutation, you first call `useAddBannerImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBannerImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBannerImageMutation, { data, loading, error }] = useAddBannerImageMutation({
 *   variables: {
 *      type: // value for 'type'
 *      avatarInput: // value for 'avatarInput'
 *   },
 * });
 */
export function useAddBannerImageMutation(baseOptions?: Apollo.MutationHookOptions<AddBannerImageMutation, AddBannerImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBannerImageMutation, AddBannerImageMutationVariables>(AddBannerImageDocument, options);
      }
export type AddBannerImageMutationHookResult = ReturnType<typeof useAddBannerImageMutation>;
export type AddBannerImageMutationResult = Apollo.MutationResult<AddBannerImageMutation>;
export type AddBannerImageMutationOptions = Apollo.BaseMutationOptions<AddBannerImageMutation, AddBannerImageMutationVariables>;
export const GetAllBoardsDocument = gql`
    query GetAllBoards($maxPage: Int, $page: Int) {
  getAllBoards(maxPage: $maxPage, page: $page) {
    id
    type
    name
    total
    roleId
    createdAt
    updatedAt
    roleId
    category
    push
  }
}
    `;

/**
 * __useGetAllBoardsQuery__
 *
 * To run a query within a React component, call `useGetAllBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBoardsQuery({
 *   variables: {
 *      maxPage: // value for 'maxPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAllBoardsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBoardsQuery, GetAllBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBoardsQuery, GetAllBoardsQueryVariables>(GetAllBoardsDocument, options);
      }
export function useGetAllBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBoardsQuery, GetAllBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBoardsQuery, GetAllBoardsQueryVariables>(GetAllBoardsDocument, options);
        }
export type GetAllBoardsQueryHookResult = ReturnType<typeof useGetAllBoardsQuery>;
export type GetAllBoardsLazyQueryHookResult = ReturnType<typeof useGetAllBoardsLazyQuery>;
export type GetAllBoardsQueryResult = Apollo.QueryResult<GetAllBoardsQuery, GetAllBoardsQueryVariables>;
export const GetBoardDocument = gql`
    query GetBoard($id: Int) {
  getBoard(id: $id) {
    id
    type
    name
    total
    roleId
    createdAt
    updatedAt
    roleId
    category
    push
  }
}
    `;

/**
 * __useGetBoardQuery__
 *
 * To run a query within a React component, call `useGetBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBoardQuery(baseOptions?: Apollo.QueryHookOptions<GetBoardQuery, GetBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoardQuery, GetBoardQueryVariables>(GetBoardDocument, options);
      }
export function useGetBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoardQuery, GetBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoardQuery, GetBoardQueryVariables>(GetBoardDocument, options);
        }
export type GetBoardQueryHookResult = ReturnType<typeof useGetBoardQuery>;
export type GetBoardLazyQueryHookResult = ReturnType<typeof useGetBoardLazyQuery>;
export type GetBoardQueryResult = Apollo.QueryResult<GetBoardQuery, GetBoardQueryVariables>;
export const CreateBoardDocument = gql`
    mutation CreateBoard($name: String, $type: Int, $roleId: Int, $category: [String], $push: Int) {
  createBoard(
    name: $name
    type: $type
    roleId: $roleId
    category: $category
    push: $push
  )
}
    `;
export type CreateBoardMutationFn = Apollo.MutationFunction<CreateBoardMutation, CreateBoardMutationVariables>;

/**
 * __useCreateBoardMutation__
 *
 * To run a mutation, you first call `useCreateBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBoardMutation, { data, loading, error }] = useCreateBoardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      roleId: // value for 'roleId'
 *      category: // value for 'category'
 *      push: // value for 'push'
 *   },
 * });
 */
export function useCreateBoardMutation(baseOptions?: Apollo.MutationHookOptions<CreateBoardMutation, CreateBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBoardMutation, CreateBoardMutationVariables>(CreateBoardDocument, options);
      }
export type CreateBoardMutationHookResult = ReturnType<typeof useCreateBoardMutation>;
export type CreateBoardMutationResult = Apollo.MutationResult<CreateBoardMutation>;
export type CreateBoardMutationOptions = Apollo.BaseMutationOptions<CreateBoardMutation, CreateBoardMutationVariables>;
export const UpdateBoardDocument = gql`
    mutation UpdateBoard($id: Int, $name: String, $type: Int, $roleId: Int, $category: [String], $push: Int) {
  updateBoard(
    id: $id
    name: $name
    type: $type
    roleId: $roleId
    category: $category
    push: $push
  )
}
    `;
export type UpdateBoardMutationFn = Apollo.MutationFunction<UpdateBoardMutation, UpdateBoardMutationVariables>;

/**
 * __useUpdateBoardMutation__
 *
 * To run a mutation, you first call `useUpdateBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoardMutation, { data, loading, error }] = useUpdateBoardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      roleId: // value for 'roleId'
 *      category: // value for 'category'
 *      push: // value for 'push'
 *   },
 * });
 */
export function useUpdateBoardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBoardMutation, UpdateBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBoardMutation, UpdateBoardMutationVariables>(UpdateBoardDocument, options);
      }
export type UpdateBoardMutationHookResult = ReturnType<typeof useUpdateBoardMutation>;
export type UpdateBoardMutationResult = Apollo.MutationResult<UpdateBoardMutation>;
export type UpdateBoardMutationOptions = Apollo.BaseMutationOptions<UpdateBoardMutation, UpdateBoardMutationVariables>;
export const DeleteBoardDocument = gql`
    mutation DeleteBoard($id: Int) {
  deleteBoard(id: $id)
}
    `;
export type DeleteBoardMutationFn = Apollo.MutationFunction<DeleteBoardMutation, DeleteBoardMutationVariables>;

/**
 * __useDeleteBoardMutation__
 *
 * To run a mutation, you first call `useDeleteBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBoardMutation, { data, loading, error }] = useDeleteBoardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBoardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBoardMutation, DeleteBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBoardMutation, DeleteBoardMutationVariables>(DeleteBoardDocument, options);
      }
export type DeleteBoardMutationHookResult = ReturnType<typeof useDeleteBoardMutation>;
export type DeleteBoardMutationResult = Apollo.MutationResult<DeleteBoardMutation>;
export type DeleteBoardMutationOptions = Apollo.BaseMutationOptions<DeleteBoardMutation, DeleteBoardMutationVariables>;
export const GetAllClassesAsAdminDocument = gql`
    query GetAllClassesAsAdmin($first: Int, $after: String) {
  getAllClassesAsAdmin(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      tutor {
        id
        introduction
        experience
        verified
        active
        mentorActive
        areas {
          id
          name
        }
        user {
          id
          name
          username
          email
          avatar {
            id
            uri
          }
          deletedAt
        }
        createdAt
        updatedAt
      }
      title
      description
      duration
      coverPhoto {
        id
        uri
      }
      fields {
        id
        name
      }
      active
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAllClassesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllClassesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClassesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClassesAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllClassesAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllClassesAsAdminQuery, GetAllClassesAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllClassesAsAdminQuery, GetAllClassesAsAdminQueryVariables>(GetAllClassesAsAdminDocument, options);
      }
export function useGetAllClassesAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllClassesAsAdminQuery, GetAllClassesAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllClassesAsAdminQuery, GetAllClassesAsAdminQueryVariables>(GetAllClassesAsAdminDocument, options);
        }
export type GetAllClassesAsAdminQueryHookResult = ReturnType<typeof useGetAllClassesAsAdminQuery>;
export type GetAllClassesAsAdminLazyQueryHookResult = ReturnType<typeof useGetAllClassesAsAdminLazyQuery>;
export type GetAllClassesAsAdminQueryResult = Apollo.QueryResult<GetAllClassesAsAdminQuery, GetAllClassesAsAdminQueryVariables>;
export const ToggleClassActiveDocument = gql`
    mutation ToggleClassActive($classId: ID!, $active: Boolean!) {
  toggleClassActive(classId: $classId, active: $active) {
    id
    active
  }
}
    `;
export type ToggleClassActiveMutationFn = Apollo.MutationFunction<ToggleClassActiveMutation, ToggleClassActiveMutationVariables>;

/**
 * __useToggleClassActiveMutation__
 *
 * To run a mutation, you first call `useToggleClassActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleClassActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleClassActiveMutation, { data, loading, error }] = useToggleClassActiveMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useToggleClassActiveMutation(baseOptions?: Apollo.MutationHookOptions<ToggleClassActiveMutation, ToggleClassActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleClassActiveMutation, ToggleClassActiveMutationVariables>(ToggleClassActiveDocument, options);
      }
export type ToggleClassActiveMutationHookResult = ReturnType<typeof useToggleClassActiveMutation>;
export type ToggleClassActiveMutationResult = Apollo.MutationResult<ToggleClassActiveMutation>;
export type ToggleClassActiveMutationOptions = Apollo.BaseMutationOptions<ToggleClassActiveMutation, ToggleClassActiveMutationVariables>;
export const GetCommentsDocument = gql`
    query GetComments($maxPage: Int, $page: Int, $postId: Int, $boardId: Int) {
  getComments(maxPage: $maxPage, page: $page, postId: $postId, boardId: $boardId) {
    total
    result {
      content
      createdAt
      id
      postId
      userId
      user {
        id
        name
        username
        email
        dateOfBirth
        gender
        phone
        introduction
        createdAt
        updatedAt
        deletedAt
        resignReason
      }
    }
  }
}
    `;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      maxPage: // value for 'maxPage'
 *      page: // value for 'page'
 *      postId: // value for 'postId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions?: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($postId: Int, $boardId: Int, $userId: Int, $content: String) {
  createComment(
    postId: $postId
    boardId: $boardId
    userId: $userId
    content: $content
  )
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      boardId: // value for 'boardId'
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($id: Int, $content: String) {
  updateComment(id: $id, content: $content)
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: Int) {
  deleteComment(id: $id)
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const GetFieldsDocument = gql`
    query GetFields {
  getFields {
    id
    name
    active
    sort
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetFieldsQuery__
 *
 * To run a query within a React component, call `useGetFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldsQuery, GetFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldsQuery, GetFieldsQueryVariables>(GetFieldsDocument, options);
      }
export function useGetFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldsQuery, GetFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldsQuery, GetFieldsQueryVariables>(GetFieldsDocument, options);
        }
export type GetFieldsQueryHookResult = ReturnType<typeof useGetFieldsQuery>;
export type GetFieldsLazyQueryHookResult = ReturnType<typeof useGetFieldsLazyQuery>;
export type GetFieldsQueryResult = Apollo.QueryResult<GetFieldsQuery, GetFieldsQueryVariables>;
export const UpdateFieldAsAdminDocument = gql`
    mutation UpdateFieldAsAdmin($fieldInput: FieldInput!) {
  updateFieldAsAdmin(fieldInput: $fieldInput) {
    id
    name
    active
    sort
    createdAt
    updatedAt
  }
}
    `;
export type UpdateFieldAsAdminMutationFn = Apollo.MutationFunction<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>;

/**
 * __useUpdateFieldAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateFieldAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldAsAdminMutation, { data, loading, error }] = useUpdateFieldAsAdminMutation({
 *   variables: {
 *      fieldInput: // value for 'fieldInput'
 *   },
 * });
 */
export function useUpdateFieldAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>(UpdateFieldAsAdminDocument, options);
      }
export type UpdateFieldAsAdminMutationHookResult = ReturnType<typeof useUpdateFieldAsAdminMutation>;
export type UpdateFieldAsAdminMutationResult = Apollo.MutationResult<UpdateFieldAsAdminMutation>;
export type UpdateFieldAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateFieldAsAdminMutation, UpdateFieldAsAdminMutationVariables>;
export const GetAllPostsDocument = gql`
    query GetAllPosts($maxPage: Int, $page: Int, $boardId: Int) {
  getAllPosts(maxPage: $maxPage, page: $page, boardId: $boardId) {
    total
    result {
      boardId
      category
      content
      createdAt
      id
      title
      updatedAt
      username
      userId
    }
  }
}
    `;

/**
 * __useGetAllPostsQuery__
 *
 * To run a query within a React component, call `useGetAllPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPostsQuery({
 *   variables: {
 *      maxPage: // value for 'maxPage'
 *      page: // value for 'page'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useGetAllPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(GetAllPostsDocument, options);
      }
export function useGetAllPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(GetAllPostsDocument, options);
        }
export type GetAllPostsQueryHookResult = ReturnType<typeof useGetAllPostsQuery>;
export type GetAllPostsLazyQueryHookResult = ReturnType<typeof useGetAllPostsLazyQuery>;
export type GetAllPostsQueryResult = Apollo.QueryResult<GetAllPostsQuery, GetAllPostsQueryVariables>;
export const GetAllPostsTempDocument = gql`
    query GetAllPostsTemp($boardId: Int, $first: Int, $after: String, $category: String, $searchCategory: String, $searchWord: String) {
  getAllPostsTemp(
    boardId: $boardId
    first: $first
    after: $after
    category: $category
    searchCategory: $searchCategory
    searchWord: $searchWord
  ) {
    total
    postConnection {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        boardId
        userId
        title
        createdAt
        updatedAt
        content
        category
        username
      }
    }
  }
}
    `;

/**
 * __useGetAllPostsTempQuery__
 *
 * To run a query within a React component, call `useGetAllPostsTempQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPostsTempQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPostsTempQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      category: // value for 'category'
 *      searchCategory: // value for 'searchCategory'
 *      searchWord: // value for 'searchWord'
 *   },
 * });
 */
export function useGetAllPostsTempQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPostsTempQuery, GetAllPostsTempQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPostsTempQuery, GetAllPostsTempQueryVariables>(GetAllPostsTempDocument, options);
      }
export function useGetAllPostsTempLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPostsTempQuery, GetAllPostsTempQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPostsTempQuery, GetAllPostsTempQueryVariables>(GetAllPostsTempDocument, options);
        }
export type GetAllPostsTempQueryHookResult = ReturnType<typeof useGetAllPostsTempQuery>;
export type GetAllPostsTempLazyQueryHookResult = ReturnType<typeof useGetAllPostsTempLazyQuery>;
export type GetAllPostsTempQueryResult = Apollo.QueryResult<GetAllPostsTempQuery, GetAllPostsTempQueryVariables>;
export const GetPostDocument = gql`
    query GetPost($id: Int, $boardId: Int) {
  getPost(id: $id, boardId: $boardId) {
    id
    boardId
    userId
    title
    content
    category
    createdAt
    updatedAt
    user {
      id
      name
      username
      email
      dateOfBirth
      gender
      phone
      introduction
      createdAt
      updatedAt
      deletedAt
      resignReason
    }
  }
}
    `;

/**
 * __useGetPostQuery__
 *
 * To run a query within a React component, call `useGetPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useGetPostQuery(baseOptions?: Apollo.QueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
      }
export function useGetPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
        }
export type GetPostQueryHookResult = ReturnType<typeof useGetPostQuery>;
export type GetPostLazyQueryHookResult = ReturnType<typeof useGetPostLazyQuery>;
export type GetPostQueryResult = Apollo.QueryResult<GetPostQuery, GetPostQueryVariables>;
export const CreatePostDocument = gql`
    mutation CreatePost($boardId: Int, $userId: String, $title: String, $content: String, $category: String, $fileList: [FileList]) {
  createPost(
    boardId: $boardId
    userId: $userId
    title: $title
    content: $content
    category: $category
    fileList: $fileList
  )
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      userId: // value for 'userId'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      category: // value for 'category'
 *      fileList: // value for 'fileList'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($id: Int, $title: String, $content: String, $category: String) {
  updatePost(id: $id, title: $title, content: $content, category: $category)
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($boardId: Int, $id: Int) {
  deletePost(boardId: $boardId, id: $id) {
    boardId
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($avatarInput: MediaInput) {
  uploadImage(avatarInput: $avatarInput) {
    success
    path
  }
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      avatarInput: // value for 'avatarInput'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($fileInput: MediaInput, $fileName: String) {
  uploadFile(fileInput: $fileInput, fileName: $fileName) {
    success
    mediaId
    fileId
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      fileInput: // value for 'fileInput'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const GetReligionQuizDocument = gql`
    query GetReligionQuiz {
  getReligionQuiz {
    id
    quiz
    option1
    option2
    option3
    option4
    answer
    updatedAt
  }
}
    `;

/**
 * __useGetReligionQuizQuery__
 *
 * To run a query within a React component, call `useGetReligionQuizQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReligionQuizQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReligionQuizQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReligionQuizQuery(baseOptions?: Apollo.QueryHookOptions<GetReligionQuizQuery, GetReligionQuizQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReligionQuizQuery, GetReligionQuizQueryVariables>(GetReligionQuizDocument, options);
      }
export function useGetReligionQuizLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReligionQuizQuery, GetReligionQuizQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReligionQuizQuery, GetReligionQuizQueryVariables>(GetReligionQuizDocument, options);
        }
export type GetReligionQuizQueryHookResult = ReturnType<typeof useGetReligionQuizQuery>;
export type GetReligionQuizLazyQueryHookResult = ReturnType<typeof useGetReligionQuizLazyQuery>;
export type GetReligionQuizQueryResult = Apollo.QueryResult<GetReligionQuizQuery, GetReligionQuizQueryVariables>;
export const UpdateReligionQuizAsAdminDocument = gql`
    mutation UpdateReligionQuizAsAdmin($religionQuizInput: ReligionQuizInput!) {
  updateReligionQuizAsAdmin(religionQuizInput: $religionQuizInput) {
    id
    quiz
    option1
    option2
    option3
    option4
    answer
    updatedAt
  }
}
    `;
export type UpdateReligionQuizAsAdminMutationFn = Apollo.MutationFunction<UpdateReligionQuizAsAdminMutation, UpdateReligionQuizAsAdminMutationVariables>;

/**
 * __useUpdateReligionQuizAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateReligionQuizAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReligionQuizAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReligionQuizAsAdminMutation, { data, loading, error }] = useUpdateReligionQuizAsAdminMutation({
 *   variables: {
 *      religionQuizInput: // value for 'religionQuizInput'
 *   },
 * });
 */
export function useUpdateReligionQuizAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReligionQuizAsAdminMutation, UpdateReligionQuizAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReligionQuizAsAdminMutation, UpdateReligionQuizAsAdminMutationVariables>(UpdateReligionQuizAsAdminDocument, options);
      }
export type UpdateReligionQuizAsAdminMutationHookResult = ReturnType<typeof useUpdateReligionQuizAsAdminMutation>;
export type UpdateReligionQuizAsAdminMutationResult = Apollo.MutationResult<UpdateReligionQuizAsAdminMutation>;
export type UpdateReligionQuizAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateReligionQuizAsAdminMutation, UpdateReligionQuizAsAdminMutationVariables>;
export const GetAllReviewsDocument = gql`
    query GetAllReviews($maxPage: Int, $page: Int) {
  getAllReviews(maxPage: $maxPage, page: $page) {
    id
    userId
    classId
    content
    score
    createdAt
    user {
      id
      name
      username
      email
      dateOfBirth
      gender
      phone
      introduction
      createdAt
      updatedAt
      deletedAt
      resignReason
    }
  }
}
    `;

/**
 * __useGetAllReviewsQuery__
 *
 * To run a query within a React component, call `useGetAllReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReviewsQuery({
 *   variables: {
 *      maxPage: // value for 'maxPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAllReviewsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllReviewsQuery, GetAllReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllReviewsQuery, GetAllReviewsQueryVariables>(GetAllReviewsDocument, options);
      }
export function useGetAllReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllReviewsQuery, GetAllReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllReviewsQuery, GetAllReviewsQueryVariables>(GetAllReviewsDocument, options);
        }
export type GetAllReviewsQueryHookResult = ReturnType<typeof useGetAllReviewsQuery>;
export type GetAllReviewsLazyQueryHookResult = ReturnType<typeof useGetAllReviewsLazyQuery>;
export type GetAllReviewsQueryResult = Apollo.QueryResult<GetAllReviewsQuery, GetAllReviewsQueryVariables>;
export const GetAllReviewsTempDocument = gql`
    query GetAllReviewsTemp($first: Int, $after: String) {
  getAllReviewsTemp(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      userId
      classId
      content
      score
      createdAt
      user {
        id
        name
        username
        email
        dateOfBirth
        gender
        phone
        introduction
        createdAt
        updatedAt
        deletedAt
        resignReason
      }
      class {
        id
        title
        description
        duration
        active
        createdAt
      }
    }
  }
}
    `;

/**
 * __useGetAllReviewsTempQuery__
 *
 * To run a query within a React component, call `useGetAllReviewsTempQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReviewsTempQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReviewsTempQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllReviewsTempQuery(baseOptions?: Apollo.QueryHookOptions<GetAllReviewsTempQuery, GetAllReviewsTempQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllReviewsTempQuery, GetAllReviewsTempQueryVariables>(GetAllReviewsTempDocument, options);
      }
export function useGetAllReviewsTempLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllReviewsTempQuery, GetAllReviewsTempQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllReviewsTempQuery, GetAllReviewsTempQueryVariables>(GetAllReviewsTempDocument, options);
        }
export type GetAllReviewsTempQueryHookResult = ReturnType<typeof useGetAllReviewsTempQuery>;
export type GetAllReviewsTempLazyQueryHookResult = ReturnType<typeof useGetAllReviewsTempLazyQuery>;
export type GetAllReviewsTempQueryResult = Apollo.QueryResult<GetAllReviewsTempQuery, GetAllReviewsTempQueryVariables>;
export const GetAllUnreviewedDocument = gql`
    query GetAllUnreviewed($first: Int, $after: String) {
  getAllUnreviewed(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      userId
      classId
      createdAt
      push
      pushedAt
      user {
        id
        name
        username
        email
        dateOfBirth
        gender
        phone
        introduction
        createdAt
        updatedAt
        deletedAt
        resignReason
      }
      class {
        id
        title
        description
        duration
        active
        createdAt
      }
    }
  }
}
    `;

/**
 * __useGetAllUnreviewedQuery__
 *
 * To run a query within a React component, call `useGetAllUnreviewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUnreviewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUnreviewedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllUnreviewedQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUnreviewedQuery, GetAllUnreviewedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUnreviewedQuery, GetAllUnreviewedQueryVariables>(GetAllUnreviewedDocument, options);
      }
export function useGetAllUnreviewedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUnreviewedQuery, GetAllUnreviewedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUnreviewedQuery, GetAllUnreviewedQueryVariables>(GetAllUnreviewedDocument, options);
        }
export type GetAllUnreviewedQueryHookResult = ReturnType<typeof useGetAllUnreviewedQuery>;
export type GetAllUnreviewedLazyQueryHookResult = ReturnType<typeof useGetAllUnreviewedLazyQuery>;
export type GetAllUnreviewedQueryResult = Apollo.QueryResult<GetAllUnreviewedQuery, GetAllUnreviewedQueryVariables>;
export const GetReviewDocument = gql`
    query GetReview($id: Int) {
  getReview(id: $id) {
    id
    userId
    classId
    content
    score
    createdAt
    user {
      id
      name
      username
      email
      dateOfBirth
      gender
      phone
      introduction
      createdAt
      updatedAt
      deletedAt
      resignReason
    }
  }
}
    `;

/**
 * __useGetReviewQuery__
 *
 * To run a query within a React component, call `useGetReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReviewQuery(baseOptions?: Apollo.QueryHookOptions<GetReviewQuery, GetReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReviewQuery, GetReviewQueryVariables>(GetReviewDocument, options);
      }
export function useGetReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReviewQuery, GetReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReviewQuery, GetReviewQueryVariables>(GetReviewDocument, options);
        }
export type GetReviewQueryHookResult = ReturnType<typeof useGetReviewQuery>;
export type GetReviewLazyQueryHookResult = ReturnType<typeof useGetReviewLazyQuery>;
export type GetReviewQueryResult = Apollo.QueryResult<GetReviewQuery, GetReviewQueryVariables>;
export const CreateReviewDocument = gql`
    mutation CreateReview($classId: String, $userId: String, $content: String, $score: Int) {
  createReview(
    classId: $classId
    userId: $userId
    content: $content
    score: $score
  )
}
    `;
export type CreateReviewMutationFn = Apollo.MutationFunction<CreateReviewMutation, CreateReviewMutationVariables>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, options);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = Apollo.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>;
export const UpdateReviewDocument = gql`
    mutation UpdateReview($id: Int, $content: String, $score: Int) {
  updateReview(id: $id, content: $content, score: $score)
}
    `;
export type UpdateReviewMutationFn = Apollo.MutationFunction<UpdateReviewMutation, UpdateReviewMutationVariables>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useUpdateReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReviewMutation, UpdateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReviewMutation, UpdateReviewMutationVariables>(UpdateReviewDocument, options);
      }
export type UpdateReviewMutationHookResult = ReturnType<typeof useUpdateReviewMutation>;
export type UpdateReviewMutationResult = Apollo.MutationResult<UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<UpdateReviewMutation, UpdateReviewMutationVariables>;
export const DeleteReviewDocument = gql`
    mutation DeleteReview($id: Int) {
  deleteReview(id: $id)
}
    `;
export type DeleteReviewMutationFn = Apollo.MutationFunction<DeleteReviewMutation, DeleteReviewMutationVariables>;

/**
 * __useDeleteReviewMutation__
 *
 * To run a mutation, you first call `useDeleteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReviewMutation, { data, loading, error }] = useDeleteReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReviewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReviewMutation, DeleteReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReviewMutation, DeleteReviewMutationVariables>(DeleteReviewDocument, options);
      }
export type DeleteReviewMutationHookResult = ReturnType<typeof useDeleteReviewMutation>;
export type DeleteReviewMutationResult = Apollo.MutationResult<DeleteReviewMutation>;
export type DeleteReviewMutationOptions = Apollo.BaseMutationOptions<DeleteReviewMutation, DeleteReviewMutationVariables>;
export const PushMessageDocument = gql`
    mutation PushMessage($classUserId: String, $userId: String, $title: String, $text: String) {
  pushMessage(
    classUserId: $classUserId
    userId: $userId
    title: $title
    text: $text
  )
}
    `;
export type PushMessageMutationFn = Apollo.MutationFunction<PushMessageMutation, PushMessageMutationVariables>;

/**
 * __usePushMessageMutation__
 *
 * To run a mutation, you first call `usePushMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushMessageMutation, { data, loading, error }] = usePushMessageMutation({
 *   variables: {
 *      classUserId: // value for 'classUserId'
 *      userId: // value for 'userId'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *   },
 * });
 */
export function usePushMessageMutation(baseOptions?: Apollo.MutationHookOptions<PushMessageMutation, PushMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PushMessageMutation, PushMessageMutationVariables>(PushMessageDocument, options);
      }
export type PushMessageMutationHookResult = ReturnType<typeof usePushMessageMutation>;
export type PushMessageMutationResult = Apollo.MutationResult<PushMessageMutation>;
export type PushMessageMutationOptions = Apollo.BaseMutationOptions<PushMessageMutation, PushMessageMutationVariables>;
export const GetAllUsersAsAdminDocument = gql`
    query GetAllUsersAsAdmin($first: Int, $after: String) {
  getAllUsersAsAdmin(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      name
      username
      email
      dateOfBirth
      gender
      phone
      introduction
      fields {
        id
        name
        sort
      }
      avatar {
        id
        uri
      }
      mentor {
        id
        verified
      }
      tutor {
        id
        verified
      }
      resignReason
      createdAt
      updatedAt
      deletedAt
    }
  }
}
    `;

/**
 * __useGetAllUsersAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllUsersAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllUsersAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>(GetAllUsersAsAdminDocument, options);
      }
export function useGetAllUsersAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>(GetAllUsersAsAdminDocument, options);
        }
export type GetAllUsersAsAdminQueryHookResult = ReturnType<typeof useGetAllUsersAsAdminQuery>;
export type GetAllUsersAsAdminLazyQueryHookResult = ReturnType<typeof useGetAllUsersAsAdminLazyQuery>;
export type GetAllUsersAsAdminQueryResult = Apollo.QueryResult<GetAllUsersAsAdminQuery, GetAllUsersAsAdminQueryVariables>;
export const GetAllMentorsDocument = gql`
    query GetAllMentors($requestedOnly: Boolean, $first: Int, $after: String) {
  getAllMentors(requestedOnly: $requestedOnly, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      introduction
      experience
      verified
      active
      fields {
        id
        name
        sort
      }
      user {
        id
        name
        username
        email
        dateOfBirth
        gender
        phone
        introduction
        avatar {
          id
          uri
        }
        createdAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAllMentorsQuery__
 *
 * To run a query within a React component, call `useGetAllMentorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMentorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMentorsQuery({
 *   variables: {
 *      requestedOnly: // value for 'requestedOnly'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllMentorsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMentorsQuery, GetAllMentorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMentorsQuery, GetAllMentorsQueryVariables>(GetAllMentorsDocument, options);
      }
export function useGetAllMentorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMentorsQuery, GetAllMentorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMentorsQuery, GetAllMentorsQueryVariables>(GetAllMentorsDocument, options);
        }
export type GetAllMentorsQueryHookResult = ReturnType<typeof useGetAllMentorsQuery>;
export type GetAllMentorsLazyQueryHookResult = ReturnType<typeof useGetAllMentorsLazyQuery>;
export type GetAllMentorsQueryResult = Apollo.QueryResult<GetAllMentorsQuery, GetAllMentorsQueryVariables>;
export const GetAllTutorsDocument = gql`
    query GetAllTutors($requestedOnly: Boolean, $first: Int, $after: String) {
  getAllTutors(requestedOnly: $requestedOnly, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      introduction
      experience
      verified
      active
      fields {
        id
        name
        sort
      }
      areas {
        id
        name
        sort
      }
      user {
        id
        name
        username
        email
        dateOfBirth
        gender
        phone
        introduction
        avatar {
          id
          uri
        }
        createdAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAllTutorsQuery__
 *
 * To run a query within a React component, call `useGetAllTutorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTutorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTutorsQuery({
 *   variables: {
 *      requestedOnly: // value for 'requestedOnly'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllTutorsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTutorsQuery, GetAllTutorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTutorsQuery, GetAllTutorsQueryVariables>(GetAllTutorsDocument, options);
      }
export function useGetAllTutorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTutorsQuery, GetAllTutorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTutorsQuery, GetAllTutorsQueryVariables>(GetAllTutorsDocument, options);
        }
export type GetAllTutorsQueryHookResult = ReturnType<typeof useGetAllTutorsQuery>;
export type GetAllTutorsLazyQueryHookResult = ReturnType<typeof useGetAllTutorsLazyQuery>;
export type GetAllTutorsQueryResult = Apollo.QueryResult<GetAllTutorsQuery, GetAllTutorsQueryVariables>;
export const VerifyMentorAsAdminDocument = gql`
    mutation VerifyMentorAsAdmin($mentorId: ID!, $verified: Boolean!) {
  verifyMentorAsAdmin(mentorId: $mentorId, verified: $verified) {
    id
    verified
  }
}
    `;
export type VerifyMentorAsAdminMutationFn = Apollo.MutationFunction<VerifyMentorAsAdminMutation, VerifyMentorAsAdminMutationVariables>;

/**
 * __useVerifyMentorAsAdminMutation__
 *
 * To run a mutation, you first call `useVerifyMentorAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMentorAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMentorAsAdminMutation, { data, loading, error }] = useVerifyMentorAsAdminMutation({
 *   variables: {
 *      mentorId: // value for 'mentorId'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useVerifyMentorAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMentorAsAdminMutation, VerifyMentorAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyMentorAsAdminMutation, VerifyMentorAsAdminMutationVariables>(VerifyMentorAsAdminDocument, options);
      }
export type VerifyMentorAsAdminMutationHookResult = ReturnType<typeof useVerifyMentorAsAdminMutation>;
export type VerifyMentorAsAdminMutationResult = Apollo.MutationResult<VerifyMentorAsAdminMutation>;
export type VerifyMentorAsAdminMutationOptions = Apollo.BaseMutationOptions<VerifyMentorAsAdminMutation, VerifyMentorAsAdminMutationVariables>;
export const VerifyTutorAsAdminDocument = gql`
    mutation VerifyTutorAsAdmin($tutorId: ID!, $verified: Boolean!) {
  verifyTutorAsAdmin(tutorId: $tutorId, verified: $verified) {
    id
    verified
  }
}
    `;
export type VerifyTutorAsAdminMutationFn = Apollo.MutationFunction<VerifyTutorAsAdminMutation, VerifyTutorAsAdminMutationVariables>;

/**
 * __useVerifyTutorAsAdminMutation__
 *
 * To run a mutation, you first call `useVerifyTutorAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTutorAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTutorAsAdminMutation, { data, loading, error }] = useVerifyTutorAsAdminMutation({
 *   variables: {
 *      tutorId: // value for 'tutorId'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useVerifyTutorAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTutorAsAdminMutation, VerifyTutorAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTutorAsAdminMutation, VerifyTutorAsAdminMutationVariables>(VerifyTutorAsAdminDocument, options);
      }
export type VerifyTutorAsAdminMutationHookResult = ReturnType<typeof useVerifyTutorAsAdminMutation>;
export type VerifyTutorAsAdminMutationResult = Apollo.MutationResult<VerifyTutorAsAdminMutation>;
export type VerifyTutorAsAdminMutationOptions = Apollo.BaseMutationOptions<VerifyTutorAsAdminMutation, VerifyTutorAsAdminMutationVariables>;
export const UpdateUserProfileAsAdminDocument = gql`
    mutation UpdateUserProfileAsAdmin($userId: ID, $userInput: UserInput, $avatarInput: MediaInput) {
  updateUserProfileAsAdmin(
    userId: $userId
    userInput: $userInput
    avatarInput: $avatarInput
  ) {
    id
    name
    username
    email
    dateOfBirth
    gender
    phone
    introduction
    fields {
      id
      name
      sort
    }
    avatar {
      id
      uri
    }
    mentor {
      id
      verified
    }
    tutor {
      id
      verified
    }
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export type UpdateUserProfileAsAdminMutationFn = Apollo.MutationFunction<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>;

/**
 * __useUpdateUserProfileAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileAsAdminMutation, { data, loading, error }] = useUpdateUserProfileAsAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userInput: // value for 'userInput'
 *      avatarInput: // value for 'avatarInput'
 *   },
 * });
 */
export function useUpdateUserProfileAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>(UpdateUserProfileAsAdminDocument, options);
      }
export type UpdateUserProfileAsAdminMutationHookResult = ReturnType<typeof useUpdateUserProfileAsAdminMutation>;
export type UpdateUserProfileAsAdminMutationResult = Apollo.MutationResult<UpdateUserProfileAsAdminMutation>;
export type UpdateUserProfileAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileAsAdminMutation, UpdateUserProfileAsAdminMutationVariables>;
export const ToggleActiveMentorDocument = gql`
    mutation ToggleActiveMentor($active: Boolean!, $mentorId: ID) {
  toggleActiveMentor(active: $active, mentorId: $mentorId) {
    id
    active
  }
}
    `;
export type ToggleActiveMentorMutationFn = Apollo.MutationFunction<ToggleActiveMentorMutation, ToggleActiveMentorMutationVariables>;

/**
 * __useToggleActiveMentorMutation__
 *
 * To run a mutation, you first call `useToggleActiveMentorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveMentorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveMentorMutation, { data, loading, error }] = useToggleActiveMentorMutation({
 *   variables: {
 *      active: // value for 'active'
 *      mentorId: // value for 'mentorId'
 *   },
 * });
 */
export function useToggleActiveMentorMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActiveMentorMutation, ToggleActiveMentorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActiveMentorMutation, ToggleActiveMentorMutationVariables>(ToggleActiveMentorDocument, options);
      }
export type ToggleActiveMentorMutationHookResult = ReturnType<typeof useToggleActiveMentorMutation>;
export type ToggleActiveMentorMutationResult = Apollo.MutationResult<ToggleActiveMentorMutation>;
export type ToggleActiveMentorMutationOptions = Apollo.BaseMutationOptions<ToggleActiveMentorMutation, ToggleActiveMentorMutationVariables>;
export const ToggleActiveTutorDocument = gql`
    mutation ToggleActiveTutor($active: Boolean!, $mentorActive: Boolean!, $tutorId: ID) {
  toggleActiveTutor(
    active: $active
    mentorActive: $mentorActive
    tutorId: $tutorId
  ) {
    id
    mentorActive
    active
  }
}
    `;
export type ToggleActiveTutorMutationFn = Apollo.MutationFunction<ToggleActiveTutorMutation, ToggleActiveTutorMutationVariables>;

/**
 * __useToggleActiveTutorMutation__
 *
 * To run a mutation, you first call `useToggleActiveTutorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveTutorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveTutorMutation, { data, loading, error }] = useToggleActiveTutorMutation({
 *   variables: {
 *      active: // value for 'active'
 *      mentorActive: // value for 'mentorActive'
 *      tutorId: // value for 'tutorId'
 *   },
 * });
 */
export function useToggleActiveTutorMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActiveTutorMutation, ToggleActiveTutorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActiveTutorMutation, ToggleActiveTutorMutationVariables>(ToggleActiveTutorDocument, options);
      }
export type ToggleActiveTutorMutationHookResult = ReturnType<typeof useToggleActiveTutorMutation>;
export type ToggleActiveTutorMutationResult = Apollo.MutationResult<ToggleActiveTutorMutation>;
export type ToggleActiveTutorMutationOptions = Apollo.BaseMutationOptions<ToggleActiveTutorMutation, ToggleActiveTutorMutationVariables>;
export const DeleteUserProfileDocument = gql`
    mutation DeleteUserProfile($userId: ID, $reason: String) {
  deleteUserProfile(userId: $userId, reason: $reason)
}
    `;
export type DeleteUserProfileMutationFn = Apollo.MutationFunction<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>;

/**
 * __useDeleteUserProfileMutation__
 *
 * To run a mutation, you first call `useDeleteUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserProfileMutation, { data, loading, error }] = useDeleteUserProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDeleteUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>(DeleteUserProfileDocument, options);
      }
export type DeleteUserProfileMutationHookResult = ReturnType<typeof useDeleteUserProfileMutation>;
export type DeleteUserProfileMutationResult = Apollo.MutationResult<DeleteUserProfileMutation>;
export type DeleteUserProfileMutationOptions = Apollo.BaseMutationOptions<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>;