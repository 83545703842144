import { ModalProps } from "global";
import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import { Button } from "../../common/styles/commonStyles";

type PushFormModalProps = {
  pushInfo: {
    classUserId: string;
    userId: string;
    classId: string;
  },
  onPush?: (classUserId: string, userId: string, title: string, text: string) => void
}

function PushFormModal({
  pushInfo,
  onPush,
  isOpen,
  onRequestClose,
}: ModalProps & PushFormModalProps) {
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')

  function onSubmit() {
    onPush && onPush(pushInfo.classUserId, pushInfo.userId, title, text)
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
    <Wrapper>
      <PolicyTypeButton>
        <LeftButtons></LeftButtons>
        <Button onClick={onSubmit}>보내기</Button>
      </PolicyTypeButton>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>제목</Label>
        <Input 
          placeholder="푸쉬 제목"
          value={title}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setTitle(e.target.value.trim())
          }
        />
      </InputRow>
      <Textarea onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=>{
          setText(e.target.value.trim())
      }} />
    </Wrapper>
    </ReactModal>
  );
}

export default PushFormModal;

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const PolicyTypeButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  marginBottom: theme.spacing.normal,
}));

const LeftButtons = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: theme.spacing.small,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing.normal,
  ...theme.font.body1,
  margin: `${theme.spacing.small}px 0px`,
}));

const Label = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Select = styled.select<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Option = styled.option<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  minHeight: 150,
  resize: "none"
}));
