import { useGetFieldsQuery } from "../../../lib/apollo/graphql/generated";

function useFieldContainer() {
  const { loading, data } = useGetFieldsQuery();

  return {
    models: {
      loading,
      data: data?.getFields,
    },
  };
}

export default useFieldContainer;
