import { onAuthStateChanged, User } from "@firebase/auth";
import { ReactChildrenProp } from "global";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, firebaseSignOut } from "../../../lib/firebase";
import AuthContainer from "../../auth/container/AuthContainer";

const FirebaseContext = createContext<User | null>(null);

function FirebaseProvider({ children }: ReactChildrenProp) {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isAdmin = (await user.getIdTokenResult()).claims?.admin;

        if (!isAdmin) {
          alert("Only admin can access this page");
          await firebaseSignOut();
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }

      setInitializing(false);
    });
  }, []);

  if (initializing) return <div />;

  return (
    <FirebaseContext.Provider value={user}>
      {user ? children : <AuthContainer />}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;

export function useFirebaseContext() {
  return useContext(FirebaseContext);
}
