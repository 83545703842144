import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { PATH_NAMES } from "../utils/router";

function useTopbar() {
  const [pathname, setPathname] = useState("");

  const location = useLocation();

  function getPathName() {
    for (let { path, name } of Object.values(PATH_NAMES)) {
      if (path === location.pathname) {
        setPathname(name!);
        break;
      }
    }
  }

  useEffect(() => {
    getPathName();
  }, [location?.pathname]);

  return {
    models: {
      pathname,
    },
  };
}

export default useTopbar;
