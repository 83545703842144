import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetAllReviewsQuery,
  useUpdateReviewMutation,
  GetReviewDocument,
  useDeleteReviewMutation,
  GetAllReviewsDocument,
  useGetAllReviewsTempQuery,
} from "../../../lib/apollo/graphql/generated";

function useReviewListTalbe() {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });
  let reviewId = 0;

  const [fetchingMore, setFetchingMore] = useState(false);


  const { data, loading, fetchMore, refetch } = useGetAllReviewsTempQuery({
    variables: {
      first: 20,
    },
  });

  async function onFetchMore(inView: boolean) {
    if (inView && !fetchingMore) {
      setFetchingMore(true);

      const { hasNextPage, endCursor } = data?.getAllReviewsTemp?.pageInfo!;
      if (hasNextPage) {
        refetch({
            first: 20,
            after: endCursor
          
        }).then(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  const [updateReview] = useUpdateReviewMutation({
    refetchQueries: () => [
      {
        query: GetReviewDocument,
        variables: {
          id: reviewId
        },
      },
    ],
  });

  function onUpdateReview(changedDatas: any) {
    const { id, content, score } = changedDatas
    reviewId=id;

    let updateDatas: any = {id}

    if (content) updateDatas = {...updateDatas, content}
    if (score) updateDatas={...updateDatas, score}

    updateReview({
      variables: updateDatas,
    });
  }

  const [deleteReview] =  useDeleteReviewMutation({
    refetchQueries: () => [
      {
        query: GetAllReviewsDocument,
        variables: {
          maxPage: 20,
          page: 1, 
        },
      },
    ],
  });

  function onDeleteReview(id: number) {
    const confirm = window.confirm('삭제하시겠습니까?')
    
    if(!confirm) {
      return 
    }

    deleteReview({
      variables: {
        id,
      },
    });
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAllReviewsTemp?.edges,
      fetchingMore,
    },
    operations: {
      onFetchMore,
      onUpdateReview,
      onDeleteReview
    }
  };
}

export default useReviewListTalbe;
