import { useState } from "react";
import {
  useGetRulesQuery,
  useUpdateRulesAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useRulesContainer() {
  const [state, setState] = useState({
    mentorRule: "",
    tutorRule: "",
  });

  const { data, loading } = useGetRulesQuery({
    onCompleted: (data) => {
      if (data?.getRules) {
        setState({
          mentorRule: data?.getRules?.mentorRule || "",
          tutorRule: data?.getRules?.tutorRule || "",
        });
      }
    },
  });

  const [updateRulesAsAdmin] = useUpdateRulesAsAdminMutation({
    onCompleted: () => {
      alert("저장 완료!");
    },
  });

  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit(key: string) {
    if (key === "mentorRule") {
      updateRulesAsAdmin({
        variables: {
          rulesInput: {
            mentorRule: state?.mentorRule,
            tutorRule: data?.getRules?.tutorRule,
          },
        },
      });
    } else {
      updateRulesAsAdmin({
        variables: {
          rulesInput: {
            tutorRule: state?.tutorRule,
            mentorRule: data?.getRules?.mentorRule,
          },
        },
      });
    }
  }

  return {
    models: {
      state,
      loading,
    },
    operations: {
      onChange,
      onSubmit,
    },
  };
}

export default useRulesContainer;
