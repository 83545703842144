import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useAreaContainer from "./AreaContainer.hook";
import AreaCard from "../components/AreaCard";

function AreaContainer() {
  const {
    models: { loading, data },
  } = useAreaContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      {data?.map((area) => (
        <AreaCard key={area?.id} area={area} />
      ))}
      <AreaCard area={null} />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: theme.spacing.normal,
  width: "100%",
  padding: theme.spacing.default,
  gridTemplateColumns: "repeat(4,1fr)",
  gridAutoRows: "1fr",
}));

export default AreaContainer;
