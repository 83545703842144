import React, {useState, useEffect } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useUnreviewedListTable from "./UnreviewedListTable.hook";
import {
  Table,
  TBody,
  Td,
  Th,
  THead
  } from "../../common/styles/commonStyles";
import ReviewRow from "./ReviewRow";
import UnreviewedRow from "./UnreviewedRow";

function UnreviewedListTable() {
  let [reviews, setReviews] = useState<any>([])

  const {
    refs: { fetchMoreRef },
    models: { loading, data, fetchingMore },
    operations: { push }
  } = useUnreviewedListTable();

  useEffect(() => {
    if (data) {
      setReviews((prev: any) => [...prev, ...data]);
    }
  }, [data]);

  useEffect(()=>{
    return()=>{
      setReviews([])
    }
  },[])

  if (loading) return <div />;

  return (
      <Table>
        <THead>
          <tr>
            <Th>번호</Th>
            <Th>강의명</Th>
            <Th>수강생</Th>
            <Th>강의신청일시</Th>
            <Th>푸쉬알림전송여부</Th>
            <Th>더보기</Th>
          </tr>
        </THead>
        <TBody>
        {
            reviews?.map((review: any, index: number) => {
              return <UnreviewedRow
                key={index}
                onPush={push}
                review={review}
              />
          })
          }
          <tr style={{ visibility: "hidden" }}>
            <td ref={fetchMoreRef} colSpan={8}>
              Fetch More
            </td>
          </tr> 
        </TBody>
      </Table>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

export default UnreviewedListTable;
