import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useFieldContainer from "./FieldContainer.hook";
import FieldCard from "../components/FieldCard";

function FieldContainer() {
  const {
    models: { loading, data },
  } = useFieldContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      {data?.map((field) => (
        <FieldCard key={field?.id} field={field} />
      ))}
      <FieldCard field={null} />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "grid",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: theme.spacing.normal,
  width: "100%",
  padding: theme.spacing.default,
  gridTemplateColumns: "repeat(4,1fr)",
  gridAutoRows: "1fr",
}));

export default FieldContainer;
