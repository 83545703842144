import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useRulesContainer from "./RulesContainer.hook";
import { Button } from "../../common/styles/commonStyles";

function RulesContainer() {
  const {
    models: { state, loading },
    operations: { onChange, onSubmit },
  } = useRulesContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <div style={{ flex: 1 }}>
        <TitleRow>
          <Title>멘토 규칙</Title>
          <Button onClick={() => onSubmit("mentorRule")}>저장</Button>
        </TitleRow>
        <TextArea
          placeholder="멘토 규칙"
          value={state.mentorRule}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onChange(e, "mentorRule")
          }
        />
      </div>
      <div style={{ flex: 1 }}>
        <TitleRow>
          <Title>강사 규칙</Title>
          <Button onClick={() => onSubmit("tutorRule")}>저장</Button>
        </TitleRow>
        <TextArea
          placeholder="강사 규칙"
          value={state.tutorRule}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onChange(e, "tutorRule")
          }
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing.middle,
}));

const TitleRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.normal,
  marginBottom: theme.spacing.middle,
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body1,
  fontWeight: "bold",
}));

const TextArea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body2,
  width: "100%",
  minHeight: 500,
}));

export default RulesContainer;
