import { ReactElement } from "react";
import AreaContainer from "../../../modules/area/container/AreaContainer";
import ClassContainer from "../../../modules/class/container/ClassContainer";
import CompanyInformationContainer from "../../../modules/companyInformation/container/CompanyInformationContainer";
import FieldContainer from "../../../modules/field/container/FieldContainer";
import MainContainer from "../../../modules/main/container/MainContainer";
import PolicyContainer from "../../../modules/policy/container/PolicyContainer";
import QuizContainer from "../../../modules/quiz/container/QuizContainer";
import RulesContainer from "../../../modules/rules/container/RulesContainer";
import UserListContainer from "../../../modules/user/userList/container/UserListContainer";
import CommunityContainer from "../../../modules/post/container/CommunityContainer";
import ReviewContainer from "../../../modules/review/container/ReviewContainer";
import BannerContainer from "../../../modules/banner/container/BannerContainer";

export type CustomRoute = {
  path: string;
  element: ReactElement;
  name?: string;
  children?: CustomRoute[];
};

export const PATH_NAMES: { [key: string]: CustomRoute } = {
  HOME: {
    path: "/",
    name: "홈",
    element: <MainContainer />,
  },
  QUIZ: {
    path: "/quiz",
    name: "회원가입퀴즈",
    element: <QuizContainer />,
  },
  FIELD: {
    path: "/fields",
    name: "관심분야",
    element: <FieldContainer />,
  },
  AREA: {
    path: "/areas",
    name: "출강지역",
    element: <AreaContainer />,
  },
  USERS: {
    path: "/users",
    name: "유저목록",
    element: <UserListContainer />,
  },
  CLASSES: {
    path: "/classes",
    name: "강의목록",
    element: <ClassContainer />,
  },
  RULES: {
    path: "/rules",
    name: "멘토 및 강사 규칙",
    element: <RulesContainer />,
  },
  POLICIES: {
    path: "/policies",
    name: "정책 및 약관",
    element: <PolicyContainer />,
  },
  COMPANY_INFORMATION: {
    path: "/company_information",
    name: "회사 정보",
    element: <CompanyInformationContainer />,
  },
  COMMUNITY: {
    path: "/community",
    name: "게시판",
    element: <CommunityContainer />,
  },
  REVIEWS: {
    path: "/reviews",
    name: "후기",
    element: <ReviewContainer />,
  },
  BANNERS: {
    path: "/banners",
    name: "배너관리",
    element: <BannerContainer />,
  },
};
