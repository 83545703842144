import styled from 'styled-components'

export const MoveButtonBox = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	&:hover {
		cursor: pointer;
	}
`