import React, {useState, useEffect } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useReviewListTable from "./ReviewListTable.hook";
import {
  Table,
  TBody,
  Td,
  Th,
  THead
  } from "../../common/styles/commonStyles";
import ReviewRow from "./ReviewRow";

function ReviewListTable() {
  let [reviewId, setReviewId] = useState(0)
  let [reviews, setReviews] = useState<any>([])

  const {
    refs: { fetchMoreRef },
    models: { loading, data, fetchingMore },
  } = useReviewListTable();

  const changedReview = (index: number, score: number, content: string) => {
    const tempArr = [...reviews]
    const temp = {...reviews[0]}
    temp.content = content
    temp.score = score
    tempArr[index] = temp
    setReviews(tempArr)
  }

  const deleteReview = (index: number) => {
    const temp = [...reviews]
    const front = temp.slice(0, index)
    const back = temp.slice(index+1)

    const t = front.concat(back)

    setReviews(t)
  }

  useEffect(() => {
    if (data) {
      setReviews((prev: any) => [...prev, ...data]);
    }
  }, [data]);

  useEffect(()=>{
    return()=>{
      setReviews([])
    }
  },[])

  if (loading) return <div />;

  return (
      <Table>
        <THead>
          <tr>
            <Th>번호</Th>
            <Th>강의명</Th>
            <Th>점수</Th>
            <Th>작성자</Th>
            <Th>작성일</Th>
            <Th>더보기</Th>
          </tr>
        </THead>
        <TBody>
        {
            reviews?.map((review: any, index: number) => (
              <ReviewRow 
              key={index}
              index={index}
              review={review}
              setReviewId={setReviewId}
              changedReview={changedReview}
              deleteReview={deleteReview}
              />
            )
          )
          }
          <tr style={{ visibility: "hidden" }}>
            <td ref={fetchMoreRef} colSpan={8}>
              Fetch More
            </td>
          </tr>
        </TBody>
      </Table>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

export default ReviewListTable;
