import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useCompanyInformationContainer from "./CompanyInformationContainer.hook";
import { Button } from "../../common/styles/commonStyles";

function CompanyInformationContainer() {
  const {
    models: { loading, state },
    operations: { onChange, onSubmit },
  } = useCompanyInformationContainer();

  return (
    <Wrapper>
      <InputRow>
        <InputLabel>회사명</InputLabel>
        <Input
          placeholder="회사명"
          value={state.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e, "name")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>주소</InputLabel>
        <Input
          placeholder="주소"
          value={state.address}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e, "address")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>대표자명</InputLabel>
        <Input
          placeholder="대표자명"
          value={state.ceo}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e, "ceo")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>등록번호</InputLabel>
        <Input
          placeholder="등록번호"
          value={state.registrationNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e, "registrationNumber")
          }
        />
      </InputRow>
      <Button onClick={onSubmit}>저장</Button>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  width: "50%",
  marginBottom: theme.spacing.normal,
}));

const InputLabel = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.small,
  border: `1px solid ${theme.color.black6}`,
  flex: 2,
}));

export default CompanyInformationContainer;
