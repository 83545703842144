import React from 'react'
import { ChangeEvent, useState } from "react";
import {
  useGetBoardQuery,
  useCreatePostMutation,
  useUploadFileMutation
} from "../../../lib/apollo/graphql/generated";

function usePostFormModal(
  boardId?: number | null,
  onRequestClose?: () => void,
) {
  const { loading, data } = useGetBoardQuery({
    variables: {
      id: boardId
    }
  });

  const [uploadFile] = useUploadFileMutation()

  const [state, setState] = useState({
    category: "",
    content: "",
    title: "",
    fileList: [{}],
  });

  const [createPost] = useCreatePostMutation({
    onCompleted: () => {
      alert("저장 완료!");
      onRequestClose && onRequestClose();
    },
    // refetchQueries: () => [
    //   {
    //     query: GetAllPostsTempDocument,
    //     variables: {
    //       first: 20,
    //       boardId,
    //     },
    //   },
    // ],
  });

  function onChange(value: string) {
    setState((prev) => ({
      ...prev,
      content: value,
    }));
  }
  function onInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSelectChange(
    e: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      category: value,
    }));
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { files } = e.target;
    const temp = [...state.fileList]

    const fileInput = { file: null as any };
    fileInput.file = files?.[0]

    if (files && files[0].size > (100 * 1024 * 1024)) {
      alert("파일 사이즈가 100mb 를 넘습니다.");
      e.target.value = "";
      return;
    }

    uploadFile({
      variables: {
        fileInput,
        fileName: files?.[0]?.name
      }
    }).then((data) => {
      temp[index] = {
        mediaId: data.data?.uploadFile?.mediaId,
        fileName: files?.[0]?.name,
        fileId: data.data?.uploadFile?.fileId,
      }

      setState((prev) => ({
        ...prev,
        fileList: temp,
      }));
    })
  }

  function onDeleteFile(index: number) {
    const temp = [...state.fileList]
    const front = temp.slice(0, index)
    const back = temp.slice(index + 1)

    const tempList = front.concat(back)

    setState((prev) => ({
      ...prev,
      fileList: tempList,
    }));
  }

  function addFileList() {
    const temp = [...state.fileList]
    temp.push('')

    setState((prev) => ({
      ...prev,
      fileList: temp,
    }));
  }

  function onSubmit() {
    const { title, content, category, fileList } = state

    createPost({
      variables: {
        boardId,
        title,
        content,
        category,
        fileList
      },
    });
  }

  return {
    models: {
      state,
      loading,
      data: data?.getBoard,
    },
    operations: {
      addFileList,
      onChange,
      onSubmit,
      onSelectChange,
      onInputChange,
      onFileChange,
      onDeleteFile
    },
  };
}

export default usePostFormModal;
