import { useState } from "react";
import { POLICY_TYPES } from "../../../constants";
import {
  useGetPoliciesQuery,
  useUpdatePolicyAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function usePolicyContainer() {
  const [tab, setTab] = useState(POLICY_TYPES.TERMS_OF_POLICY);

  const [state, setState] = useState({
    terms: "",
    privacy: "",
  });

  const { loading, data } = useGetPoliciesQuery({
    onCompleted: (data) => {
      if (data?.getPolicies) {
        setState({
          terms: data?.getPolicies?.terms || "",
          privacy: data?.getPolicies?.privacy || "",
        });
      }
    },
  });

  const [updatePolicyAsAdmin] = useUpdatePolicyAsAdminMutation({
    onCompleted: () => {
      alert("저장 완료!");
    },
  });

  function onTabSelect(selectedTab: string) {
    setTab(selectedTab);
  }

  function onChange(value: string) {
    if (tab === POLICY_TYPES.TERMS_OF_POLICY) {
      setState((prev) => ({
        ...prev,
        terms: value,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        privacy: value,
      }));
    }
  }

  function onSubmit() {
    if (tab === POLICY_TYPES.TERMS_OF_POLICY) {
      updatePolicyAsAdmin({
        variables: {
          policyInput: {
            terms: state.terms,
            privacy: data?.getPolicies?.privacy,
          },
        },
      });
    } else {
      updatePolicyAsAdmin({
        variables: {
          policyInput: {
            privacy: state.privacy,
            terms: data?.getPolicies?.terms,
          },
        },
      });
    }
  }

  return {
    models: {
      loading,
      state,
      tab,
    },
    operations: {
      onTabSelect,
      onChange,
      onSubmit,
    },
  };
}

export default usePolicyContainer;
