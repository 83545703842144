import { useState } from "react";
import {  useGetAllBoardsQuery } from "../../../lib/apollo/graphql/generated";

function useBoardWrapper(
  onTabSelect: (selectedTab: number | undefined) => void,
  onSetCategory: (category: string | null | undefined) => void,
  onSetPostTotal: (total: number | undefined) => void
  ) {
  const [state, setState] = useState({
    total: 0
  })

  const { loading, data } = useGetAllBoardsQuery({
    onCompleted: (data) => {
      if (data && data?.getAllBoards) {
        const boards = data?.getAllBoards
        onTabSelect(boards[0]?.id)
        onSetPostTotal(boards[0]?.total)
        onSetCategory(boards[0]?.category)
      }
    },
    variables: {
      maxPage: 20,
      page: 1
    }
  });


  return {
    models: {
      state,
      loading,
      data: data?.getAllBoards
    }
  };
}

export default useBoardWrapper;
