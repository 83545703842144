import { useState } from "react";
import {
  useGetReligionQuizQuery,
  useUpdateReligionQuizAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useQuizContainer() {
  const [state, setState] = useState({
    quiz: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 1,
  });

  const { loading, data } = useGetReligionQuizQuery({
    onCompleted: (data) => {
      if (data?.getReligionQuiz) {
        const { quiz, option1, option2, option3, option4, answer } =
          data?.getReligionQuiz;

        setState({
          quiz,
          option1,
          option2,
          option3,
          option4,
          answer,
        });
      }
    },
  });

  const [updateReligionQuizAsAdmin, { loading: updateLoading }] =
    useUpdateReligionQuizAsAdminMutation();

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    if (key === "answer" && (Number(value) < 1 || Number(value) > 4)) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function isButtonDisabled() {
    const { quiz, option1, option2, option3, option4, answer } = state;

    const isFilled =
      quiz &&
      option1 &&
      option2 &&
      option3 &&
      option4 &&
      answer > 0 &&
      answer < 5;

    return !isFilled || updateLoading;
  }

  function onSubmit() {
    const { quiz, option1, option2, option3, option4, answer } = state;

    updateReligionQuizAsAdmin({
      variables: {
        religionQuizInput: {
          quiz,
          option1,
          option2,
          option3,
          option4,
          answer: Number(answer),
        },
      },
    });
  }

  return {
    models: {
      loading,
      state,
      data: data?.getReligionQuiz,
      updateLoading,
    },
    operations: {
      onInputChange,
      isButtonDisabled,
      onSubmit,
    },
  };
}

export default useQuizContainer;
