import { ModalProps } from "global";
import React, {useState} from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import { Button } from "../../common/styles/commonStyles";
import { BOARD_ROLES, BOARD_ROLES_MEM } from "../../../constants";
import useNewBoardModal from "./NewBoardModal.hook";

function NewBoardModal({
  isOpen,
  onRequestClose,
}: ModalProps) {
  const theme = useTheme();
  const [name, setName] = useState('')
  const [type, setType] = useState(false)
  const [roles, setRoles] = useState<string[]>([])
  const [category, setCategory] = useState<string[]>([])
  const [push, setPush] = useState(false)

  const {
    operations: {onCreateBoard}
  } = useNewBoardModal(onRequestClose);

  function onChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value.trim())
  }

  function onChangeRoles (member: string) {
    let tempRoles = [...roles]

    const find = roles.find((r) => r == member) 

    if (!find) {
      tempRoles.push(member)
    } else {
      tempRoles = tempRoles.filter((role) => role!= member)
    }

    setRoles(tempRoles)
  }

  function onChangeType(e: React.ChangeEvent<HTMLInputElement>) {
    setType(e.target.checked)

  }

  function validation() {
    if (!name || name.length > 20) {
      alert('게시판명은 20자 이하로 작성해주세요')
      return false
    }

    if (category.length < 1) {
      alert('카테고리가 없습니다')
      return false
    }

    const sortedRoles = roles.sort()

    let roleid = 0
    BOARD_ROLES.map((role,index)=>{
      if (JSON.stringify(role)==JSON.stringify(sortedRoles)) {
        roleid = index+1
      }
    })

    if (!roleid) {
      alert('이용가능자를 확인해주세요')
      return false
    }

    return roleid
  }

  function onSubmit() {
    const result = validation()

    if (!result) {
      return
    }

    onCreateBoard(name, type? 1 : 0, result, category, push)
  }

  const newCategory = () => {
    let tempCat = [...category]
    tempCat.push('')
    setCategory(tempCat)
  }

  const setNewCategory = (index: number, cat: string) => {
    let tempCat = [...category]
    tempCat[index] = cat.trim()
    setCategory(tempCat)
  }

  const deleteCategory = (index: number) => {
    const front = category.slice(0, index)
    const back = category.slice(index+1)

    const temp = front.concat(back)

    setCategory(temp)
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Title>게시판 추가</Title>
      <Button
        disabled={false}
        onClick={onSubmit}
      >
        제출하기
      </Button>
      <InputRow>
        <Label>게시판명</Label>
        <Input
          placeholder="게시판명"
          name="name"
          value={null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>{
            onChangeName(e)
          }}
        />
      </InputRow>
      <InputRow>
          <Label>카테고리</Label>
      {
        category?.map((cat, index) => {
          return <div>
          <Input
            placeholder="카테고리"
            value={cat}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              {setNewCategory(index, e.target.value)}
            }
          />
          <span onClick={()=>deleteCategory(index)}>x</span>
          </div>
        })
      }
      <button onClick={newCategory}>+</button>
      </InputRow>
      <InputRow>
        <Label>푸시알림</Label>
        <input type="checkbox" onChange={(e)=>setPush(e.target.checked)}></input>
      </InputRow>
      <InputRow>
        <Label>익명여부</Label>
        <Input
          type="checkbox"
          checked={type}
          onChange={(e: React.ChangeEvent<HTMLInputElement>)=>onChangeType(e)}
        />
      </InputRow>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>이용가능</Label>
        <FieldsWrapper>
        {
          Object.values(BOARD_ROLES_MEM).map((mem) => {
            const find = roles.find((f) => f == mem)
            if (find) {
              return(
                <Button
                  key={1}
                  style={{
                    backgroundColor: theme.color.black,
                  }}
                  onClick={() => {
                    onChangeRoles(mem)
                  }}
                >
                {mem}
              </Button>
              )
            } else {
              return(
                <Button
                  key={1}
                  style={{
                    backgroundColor: theme.color.black6,
                  }}
                  onClick={() => {
                    onChangeRoles(mem)
                  }}
                >
                {mem}
              </Button>
              )
            }
            })
          }
        </FieldsWrapper>
      </InputRow>
    </ReactModal>
  );
}

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head3,
  fontWeight: "bold",
  marginBottom: theme.spacing.middle,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing.normal,
  ...theme.font.body1,
  margin: `${theme.spacing.small}px 0px`,
}));

const Label = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  minHeight: 150,
}));

const FieldsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing.small,
}));

const Avatar = styled.img<any>(() => ({
  width: 150,
  height: 150,
  borderRadius: 150 / 2,
  objectFit: "cover",
}));

export default NewBoardModal;
