import {
  useUpdateBoardMutation,
  GetAllBoardsDocument,
  useCreateBoardMutation
} from "../../../lib/apollo/graphql/generated";

function useNewBoardModal(onRequestClose?: () => void) {
    
  const [createBoard] =  useCreateBoardMutation({
    onCompleted: () => {
      alert('신규 게시판이 생성되었습니다')
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllBoardsDocument,
        variables: {
          maxPage: 20,
          page: 1, 
        },
      },
    ],
  });

  function onCreateBoard(name: string, type: number, roleId: number, category: string[], push: boolean) {
    createBoard({
      variables: {
        name,
        type,
        roleId,
        category,
        push: push ? 1 : 0
      },
    });
  }

  const [updateBoard] = useUpdateBoardMutation({
    onCompleted: () => {
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllBoardsDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  return {
    models: {},
    operations: {
      onCreateBoard
    },
  };
}

export default useNewBoardModal;
