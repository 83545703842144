import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useClassContainer from "./ClassContainer.hook";
import {
  Table,
  TBody,
  Th,
  THead
  } from "../../common/styles/commonStyles";
import ClassRow from "../components/ClassRow";

function ClassContainer() {
  const {
    refs: { fetchMoreRef },
    models: { loading, data, fetchingMore },
  } = useClassContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <Table>
        <THead>
          <tr>
            <Th>ID</Th>
            <Th>프로필사진</Th>
            <Th>강사명</Th>
            <Th>강사닉네임</Th>
            <Th>강의제목</Th>
            <Th>강의분야</Th>
            <Th>강의 진행중</Th>
            <Th>더보기</Th>
          </tr>
        </THead>
        <TBody>
          {data?.map((tutorClass) => (
            <ClassRow key={tutorClass?.id} tutorClass={tutorClass!} />
          ))}
          <tr style={{ visibility: "hidden" }}>
            <td ref={fetchMoreRef} colSpan={8}>
              Fetch More
            </td>
          </tr>
        </TBody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

export default ClassContainer;
