import { ModalProps } from "global";
import React, {useState, useEffect} from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useUpdateBannerModal from "./UpdateBannerModal.hook";
import { Button } from "../../common/styles/commonStyles";

function UpdateBannerModal({
  bannerId,
  userId,
  isOpen,
  onRequestClose,
}: ModalProps & { bannerId?: number, userId?: string }) {
  const theme = useTheme();

  const {
    models: { state, data, loading },
    operations: {
      onImageInputChange,
      onInputChange,
      onSubmit
    },
  } = useUpdateBannerModal(bannerId, userId, onRequestClose);

  const [edit, setEdit] = useState(false)

  if (loading) return <div />;

  const {
    desc,
    order,
    link,
    avatar,
    avatarState,
    imageUrl
  } = state;

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Title>배너 수정</Title>
      <Button onClick={onSubmit}>
        제출하기
      </Button>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>배너설명</Label>
        <Textarea
          placeholder="설명"
          value={desc}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "desc")
          }
        />
      </InputRow>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>링크</Label>
        <Input 
          placeholder="링크"
          value={link}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "link")
          }
        />
      </InputRow>
      <InputRow>
        <Label>배너이미지</Label>
        <Input
          type="file"
          accept="image/*"
          placeholder="배너이미지"
          onChange={onImageInputChange}
        />
      </InputRow>
      {
        !avatarState
        ? <img src={`https://storage.cloud.google.com/altruists_dev_media/${imageUrl}`} />
        :
        <div>
          <p>수정전</p>
          <img src={`https://storage.cloud.google.com/altruists_dev_media/${imageUrl}`} />
        </div>
      }
      {
        avatarState &&
        <div>
          <p>수정후</p>
          <Avatar src={URL.createObjectURL(avatarState)} />
        </div>
      }
      
      {/* {avatarState && <Avatar src={URL.createObjectURL(avatarState)} />} */}
      {/* {!avatarState && avatar && <Avatar src={avatar?.uri} />} */}
    </ReactModal>
  );
}

const BannerImage= styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "500px",
  height: "250px"
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head3,
  fontWeight: "bold",
  marginBottom: theme.spacing.middle,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing.normal,
  ...theme.font.body1,
  margin: `${theme.spacing.small}px 0px`,
}));

const Label = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  minHeight: 150,
}));

const FieldsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing.small,
}));

const Avatar = styled.img<any>(() => ({
  width: 150,
  height: 150,
  borderRadius: 150 / 2,
  objectFit: "cover",
}));

export default UpdateBannerModal;
