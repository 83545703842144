import { format } from "date-fns";
import React from "react";
import styled from "styled-components";
import useTutorListTable from "./TutorListTable.hook";
import {
  Button,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../../../common/styles/commonStyles";

interface MentorListTableProps {
  requestedOnly?: boolean;
}

function TutorListTable({ requestedOnly = false }: MentorListTableProps) {
  const {
    refs: { fetchMoreRef },
    models: { loading, data, fetchingMore },
    operations: { onVerifyClick, onActivateClick },
  } = useTutorListTable(requestedOnly);

  if (loading) return <div />;

  return (
    <Table>
      <THead>
        <tr>
          <Th style={{ width: "2%" }}>ID</Th>
          <Th>사진</Th>
          <Th>이름</Th>
          <Th>닉네임</Th>
          <Th>이메일</Th>
          <Th>전화번호</Th>
          <Th>전문분야</Th>
          <Th style={{ width: "10%" }}>소개</Th>
          <Th style={{ width: "10%" }}>경력</Th>
          <Th>가입일</Th>
          <Th>멘토요청일</Th>
          <Th>승인여부</Th>
          <Th>승인하기</Th>
          <Th>활동관리</Th>
        </tr>
      </THead>
      <TBody>
        {data?.map((tutor) => {
          const {
            id,
            introduction,
            experience,
            fields,
            verified,
            createdAt,
            user: {
              name,
              username,
              avatar,
              email,
              phone,
              createdAt: userCreatedAt,
              deletedAt,
            },
            active,
          } = tutor!;

          return (
            <tr key={id}>
              <Td>{id}</Td>
              <Td>{avatar ? <Avatar src={avatar?.uri} /> : null}</Td>
              <Td>{name || "-"}</Td>
              <Td>{username}</Td>
              <Td>{email}</Td>
              <Td>{phone || "-"}</Td>
              <Td>{fields?.map((field) => "#" + field?.name + " ")}</Td>
              <Td>{introduction}</Td>
              <Td>{experience}</Td>
              <Td>{format(new Date(userCreatedAt), "yyyy-MM-dd")}</Td>
              <Td>{format(new Date(createdAt), "yyyy-MM-dd")}</Td>
              <Td>{verified ? "O" : "X"}</Td>
              <Td>
                {verified ? (
                  "승인됨"
                ) : (
                  <Button onClick={() => onVerifyClick(id)}>승인</Button>
                )}
              </Td>
              <Td>
                {active ? "O" : "X"}
                <Button
                  disabled={!!deletedAt}
                  onClick={() => onActivateClick(id, active)}
                >
                  {deletedAt ? "탈퇴함" : active ? "활동정지" : "활동재개"}
                </Button>
              </Td>
            </tr>
          );
        })}
        <tr style={{ visibility: "hidden" }}>
          <td ref={fetchMoreRef} colSpan={8}>
            Fetch More
          </td>
        </tr>
      </TBody>
    </Table>
  );
}

const Avatar = styled.img<any>(() => ({
  width: 50,
  height: 50,
  borderRadius: 25,
  objectFit: "cover",
}));

export default TutorListTable;
