import styled, { ThemedComponentProps } from "styled-components";

interface ButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

export const Button = styled.button<any>(
  ({ theme, disabled }: ButtonProps) => ({
    cursor: "pointer",
    padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
    backgroundColor: theme.color.black3,
    ...theme.font.body3,
    color: theme.color.white,
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? "none" : "auto",

    ":hover": {
      backgroundColor: theme.color.black1,
    },
  })
);

export const Table = styled.table<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  borderSpacing: 0,
}));

export const THead = styled.thead<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.background,
}));

export const Th = styled.th<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body1,
  textAlign: "left",
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  border: `1px solid ${theme.color.black6}`,
}));

export const TBody = styled.tbody<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  ...theme.font.body2,
}));

export const Td = styled.td<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.small}px`,
  border: `1px solid ${theme.color.black8}`,
}));
