import { ModalProps } from "global";
import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import { Button } from "../../common/styles/commonStyles";
import useUpdateBoardModal from "./UpdateBoardModal.hook";
import { BOARD_ROLES, BOARD_ROLES_MEM } from "../../../constants";

function UpdateBoardModal({
  boardId,
  isOpen,
  onRequestClose,
}: ModalProps & { boardId?: number | null }) {
  const theme = useTheme();
  
  const {
    models: { state, data, loading },
    operations: { onUpdateBoard, onDeleteSelectedBoard, onInputChange, onCheckboxChange },
  } = useUpdateBoardModal(boardId, onRequestClose, categorySplit, setRoleArr);

  const [roles, setRoles] = useState<string[]>([]);
  const [categoryArr, setCategoryArr] = useState<string[]>([]);

  function categorySplit (categoryStr: string) {
    categoryStr ? setCategoryArr(categoryStr.split(',')) : setCategoryArr([])
  }

  function setRoleArr (roleId: number) {
    setRoles(BOARD_ROLES[roleId - 1]);
  }

  function onChangeRoles(member: string) {
    let tempRoles = [...roles];

    const find = roles.find((r) => r == member);

    if (!find) {
      tempRoles.push(member);
    } else {
      tempRoles = tempRoles.filter((role) => role != member);
    }

    setRoles(tempRoles);
  }

  function validation() {
    let changedDatas: any = {} ;
    //이름검증
    if (name != data?.name) {
      changedDatas = {...changedDatas, name}
    }

    //익명여부
    if (type != data?.type) {
      changedDatas = {...changedDatas, type}
    }

    //이용가능자
    const sortedRoles = roles.sort()


    let roleid = 0
    BOARD_ROLES.map((role,index)=>{
      if (JSON.stringify(role)==JSON.stringify(sortedRoles)) {
        roleid = index+1
      }
    })

    if (roleid != data?.roleId) {
      changedDatas = {...changedDatas, roleId: roleid}
    }

    //category 검증
    if (categoryArr.join() != data?.category) {
      changedDatas = {...changedDatas, category: categoryArr.join()}
    }

    //푸시여부
    if (push != data?.push) {
      changedDatas = {...changedDatas, push}
    }

    if (Object.keys(changedDatas).length < 1) return false
    return changedDatas
  }

  function onUpdate() {
    const changedDatas = validation()

    if(!changedDatas) {
      alert('수정사항이 없습니다')
      return
    }

    onUpdateBoard(changedDatas);
  }

  const newCategory = () => {
    let tempCat = [...categoryArr]
    tempCat.push('')
    setCategoryArr(tempCat)
  }

  const setNewCategory = (index: number, cat: string) => {
    let tempCat = [...categoryArr]
    tempCat[index] = cat.trim()
    setCategoryArr(tempCat)
  }

  const deleteCategory = (index: number) => {
    const front = categoryArr.slice(0, index)
    const back = categoryArr.slice(index+1)

    const temp = front.concat(back)

    setCategoryArr(temp)
  }

  if (loading) return <div />;

  const { name, type, roleId, category, push } = state

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
      {data && (
        <>
          <Title>게시판 관리</Title>
          <ButtonWrapper>
            <Button onClick={onUpdate}>
                제출하기
            </Button>
            <Button onClick={onDeleteSelectedBoard}>
                삭제하기
            </Button>
          </ButtonWrapper>
          <InputRow>
            <Label>게시판명</Label>
            <Input
              placeholder={name}
              value={name}
              onChange={(e: any) => onInputChange(e, 'name')}
            />
          </InputRow>
          <InputRow>
            <Label>말머리</Label>
            {
              categoryArr?.map((cat, index) => {
                return <div>
                <Input
                  placeholder="말머리"
                  value={cat}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    {setNewCategory(index, e.target.value)}
                  }
                />
                <span onClick={()=>deleteCategory(index)}>x</span>
                </div>
              })
            }
            <button onClick={newCategory}>+</button>
          </InputRow>
          <InputRow>
            <Label>푸시알림</Label>
            <Input
              type="checkbox"
              checked={push}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheckboxChange(e, 'push')}
            />
          </InputRow>
          <InputRow>
            <Label>익명여부</Label>
            <Input
              type="checkbox"
              checked={type}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheckboxChange(e, 'type')}
            />
          </InputRow>
          <InputRow style={{ alignItems: "flex-start" }}>
            <Label>이용가능</Label>
            <FieldsWrapper>
              {roleId &&
                Object.values(BOARD_ROLES_MEM).map((mem) => {
                  const find = roles.find((f) => f == mem);
                  if (find) {
                    return (
                      <Button
                        key={1}
                        style={{
                          backgroundColor: theme.color.black,
                        }}
                        onClick={() => {
                          onChangeRoles(mem);
                        }}
                      >
                        {mem}
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        key={1}
                        style={{
                          backgroundColor: theme.color.black6,
                        }}
                        onClick={() => {
                          onChangeRoles(mem);
                        }}
                      >
                        {mem}
                      </Button>
                    );
                  }
                })}
            </FieldsWrapper>
          </InputRow>
      </>
      )}
    </ReactModal>
  );
}

const ButtonWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: "20px"
}));
const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "500px",
  height: "400px"
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head3,
  fontWeight: "bold",
  marginBottom: theme.spacing.middle,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing.normal,
  ...theme.font.body1,
  margin: `${theme.spacing.small}px 0px`,
}));

const Label = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  
}));

const FieldsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing.small,
}));

export default UpdateBoardModal;
