import React, { useEffect, useState } from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useBoardWrapper from "./BoardWrapper.hook"
import { Button } from "../../common/styles/commonStyles";
import UpdateBoardModal from "./UpdateBoardModal";
import NewBoardModal from "./NewBoardModal";
import PostFormModal from "./PostFormModal";

type BoardWrapperProps = {
  setChagePostList: React.Dispatch<React.SetStateAction<boolean>>;
  tab: number | null;
  postId: number | null;
  search: (tempSearchCategory: string, tempSearchWord: string) => void
  onTabSelect: (selectedTab: number | undefined) => void;
  onSetPostTotal: (total: number | undefined) => void
}

function BoardWrapper({ setChagePostList, tab, postId, search, onTabSelect, onSetPostTotal }: BoardWrapperProps) {
  const theme = useTheme();
  const [showUpdateBoardModal, setShowUpdateBoardModal] = useState(false)
  const [showNewBoardModal, setShowNewBoardModal] = useState(false)
  const [showPostFormModal, setShowPostFormModal] = useState(false)
  const [category, setCategory] = useState<string[]>([])
  const [tempSearchWord, setTempSearchWord] = useState('')
  const [tempSearchCategory, setTempSearchCategory] = useState('')

  function onSetCategory(category: string | null | undefined) {
    if (category) {
      const catArr = category.split(',')
      setCategory(catArr)
    } 
  }

  const {
    models: { state, loading, data },
  } = useBoardWrapper(onTabSelect, onSetCategory, onSetPostTotal);

  function toggleShowUpdateBoardModal(show: boolean) {
    setShowUpdateBoardModal(show);
  }
  
  function toggleShowNewBoardModal(show: boolean) {
    setShowNewBoardModal(show);
  }

  function toggleShowPostFormModal(show: boolean) {
    setShowPostFormModal(show);
  }

  useEffect(()=>{
    if (data) {
      const find = data.find((f) => f?.id == tab)
      const cat = find?.category?.split(',')
      cat ? setCategory(cat) : setCategory([])
      setTempSearchCategory('title')
    }
  },[tab])

  if (loading) return <div />;

  const {
    total
  } = state;

  return(
    <>
    <BoardTypeButton>
      {
        data?.map((board, index) => {
          return <Button
            key={index}
            style={{
              backgroundColor:
                board?.id === tab ? theme.color.black1 : theme.color.black6,
            }}
            onClick={() => {if (!postId) onTabSelect(board?.id)}}
          >
            {board?.name}
          </Button>
        })
      }
      {
        (data && data?.length < 5 && !postId)
        && 
        <Button
            style={{
              backgroundColor: theme.color.black1
            }}
            onClick={() => {toggleShowNewBoardModal(true)}}
          >
            +
        </Button>
      }
      {
        !postId &&
        <div>
          <div>
            <CustomButton onClick={()=>{setShowUpdateBoardModal(true)}}>설정</CustomButton> 
          </div>
        </div>
      }
      {
        showUpdateBoardModal &&
        <UpdateBoardModal
          boardId={tab}
          isOpen={showUpdateBoardModal}
          onRequestClose={() => toggleShowUpdateBoardModal(false)}
      />
      }
      {
        showNewBoardModal &&
        <NewBoardModal
          isOpen={showNewBoardModal}
          onRequestClose={() => toggleShowNewBoardModal(false)}
      />
      }
      {
        showPostFormModal &&
        <PostFormModal 
          category={category}
          boardId={tab}
          isOpen={showPostFormModal}
          onRequestClose={() => {
            setChagePostList(true)
            toggleShowPostFormModal(false)
          }
          }
        />
      }
    </BoardTypeButton>
    {
        !postId &&
        <div style={{display: "flex", justifyContent:"space-between", margin: "20px 0"}}>
          <div style={{display: "flex", justifyContent:"flex-start"}}>
            <Select name="category" onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setTempSearchCategory(e.target.value)
              }>
                <option value="title" selected>제목</option>
                <option value="username">작성자</option>
            </Select>
            <div>
              <input type='text' onChange={(e)=>setTempSearchWord(e.target.value)}></input>
              <CustomButton onClick={()=>search(tempSearchCategory, tempSearchWord)}>검색</CustomButton>
            </div>
          </div>
          <div style={{display: "flex", justifyContent:"flex-end"}}>
            <div>
              <CustomButton onClick={()=>{setShowPostFormModal(true)}}>게시글 작성</CustomButton> 
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default BoardWrapper;

const BoardTypeButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "10px",

  marginBottom: theme.spacing.normal,
}));

const Select = styled.select<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Option = styled.option<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px"
}));