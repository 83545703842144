import { format } from "date-fns";
import React, {useState, useEffect} from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import usePostList from "./PostListTable.hook";
import {
  Table,
  TBody,
  Td,
  Th,
  THead
  } from "../../common/styles/commonStyles";

type PostListTableProps ={
  tab: number;
  searchInfo: {searchCategory: string, searchWord: string};
  onSetPostTotal: (total: number | undefined) => void;
  onPostSelect(selectedPost: number): void;
  deletePostOnList: () => void;
  changePostList: boolean;
  setChagePostList: React.Dispatch<React.SetStateAction<boolean>>
}

  function PostListTable({changePostList, setChagePostList, tab, searchInfo, onSetPostTotal, onPostSelect, deletePostOnList}: PostListTableProps) {
  const {
    refs: { fetchMoreRef },
    models: { state, loading, data, fetchingMore },
    operations: { reFetch, onDeletePost }
  } = usePostList(tab, onSetPostTotal);

  const [posts, setPosts] = useState<any>([])
  const [skip, setSkip] = useState(true)
  const [change, setChange] = useState(false)

  const deletePost = (index: number, postId: number) => {
    const temp = [...posts]
    const front = temp.slice(0, index)
    const back = temp.slice(index+1)

    const t = front.concat(back)

    setPosts(t)

    onDeletePost(tab, postId)
    deletePostOnList()
  }

  useEffect(()=>{
    if (skip) setSkip(false)
    else {
      setPosts([])
      setChange(true)
      reFetch(skip, tab, searchInfo)
    }
  },[tab, searchInfo])

  useEffect(()=>{
    if (changePostList) {
      // console.log(changePostList)
      setPosts([])
      setChange(true)
      // reFetch(skip, tab, searchInfo)
      setChagePostList(false)
    }
  },[changePostList])

  useEffect(()=>{
    if (change) {
      reFetch(skip, tab, searchInfo)
      setChange(false)
    }

    // if (changePostList) {
    //   console.log(changePostList)
    //   setPosts([])
    //   reFetch(skip, tab, searchInfo)
    //   setChagePostList(false)
    // }
  },[change])

  useEffect(()=>{
    if(state) setPosts((prev:any)=>[...prev, ...state])
  },[state])

  if (loading) return <div />;

  return (
    <Table>
      <THead>
        <tr>
          <Th>번호</Th>
          <Th>제목</Th>
          <Th>작성자</Th>
          <Th>작성일</Th>
          <Th>수정일</Th>
          <Th>더보기</Th>
        </tr>
      </THead>
      <TBody>
        {
          posts?.map((post: any, index: number) => {
            console.log(post)
            return(
            <tr key={post?.id}>
              <Td>{post?.id}</Td>
              <Td onClick={()=>onPostSelect(post?.id)}>{post?.title}</Td>
              <Td>{post?.user?.username ? post?.user?.username: '관리자' }</Td>
              <Td>{format(new Date(post?.createdAt), "yyyy-MM-dd")}</Td>
              <Td>{format(new Date(post?.updatedAt), "yyyy-MM-dd")}</Td>
              <Td><CustomButton onClick={()=>{deletePost(index, post?.id)}}>삭제</CustomButton></Td>
            </tr>
            )
        })
        }{
          !loading && posts.length > 0
          &&
          <tr style={{ visibility: "hidden" }}>
          <td ref={fetchMoreRef} colSpan={8}>
            Fetch More
          </td>
        </tr>
        }

      </TBody>
    </Table>

  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px"
}));

export default PostListTable;

type SearchWrapperProps = {
  onSetSearchWord(searchText: string): void
}

function SearchWrapper({ onSetSearchWord }: SearchWrapperProps) {
  return(
    <div>
        <input type='text' onChange={(e) => onSetSearchWord(e.target.value)}></input>
        <button>검색</button>
    </div>
  )
}