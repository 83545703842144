import imageCompression from "browser-image-compression";
import { format } from "date-fns";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../constants";
import {
  UpdateBannerMutation,
  useUpdateBannerMutation,
  useGetFieldsQuery,
  useUpdateUserProfileAsAdminMutation,
  GetAllBannersDocument,
  useAddBannerImageMutation,
} from "../../../lib/apollo/graphql/generated";

function useCreateBanner(bannerTypes?: string[], bannerId?: number, userId?: string, onRequestClose?: () => void) {
  const isNew = !userId;

  const [state, setState] = useState({
    description: "",
    type: "",
    links: [] as any,
    avatar: null as any,
    avatarState: [] as any,
  });

  const { loading, data } = useGetFieldsQuery();

  const [updateBanner] = useUpdateBannerMutation({
    onCompleted: () => {
      alert('저장되었습니다')
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllBannersDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  const [addBannerImage] = useAddBannerImageMutation({
    onCompleted: () => {
      alert('저장되었습니다')
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllBannersDocument,
        variables: {
          first: 20,
        },
      },
    ],
  })

  function onInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    if (
      key === "description" &&
      value.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    if (
      key === "order" &&
      (isNaN(Number(value)) || Number(value) > 5 || Number(value)  < 0)
    ) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: key === "order" ? Number(value) : value,
    }));
  }

  function onLinkChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const temp = [...state.links]
    temp[index] = e.target.value

    setState((prev) => ({
      ...prev,
      links: temp,
    }));

  }

  function onImageInputChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { files } = e.target;
    const temp = [...state.avatarState]
    temp[index] = files?.[0]

    setState((prev) => ({
      ...prev,
      avatarState: temp,
    }));
  }

  function deleteBannerImage(index: number) {
    const {avatarState} = state;

    const front = avatarState.slice(0, index)
    const back = avatarState.slice(index+1)

    const temp = front.concat(back)

    setState((prev) => ({
      ...prev,
      avatarState: temp,
    }));
  }

  async function addImage(e: React.ChangeEvent<HTMLInputElement>, type: string) {
    const { files } = e.target;
    const image = files?.[0]

    const avatarInput = { file: null as any };

    if (image) {
      const compressed = await imageCompression(
        image,
        IMAGE_COMPRESSION_OPTIONS
      );

      avatarInput.file = compressed;
    }

    addBannerImage({
      variables: {
        type, 
        avatarInput,
      },
    });
  }

  
  async function onSubmit() {
    const {
      description,
      type, 
      links,
      avatar,
      avatarState,
    } = state;

    const find = bannerTypes?.find((banner) => banner == type)
    
    if (find) {
      alert('중복되는 type이 있습니다')
      return
    }

    if (avatarState.length < 1) {
      alert('배너 이미지를 등록해주세요')
      return
    }
    
    const avatarInput = [{ file: null as any }];

    if (avatarState.length > 0) {
      for(let i=0; i<avatarState.length; i++) {
        const compressed = await imageCompression(
          avatarState[i],
          IMAGE_COMPRESSION_OPTIONS
        );

        avatarInput[i] = {file: compressed};
      }
    }

    // if (avatarState) {
    //   const compressed = await imageCompression(
    //     avatarState,
    //     IMAGE_COMPRESSION_OPTIONS
    //   );

    //   avatarInput.file = compressed;
    // }

    updateBanner({
      variables: {
        desc: description,
        type, 
        links,
        avatarInput,
      },
    });
  }

  return {
    models: {
      state,
      isNew,
      loading,
    },
    operations: {
      onImageInputChange,
      onInputChange,
      onLinkChange,
      onSubmit,
      deleteBannerImage,
      addImage
    },
  };
}

export default useCreateBanner;
