import { ModalProps } from "global";
import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import usePostFormModal from "./PostFormModal.hook";
import { Button } from "../../common/styles/commonStyles";
import Editor from "../../common/components/Editor";

function PostFormModal({
  boardId,
  category,
  isOpen,
  onRequestClose,
}: ModalProps & { boardId?: number | null, category?: string[] }) {
  const theme = useTheme();

  const {
    models: { state, data, loading },
    operations: { addFileList, onSubmit, onChange, onSelectChange, onInputChange, onFileChange, onDeleteFile },
  } = usePostFormModal(boardId, onRequestClose);

  if (loading) return <div />;

  const {
    content,
    title,
    fileList
  } = state
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
    <Wrapper>
      <PolicyTypeButton>
        <LeftButtons></LeftButtons>
        <Button onClick={onSubmit}>저장</Button>
      </PolicyTypeButton>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>제목</Label>
        <Input 
          placeholder="제목"
          value={title}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "title")
          }
        />
      </InputRow>
      {
        (category && category?.length > 0) &&
        <InputRow style={{ alignItems: "flex-start" }}>
          <Label>머리말</Label>
          <Select name="category" onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              onSelectChange(e)
            }>
            {
              category?.map((cat, index) => {
                return(<Option value={cat} >{cat}</Option>
                )
              })
            }
          </Select>
        </InputRow>
      }
      <Editor
        value={content}
        onChange={onChange}
      />
      {
        fileList?.map((fi: any, index: number) => {
          return(
            <div>   
              <label>파일첨부</label>
              <input type="file"onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{onFileChange(e, index)}}></input>
              <CustomButton onClick={(e: React.ChangeEvent<HTMLInputElement>)=>{onDeleteFile(index)}}>삭제</CustomButton>
            </div>
          )
        })
      }
      <CustomButton onClick={addFileList}>+</CustomButton>
    </Wrapper>
    </ReactModal>
  );
}

export default PostFormModal;

const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px"
}));

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const PolicyTypeButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  marginBottom: theme.spacing.normal,
}));

const LeftButtons = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: theme.spacing.small,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing.normal,
  ...theme.font.body1,
  margin: `${theme.spacing.small}px 0px`,
}));

const Label = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Select = styled.select<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Option = styled.option<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));
