//postListTable.hook.ts

import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { 
  GetAllPostsDocument, 
  useDeletePostMutation, 
  useGetAllPostsQuery,
  useGetAllPostsTempQuery,
  GetAllPostsTempDocument

} from "../../../lib/apollo/graphql/generated";

function usePostListTable(tab?: number, onSetPostTotal?: (total: number | undefined) => void) {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });
  const [fetchingMore, setFetchingMore] = useState(false);
  let searchCat = ''
  let search = ''

  const [state, setState] = useState<any[]>([])

  const { data, loading, fetchMore, refetch } = useGetAllPostsTempQuery({
    onCompleted: (data) => {
      if (data?.getAllPostsTemp?.total || data?.getAllPostsTemp?.total == 0) {
        data?.getAllPostsTemp?.postConnection?.edges && setState(data?.getAllPostsTemp?.postConnection?.edges)
        onSetPostTotal && onSetPostTotal(data?.getAllPostsTemp?.total)
      }
    },variables: {
      first: 20,
      boardId: tab
    },
  });

 function newPost() {
 }

  async function onFetchMore(inView: boolean) {
    setFetchingMore(false);

    if (inView && !fetchingMore) {
      setFetchingMore(true);
      
      const { hasNextPage, endCursor } = data?.getAllPostsTemp?.postConnection?.pageInfo!;
      if (hasNextPage) {
        refetch({
            first: 20,
            boardId: tab,
            after: endCursor
          
        }).then(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  async function reFetch(skip: boolean, boardId: number, searchInfo: {searchWord: string, searchCategory: string}) {
    if (skip) {
      return
    }

    searchCat = searchInfo.searchCategory
    search = searchInfo.searchWord

    refetch({
      first: 20,
      boardId,
      after: undefined,
      searchCategory: searchCat,
      searchWord: search
    }).then((res)=>{
      res.data.getAllPostsTemp?.postConnection?.edges && setState(res.data.getAllPostsTemp?.postConnection?.edges)
    })
  }

  const [deletePost] =  useDeletePostMutation();

  function onDeletePost(boardId: number, id: number) {
    const confirm = window.confirm('삭제하시겠습니까?')
    
    if(!confirm) {
      return 
    }

    deletePost({
      variables: {
        boardId,
        id,
      },
    });
  }


  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      state,
      loading,
      data: data?.getAllPostsTemp?.postConnection?.edges,
      fetchingMore
      // total: data?.getAllPosts?.total,
      // data: data?.getAllPosts?.result,
    },
    operations: {
      onFetchMore,
      onDeletePost,
      reFetch
    },
  };
}

export default usePostListTable;
