import React from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import usePolicyContainer from "./PolicyContainer.hook";
import { POLICY_TYPES, POLICY_TYPES_TEXT } from "../../../constants";
import Editor from "../../common/components/Editor";
import { Button } from "../../common/styles/commonStyles";

function PolicyContainer() {
  const theme = useTheme();

  const {
    models: { loading, state, tab },
    operations: { onTabSelect, onChange, onSubmit },
  } = usePolicyContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <PolicyTypeButton>
        <LeftButtons>
          {Object.keys(POLICY_TYPES).map((key) => (
            <Button
              key={key}
              style={{
                backgroundColor:
                  key === tab ? theme.color.black1 : theme.color.black6,
              }}
              onClick={() => onTabSelect(key)}
            >
              {POLICY_TYPES_TEXT[key]}
            </Button>
          ))}
        </LeftButtons>
        <Button onClick={onSubmit}>저장</Button>
      </PolicyTypeButton>

      <Editor
        value={
          tab === POLICY_TYPES.TERMS_OF_POLICY ? state.terms : state.privacy
        }
        onChange={onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const PolicyTypeButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  marginBottom: theme.spacing.normal,
}));

const LeftButtons = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  gap: theme.spacing.small,
}));

export default PolicyContainer;
