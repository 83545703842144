import { ModalProps } from "global";
import React from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useCreateBanner from "./CreateBanner.hook";
import { Button } from "../../common/styles/commonStyles";

function CreateBanner({
  bannerTypes,
  addBanner,
  bannerId,
  userId,
  isOpen,
  onRequestClose,
}: ModalProps & { bannerTypes?: string[], addBanner?: boolean, bannerId?: number, userId?: string }) {
  const theme = useTheme();

  const {
    models: { state, loading, isNew },
    operations: {
      onInputChange,
      onImageInputChange,
      onSubmit,
      deleteBannerImage,
      onLinkChange
    },
  } = useCreateBanner(bannerTypes, bannerId, userId, onRequestClose);

  if (loading) return <div />;

  const {
    description,
    type,
    links,
    avatar,
    avatarState,
  } = state;

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Title>배너 등록</Title>
      <Button onClick={onSubmit}>
        제출하기
      </Button>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>배너설명</Label>
        <Textarea
          placeholder="설명"
          value={description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "description")
          }
        />
      </InputRow>
      <InputRow style={{ alignItems: "flex-start" }}>
        <Label>타입</Label>
        <Input
          placeholder="타입"
          value={type}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "type")
          }
        />
      </InputRow>
      {
        avatarState?.map((a: any, index: number) => {
          return <InputRow style={{ alignItems: "flex-start" }}>
          <Label>링크 {index+1}</Label>
          <Input 
            placeholder="링크"
            value={links[index]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onLinkChange(e, index)
            }
          />
        </InputRow>
        })
      }
      <InputRow>
        <Label>배너이미지</Label>
        {
          (avatarState && avatarState?.length < 5) &&
          <FakeButton>
          <label htmlFor="files1">이미지추가</label> 
          <ImageInput
            id = "files1"  
            type="file"
            accept="image/*"
            onChange={
              (e: React.ChangeEvent<HTMLInputElement>)=>{
                onImageInputChange(e, avatarState.length)
              }
              }
          />
        </FakeButton>
        }
      </InputRow>
      <BannerImageContainer>
      {
        avatarState?.map((avatar: any, index: number) => {
          return (
            <BannerImageWrapper>
              <BannerImage src={URL.createObjectURL(avatar)} />
              <FakeButton>
                <label htmlFor={`file${index}`}>변경</label> 
                <ImageInput
                  id = {`file${index}`}  
                  type="file"
                  accept="image/*"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>)=>onImageInputChange(e, index)}
                />
              </FakeButton>
              <button onClick={()=>{ deleteBannerImage(index) }}>삭제</button>
            </BannerImageWrapper>
          )
        })
      }
      </BannerImageContainer>
      {/* {avatarState && <Avatar src={URL.createObjectURL(avatarState)} />} */}
      {/* {!avatarState && avatar && <Avatar src={avatar?.uri} />} */}
    </ReactModal>
  );
}

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head3,
  fontWeight: "bold",
  marginBottom: theme.spacing.middle,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing.normal,
  ...theme.font.body1,
  margin: `${theme.spacing.small}px 0px`,
}));

const Label = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
}));

const Textarea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  minHeight: 150,
}));

const FieldsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 9,
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing.small,
}));

const Avatar = styled.img<any>(() => ({
  width: 150,
  height: 150,
  borderRadius: 150 / 2,
  objectFit: "cover",
}));

const BannerImageContainer = styled.div<any>(() => ({
  display: "flex",
  gap: "20px"
  // borderRadius: 150 / 2,
  // objectFit: "cover",
}));

const BannerImageWrapper = styled.div<any>(() => ({
  width: 250,
  height: 150,
  // borderRadius: 150 / 2,
  // objectFit: "cover",
}));

const BannerImage = styled.img<any>(() => ({
  width: 250,
  height: 150,
  objectFit: "fill",
}));

const ImageInput = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  width: "0",
  height: "0",
  overflow: "hidden"
}));

const FakeButton = styled.div<any>(() => ({
  border: "2px solid gainsboro",
  borderRadius: "10px"
}));


export default CreateBanner;
