import React, {useState, useEffect} from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useBannerListTable from "./BannerListTable.hook";
import {
  Table,
  TBody,
  Td,
  Th,
  THead
  } from "../../common/styles/commonStyles";
import UpdateBanner from "./UpdateBanner";
import UpdateBannerModal from "./UpdateBannerModal";
import useUpdateBanner from "./UpdateBanner.hook";
import useCreateBanner from "./CreateBanner.hook";

type BannerListTable={
  changeBannerTypes: (types: string[]) => void
  onSelectBanner: (type: string) => void
  showUpdateBanner: boolean
  toggleShowUpdateBanner: (show: boolean) => void
  setAddBanner: React.Dispatch<React.SetStateAction<boolean>>
}
function BannerListTable({ changeBannerTypes, setAddBanner, onSelectBanner, showUpdateBanner, toggleShowUpdateBanner }: BannerListTable) {
  const {
    models: { bannerType, state, loading, data },
    operations: { onDeleteBanners, onDeleteBanner, changeData, orderChange }
  } = useBannerListTable(changeBannerTypes);

  const {
    operations:{ addImage }
  } = useCreateBanner()

  const [bannerId, setBannerId] = useState(0)
  
  function setOrder(mainIdx: number, index: number, direction: 'up' | 'down') {
    if(!state) {
      return
    }

    let tempArr1 = [...bannerType]
    let tempArr = bannerType[mainIdx]

    if (direction == 'up') {
      if (index == 0) return
      const temp = tempArr[index]
      tempArr[index] = tempArr[index-1]
      tempArr[index-1] = temp

    } else {
      if (index == tempArr.length-1) return
      const temp = tempArr[index]
      tempArr[index] = tempArr[index+1]
      tempArr[index+1] = temp
    }

    tempArr1[mainIdx] = tempArr 
    changeData(tempArr1)
  }

  useEffect(()=>{
    if (bannerId)
      toggleShowUpdateBanner(true)
  },[bannerId])
  if (loading) return <div />;

  return (
    <div>
          {
            bannerType?.map((ad: any, mainIdx: number) =>{
              return <BannerWrapper>
                <BannerInfo>
                <div>
                  <div>타입 : {ad[0].type}</div>   
                  <div>설명 : {ad[0].desc}</div>    
                </div>
                {
                  ad?.length < 5
                  && <CustomButton>
                  <label htmlFor={`files`}>배너 추가</label> 
                  <ImageInput
                    id = {`files`}  
                    type="file"
                    accept="image/*"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{addImage(e, ad[0]?.type)}}
                  />
                </CustomButton>
                }
                <CustomButton onClick={()=>{orderChange(mainIdx)}}>순서수정</CustomButton>
                <CustomButton onClick={()=>{onDeleteBanners(ad[0].type)}}>삭제</CustomButton>
                </BannerInfo>
                <BannerImageWrapper>
                  {
                  ad.map((d: any, index: number) => {
                    return <div>
                    <div><BannerImage src={`https://storage.cloud.google.com/altruists_dev_media/${d?.media?.path}`}></BannerImage></div>
                    <div>링크{index+1}:{d?.link}</div>
                    <div>
                    <CustomButton onClick={()=>{
                        setBannerId(d?.id)
                        }}>수정</CustomButton>
                    <CustomButton onClick={()=>{onDeleteBanner(d?.id)}}>삭제</CustomButton>
                    </div>
                    <div>
                      <CustomButton onClick={()=>{setOrder(mainIdx, index, 'up')}}>◀</CustomButton>
                      <CustomButton onClick={()=>{setOrder(mainIdx, index, 'down')}}>▶</CustomButton>
                    </div>
                    </div>
                  })
                    }
                </BannerImageWrapper>
              </BannerWrapper>

            })

          }
        {
          <UpdateBannerModal 
            bannerId={bannerId}
            isOpen={showUpdateBanner}
            onRequestClose={() => {
              setBannerId(0)
              toggleShowUpdateBanner(false)
            }}
          />
          // <UpdateBanner 
          //   banners={banners}
          //   isOpen={showUpdateBanner}
          //   onRequestClose={() => {
          //     setBanners([])
          //     toggleShowUpdateBanner(false)
          //   }}
          // />
        }
    </div>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const BannerImage = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "300px",
  height: "200px",
}));

const BannerWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  marginBottom: "30px",
  border: "1px solid gainsboro"
}));

const BannerImageWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: "30px",
  border: "1px solid gainsboro"
}));

const BannerInfo = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: "5px 10px",
  display: "flex",
  justifyContent: "space-between"
}));


const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px"
}));

const ImageInput = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  width: "0",
  height: "0",
  overflow: "hidden"
}));

export default BannerListTable;
