import React, {useState, useEffect} from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import UpdateBannerModal from "../components/UpdateBannerModal";
import BannerListTable from "../components/BannerListTable";
import CreateBanner from "../components/CreateBanner";

function BannerContainer() {
  const [showBannerFormModal, setShowBannerFormModal] = useState(false)
  const [showUpdateBannerFormModal, setShowUpdateBannerFormModal] = useState(false)

  const [showUpdateBanner, setShowUpdateBanner] = useState(false)
  const [bannerId, setBannerId] = useState<string| null>(null)
  const [addBanner, setAddBanner] = useState(false)

  const [bannerTypes, setBannerTypes] = useState<string[]>([])

  function changeBannerTypes(types: string[]) {
    setBannerTypes(types)
  }
  function toggleShowBannerFormModal(show: boolean) {
    if (!show) addBanner && setAddBanner(false)
    setShowBannerFormModal(show);
  }

  function toggleShowUpdateBannerFormModal(show: boolean) {
    setShowUpdateBannerFormModal(show);
  }

  function toggleShowUpdateBanner(show: boolean) {
    setShowUpdateBanner(show);
  }

  function onSelectBanner(type: string) {
    setBannerId(type)
  }

  useEffect(()=>{
    if(addBanner) toggleShowBannerFormModal(true)
  },[addBanner])

  return (
    <Wrapper>
      <CustomButton onClick={()=>setShowBannerFormModal(true)}>신규 배너 등록</CustomButton>
      <BannerListTable changeBannerTypes={changeBannerTypes} setAddBanner={setAddBanner} onSelectBanner={onSelectBanner} showUpdateBanner={showUpdateBanner} toggleShowUpdateBanner={toggleShowUpdateBanner}/>
      {
        bannerId && 
        <UpdateBannerModal
          isOpen={showUpdateBannerFormModal}
          onRequestClose={() => 
            {
              setBannerId(null)
              toggleShowUpdateBannerFormModal(false)
            }}
      />
      }
      {
        showBannerFormModal&&
        <CreateBanner
          bannerTypes={bannerTypes}
          addBanner={addBanner}
          isOpen={showBannerFormModal}
          onRequestClose={() => toggleShowBannerFormModal(false)}
      />
      }
     </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const CustomButton = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black7}`,
  padding: theme.spacing.small,
  borderRadius: "5px",
  marginBottom: "30px"
}));

export default BannerContainer;
