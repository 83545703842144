import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { useToggleClassActiveMutation } from "../../../lib/apollo/graphql/generated";
import { Button, Td } from "../../common/styles/commonStyles";
import {
    Gender
  } from "../../../lib/apollo/graphql/generated";
import PushFormModal from "./PushFormModal";

interface UnreviewedRowProps {
  review: {
    __typename?: "Review";
    id: string, 
    userId: string, 
    classId: string, 
    createdAt: any, 
    push: number,
    pushedAt: any,
    user: 
    { __typename?: 'User', 
    id: string, 
    name?: string | null, 
    username: string, 
    email?: string | null, 
    dateOfBirth?: any | null, 
    gender?: Gender | null, 
    phone?: string | null, 
    introduction?: string | null, 
    createdAt: any, 
    updatedAt: any, 
    deletedAt?: any | null, 
    resignReason?: string | null },
    class: {
      __typename?: "Class" | undefined;
      id: string;
      title?: string | null | undefined;
      description?: string | null | undefined;
      duration?: number | null | undefined;
      active?: boolean | undefined;
      createdAt?: any;
  } },
  onPush: (classUserId: string, userId: string, title: string, text: string) => void
}

function UnreviewedRow({ review, onPush }: UnreviewedRowProps) {
  const [showPushModal, setShowPushModal] = useState(false);
  const {
    id,
    userId,
    classId,
    createdAt,
    user,
    push,
    pushedAt
  } = review!;

  const classes = review.class
  const pushInfo = {
    classUserId: id,
    userId,
    classId, 
  }

  function toggleShowPushModal(show: boolean) {
    setShowPushModal(show);
  }

  return (
    <>
      <tr>
        <Td>{id}</Td>
        <Td>{classes.title}</Td>
        <Td>{user.username}</Td>
        <Td>{format(new Date(createdAt), "yyyy-MM-dd")}</Td>
        <Td><p>{push > 0 ? 'O' : 'X'}</p><p>{pushedAt && format(new Date(pushedAt), "yyyy-MM-dd")}</p></Td>
        <Td onClick={()=>{setShowPushModal(true)}}>알림보내기</Td>
        {
          <PushFormModal
            pushInfo={pushInfo}
            onPush={onPush}
            isOpen={showPushModal}
            onRequestClose={() => toggleShowPushModal(false)}
        />
      }
      </tr>
    </>
  );
}

export default UnreviewedRow;
