import { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  GetAllUsersAsAdminDocument,
  useDeleteUserProfileMutation,
  useGetAllUsersAsAdminQuery,
} from "../../../../lib/apollo/graphql/generated";

function useUserListTable() {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useGetAllUsersAsAdminQuery({
    variables: {
      first: 20,
    },
  });

  const [deleteUserProfile] = useDeleteUserProfileMutation({
    refetchQueries: () => [
      {
        query: GetAllUsersAsAdminDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  async function onFetchMore(inView: boolean) {
    if (inView && !fetchingMore) {
      setFetchingMore(true);

      const { hasNextPage, endCursor } = data?.getAllUsersAsAdmin?.pageInfo!;
      if (hasNextPage) {
        fetchMore({
          variables: {
            first: 20,
            after: endCursor,
          },
        }).finally(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  function onDeleteClick(userId: string) {
    deleteUserProfile({
      variables: {
        userId,
      },
    });
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      data: data?.getAllUsersAsAdmin?.edges,
      loading,
      fetchingMore,
    },
    operations: {
      onDeleteClick,
    },
  };
}

export default useUserListTable;
