import imageCompression from "browser-image-compression";
import { format } from "date-fns";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../../constants";
import {
  Gender,
  GetAllUsersAsAdminDocument,
  useGetFieldsQuery,
  useUpdateUserProfileAsAdminMutation,
} from "../../../../lib/apollo/graphql/generated";

function useUpdateUserModal(userId?: string, onRequestClose?: () => void) {
  const isNew = !userId;

  const [state, setState] = useState({
    name: "",
    username: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    phone: "",
    introduction: "",
    selectedFieldIds: new Set(),
    avatar: null as any,
    avatarState: null as any,
  });

  const { loading, data } = useGetFieldsQuery();

  const [updateUserProfileAsAdmin] = useUpdateUserProfileAsAdminMutation({
    onCompleted: () => {
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllUsersAsAdminDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  function onInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    if (key === "phone" && isNaN(Number(value))) {
      return;
    }

    if (
      key === "introduction" &&
      value.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onGenderChange(gender: string) {
    setState((prev) => ({
      ...prev,
      gender,
    }));
  }

  function onFieldSelect(fieldId: string) {
    const { selectedFieldIds } = state;

    const currentSelectedFieldIds = new Set(selectedFieldIds);

    if (currentSelectedFieldIds.has(fieldId)) {
      currentSelectedFieldIds.delete(fieldId);
      setState((prev) => ({
        ...prev,
        selectedFieldIds: currentSelectedFieldIds,
      }));
    } else {
      currentSelectedFieldIds.add(fieldId);
      setState((prev) => ({
        ...prev,
        selectedFieldIds: currentSelectedFieldIds,
      }));
    }
  }

  function onAvatarInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    setState((prev) => ({
      ...prev,
      avatarState: files?.[0],
    }));
  }

  async function onSubmit() {
    const {
      name,
      username,
      email,
      selectedFieldIds,
      gender,
      dateOfBirth,
      phone,
      introduction,
      avatar,
      avatarState,
    } = state;

    const userInput = {
      name,
      email,
      username,
      introduction,
      phone,
      dateOfBirth,
      gender: gender as Gender,
      fieldIds: Array.from(selectedFieldIds) as string[],
    };

    const avatarInput = { file: null as any };

    if (avatarState) {
      const compressed = await imageCompression(
        avatarState,
        IMAGE_COMPRESSION_OPTIONS
      );

      avatarInput.file = compressed;
    }

    updateUserProfileAsAdmin({
      variables: {
        userId: isNew ? null : userId,
        userInput,
        avatarInput,
      },
    });
  }

  return {
    models: {
      state,
      isNew,
      loading,
      fields: data?.getFields,
    },
    operations: {
      onInputChange,
      onFieldSelect,
      onGenderChange,
      onAvatarInputChange,
      onSubmit,
    },
  };
}

export default useUpdateUserModal;
