import { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  GetAllMentorsDocument,
  useGetAllMentorsQuery,
  useToggleActiveMentorMutation,
  useVerifyMentorAsAdminMutation,
} from "../../../../lib/apollo/graphql/generated";

function useMentorListTable(requestedOnly: boolean) {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useGetAllMentorsQuery({
    variables: {
      first: 20,
      requestedOnly,
    },
  });

  const [verifyMentorAsAdmin] = useVerifyMentorAsAdminMutation({
    refetchQueries: requestedOnly
      ? () => [
          {
            query: GetAllMentorsDocument,
            variables: {
              first: 20,
              requestedOnly,
            },
          },
        ]
      : undefined,
  });

  const [toggleActiveMentor] = useToggleActiveMentorMutation();

  async function onFetchMore(inView: boolean) {
    if (inView && !fetchingMore) {
      setFetchingMore(true);

      const { hasNextPage, endCursor } = data?.getAllMentors?.pageInfo!;
      if (hasNextPage) {
        fetchMore({
          variables: {
            first: 20,
            requestedOnly,
            after: endCursor,
          },
        }).finally(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  function onVerifyClick(mentorId: string) {
    verifyMentorAsAdmin({
      variables: {
        mentorId,
        verified: true,
      },
    });
  }

  function onActivateClick(mentorId: string, active: boolean) {
    toggleActiveMentor({
      variables: {
        mentorId,
        active: !active,
      },
    });
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAllMentors?.edges,
      fetchingMore,
    },
    operations: {
      onVerifyClick,
      onActivateClick,
    },
  };
}

export default useMentorListTable;
