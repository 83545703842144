import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetAllUnreviewedQuery, usePushMessageMutation } from "../../../lib/apollo/graphql/generated";

function useUnreviewedListTalbe() {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore, refetch } = useGetAllUnreviewedQuery({
    variables: {
      first: 20,
    },
  });

  const [pushMessage] = usePushMessageMutation()

  async function onFetchMore(inView: boolean) {
    if (inView && !fetchingMore) {
      setFetchingMore(true);

      const { hasNextPage, endCursor } = data?.getAllUnreviewed?.pageInfo!;
      if (hasNextPage) {
        refetch({
            first: 20,
            after: endCursor
          
        }).then(() => {
          setFetchingMore(false);
        });
      }
    }
  }

  function push(classUserId: string, userId: string, title: string, text: string) {
    pushMessage({
      variables:{
        classUserId,
        userId,
        title,
        text
      }
    })
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAllUnreviewed?.edges,
      fetchingMore,
    },
    operations: {
      push
    }
  };
}

export default useUnreviewedListTalbe;
