import React from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useUserListContainer from "./UserListContainer.hook";
import { USER_TYPE_TEXTS, USER_TYPES } from "../../../../constants";
import { Button } from "../../../common/styles/commonStyles";
import MentorListTable from "../components/MentorListTable";
import TutorListTable from "../components/TutorListTable";
import UpdateUserModal from "../components/UpdateUserModal";
import UserListTable from "../components/UserListTable";

function UserListContainer() {
  const theme = useTheme();

  const {
    models: { tab, showUpdateUserModal },
    operations: {
      onTabSelect,
      toggleShowUpdateUserModal,
      onExcelDownloadPress,
    },
  } = useUserListContainer();

  return (
    <>
      <Wrapper>
        <UserTypeButtons>
          {Object.keys(USER_TYPES).map((key) => (
            <Button
              key={key}
              style={{
                backgroundColor:
                  key === tab ? theme.color.black1 : theme.color.black6,
              }}
              onClick={() => onTabSelect(key)}
            >
              {USER_TYPE_TEXTS[key]}
            </Button>
          ))}
          <Button onClick={() => toggleShowUpdateUserModal(true)}>
            유저 생성
          </Button>
          <Button onClick={onExcelDownloadPress}>엑셀 다운로드</Button>
        </UserTypeButtons>
        {tab === USER_TYPES.ALL && <UserListTable />}
        {tab === USER_TYPES.MENTORS && <MentorListTable />}
        {tab === USER_TYPES.MENTORS_PENDING_VERIFIED && (
          <MentorListTable requestedOnly />
        )}
        {tab === USER_TYPES.TUTORS && <TutorListTable />}
        {tab === USER_TYPES.TUTORS_PENDING_VERIFIED && (
          <TutorListTable requestedOnly />
        )}
      </Wrapper>
      {showUpdateUserModal && (
        <UpdateUserModal
          isOpen={showUpdateUserModal}
          onRequestClose={() => toggleShowUpdateUserModal(false)}
        />
      )}
    </>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  padding: theme.spacing.middle,
}));

const UserTypeButtons = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  marginBottom: theme.spacing.normal,
}));

export default UserListContainer;
