export const USER_TYPES = {
  ALL: "ALL",
  MENTORS: "MENTORS",
  MENTORS_PENDING_VERIFIED: "MENTORS_PENDING_VERIFIED",
  TUTORS: "TUTORS",
  TUTORS_PENDING_VERIFIED: "TUTORS_PENDING_VERIFIED",
};

export const USER_TYPE_TEXTS = {
  [USER_TYPES.ALL]: "전체",
  [USER_TYPES.MENTORS]: "멘토",
  [USER_TYPES.MENTORS_PENDING_VERIFIED]: "멘토 승인 요청",
  [USER_TYPES.TUTORS]: "강사",
  [USER_TYPES.TUTORS_PENDING_VERIFIED]: "강사 승인 요청",
};

export const POLICY_TYPES = {
  TERMS_OF_POLICY: "TERMS_OF_POLICY",
  PRIVACY_POLICY: "PRIVACY_POLICY",
};

export const POLICY_TYPES_TEXT = {
  [POLICY_TYPES.TERMS_OF_POLICY]: "이용약관",
  [POLICY_TYPES.PRIVACY_POLICY]: "개인정보처리방침",
};

export const IMAGE_COMPRESSION_OPTIONS = {
  maxSizeMB: 0.5,
};

export const BOARD_ROLES_MEM = {
  USERS: "일반사용자",
  MENTORS: "멘토",
  TUTORS: "강사",
}

export const BOARD_ROLES = [
  [BOARD_ROLES_MEM.USERS],
  [BOARD_ROLES_MEM.TUTORS],
  [BOARD_ROLES_MEM.MENTORS],
  [BOARD_ROLES_MEM.TUTORS, BOARD_ROLES_MEM.USERS],
  [BOARD_ROLES_MEM.MENTORS, BOARD_ROLES_MEM.USERS],
  [BOARD_ROLES_MEM.TUTORS, BOARD_ROLES_MEM.MENTORS],
  [BOARD_ROLES_MEM.TUTORS, BOARD_ROLES_MEM.MENTORS, BOARD_ROLES_MEM.USERS],
];
