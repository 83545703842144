import imageCompression from "browser-image-compression";
import { format } from "date-fns";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../constants";
import {
  UpdateBannerMutation,
  useUpdateBannerMutation,
  useGetFieldsQuery,
  useUpdateUserProfileAsAdminMutation,
  useGetBannerQuery,
  useUpdateBannerAsAdminMutation,
  GetAllBannersDocument
} from "../../../lib/apollo/graphql/generated";

function useUpdateBannerModal(bannerId?: number, userId?: string, onRequestClose?: () => void) {

  const [state, setState] = useState({
    desc: '',
    order: 0,
    link: '',
    imageUrl: '',
    avatar: null as any,
    avatarState: null as any,
  });


  const { loading, data } = useGetBannerQuery({
    onCompleted: (data) => {
      if (data?.getBanner) {
        setState({
          desc: data?.getBanner?.desc || "",
          order: data?.getBanner?.order || 0,
          link: data?.getBanner?.link || "",
          imageUrl: data?.getBanner?.media?.path || "",
          avatar: null,
          avatarState: null
        });
      }
    },variables:{
      id: bannerId
    }

  });

  function onInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    if (
      key === "desc" &&
      value.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    if (
      key === "order" &&
      (isNaN(Number(value)) || Number(value) > 5)
    ) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: key === "order" ? Number(value) : value,
    }));
  }

  const [updateBanner] = useUpdateBannerAsAdminMutation({
    onCompleted: () => {
      alert("수정되었습니다");
      onRequestClose && onRequestClose();
    },
    refetchQueries: () => [
      {
        query: GetAllBannersDocument,
        variables: {
          first: 20,
        },
      },
    ],
  });

  function onImageInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    setState((prev) => ({
      ...prev,
      avatarState: files?.[0],
    }));
  }

  async function onSubmit() {
    const {
      desc,
      order, 
      link,
      avatar,
      avatarState,
    } = state;

    const avatarInput = { file: null as any };

    if (avatarState) {
      const compressed = await imageCompression(
        avatarState,
        IMAGE_COMPRESSION_OPTIONS
      );

      avatarInput.file = compressed;
    }

    let updateDatas: any = {}
    if (desc != data?.getBanner?.desc) updateDatas={...updateDatas, desc}
    if (order != data?.getBanner?.order) updateDatas={...updateDatas, order}
    if (link != data?.getBanner?.link) updateDatas={...updateDatas, link}

    updateBanner({
      variables: {
        id: bannerId, 
        desc,
        order, 
        link,
        avatarInput,
      },
    });
    }

  return {
    models: {
      state,
      loading,
      data: data?.getBanner
    },
    operations: {
      onImageInputChange,
      onInputChange,
      onSubmit,

    },
  };
}

export default useUpdateBannerModal;
