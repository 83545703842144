import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useAreaCard from "./AreaCard.hook";
import { Button } from "../../common/styles/commonStyles";

interface AreaCardProps {
  area: {
    __typename?: "Area" | undefined;
    id: string;
    name: string;
    sort: number;
    createdAt: any;
    updatedAt: any;
  } | null;
}

function AreaCard({ area }: AreaCardProps) {
  const {
    models: { state, loading },
    operations: { onInputChange, onSubmit, isButtonDisabled },
  } = useAreaCard(area);

  return (
    <Wrapper>
      <div style={{ alignSelf: "center", fontWeight: "bold" }}>
        {area ? "수정하기" : "추가하기"}
      </div>
      <InputRow>
        <InputLabel>지역 이름</InputLabel>
        <Input
          placeholder="지역 이름"
          value={state.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "name")
          }
        />
      </InputRow>
      <InputRow>
        <InputLabel>순서</InputLabel>
        <Input
          type="number"
          placeholder="0이상 다른순서와 겹치지 않게"
          value={state.sort}
          min={0}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "sort")
          }
        />
      </InputRow>

      <Button disabled={isButtonDisabled()} onClick={onSubmit}>
        {loading ? "제출 중" : "제출하기"}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black6}`,
  borderRadius: 2,
  padding: theme.spacing.small,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxSmall2,
}));

const InputRow = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
  width: "100%",
}));

const InputLabel = styled.div<any>(() => ({
  flex: 1,
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.small,
  border: `1px solid ${theme.color.black6}`,
  flex: 2,
}));

export default AreaCard;
