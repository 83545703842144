import React, {useState, useEffect} from "react";
import styled, { ThemedComponentProps, useTheme } from "styled-components";

import useCommentContainer from "./CommentContainer.hook";
import CommentRow from "../components/CommentRow";
import PaginatedItems from "../components/Pagination";
import { Button } from "../../common/styles/commonStyles";

type CommnetProps = {
  postId?: number | null
  boardId?: number
}

function CommentContainer({boardId, postId}: CommnetProps) {
  const {
    refs: {  },
    models: { loading, data },
    operations: { reFetch, onCreateComment }
  } = useCommentContainer(postId, boardId);
  const [pageSize, setPageSize] = useState<number[]>([])
	const [page, setPage] = useState(1)
  const [skip, setSkip] = useState(true)
  const [text, setText] = useState('')

  const changePage = (num: number) => {
		setPage(num)
	}

  const clickedCreateButton = () => {
    onCreateComment(text)
  }

  useEffect(()=>{
    if(data) {
      const temp = data?.total ? Math.ceil(data?.total / 20) : 1
      setPageSize(Array.from({ length: temp }, (v, i) => i + 1))
    }
  },[data])

  useEffect(()=>{
    if (skip) {
      setSkip(false)
    } else {
      reFetch(skip, page)
    }
  },[page])
  if (loading) return <div />;

  return (
    <div>
      {
        data?.total == 0 
        ? <NoCommentWrapper>
          등록된 댓글이 없습니다
        </NoCommentWrapper>
        : data?.result?.map((comment) => {
          return <CommentRow boardId={boardId} comment={comment}/>
        })
      }
      <PaginatedItems 
        page={page}
        pageSize={pageSize}
        changePage={changePage}
        itemsPerPage={5}
      />
      <CommnetInputWrapper>
        <CommentInput placeholder="바르고 고운말" onChange={(e: any) => setText(e.target.value.trim())}></CommentInput>
        <Button onClick={clickedCreateButton}>등록</Button>
      </CommnetInputWrapper>
    </div>
  );
}

export default CommentContainer;

const NoCommentWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  minHeight: "50px"
}));
const CommnetInputWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  gap: "5px",
  margin: "20px 0"
}));
const CommentInput = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  resize: "none",
  width: "100%",
  padding: theme.spacing.small,
}));